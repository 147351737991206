import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar, Pie } from 'react-chartjs-2';
import Select from "react-select";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CabecalhoAdmin from "../../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../../componentes/MenuLateral";
import { AreaSuperior, FiltrarData, InputMes, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal } from "../../AdminStyled";
import ErroSpan from "../../../../componentes/ErroSpan";
import { ticketsComGuia } from "../../../../servicos/ticketsServices";
import { AreaRelatorio, LadoALado } from "../IndicadoresStyled";
import { getAllGuias } from "../../../../servicos/guiasServices";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function PorGuias() {
    const [params, setParams] = useSearchParams();
    const [datas, setDatas] = useState({
        dataInicial: params.get("dataInicial"),
        dataFinal: params.get("dataFinal")
    });
    const [carregando, setCarregando] = useState(true);
    const [dadosTotal, setDadosTotal] = useState(null);
    const [dadosOnline, setDadosOnline] = useState(null);
    const [dadosSemana, setDadosSemana] = useState(null);
    const [dadosTipo, setDadosTipo] = useState(null);
    const [guias, setGuias] = useState([]);
    const [guiaSelecionado, setGuiaSelecionado] = useState([]);
    const tipos = [
        "PROMOCIONAL",
        "PROMOCIONAL-ONLINE",
        "PROMOCIONAL-EXCURSÃO",
        "INFANTIL",
        "INFANTIL-ONLINE",
        "CORTESIA"
    ];

    // Atualizar lista de guias
    useEffect(() => {
        getAllGuias().then(resposta => {
            setGuias(resposta.map(guia => {
                return {
                    value: guia.codigo,
                    label: guia.nome
                }
            }));
        });
    }, []);

    // Atualizar dados dos relatórios
    useEffect(() => {
        if (params.get("dataInicial") && params.get("dataFinal") && guias.length > 0) {
            setCarregando(true);
            ticketsComGuia(params.get("dataInicial"), params.get("dataFinal")).then(resposta => {
                const dados1 = [];                      // Ingressos totais por guia
                const dados2 = [];                      // Ingressos online por guia
                const dados3 = [0, 0, 0, 0, 0, 0, 0];   // Ingressos por dia da semana
                const dados4 = [0, 0, 0, 0, 0, 0];      // Ingressos por tipo
                const dadosFiltrado = params.get("guia") ? resposta.ingressos.filter(ingresso => {
                    let retorno = false;
                    params.get("guia").split("-").forEach(cod => {
                        if (ingresso.guia.codigo === Number(cod)) retorno = true;
                    });
                    return retorno;
                }) : resposta.ingressos;

                let dDia, busca;

                for (let i = 0; i < dadosFiltrado.length; i++) {
                    dados4[tipos.indexOf(dadosFiltrado[i].tipo)]++;

                    dDia = new Date(dadosFiltrado[i].numero * 1000);
                    dados3[dDia.getDay()]++;

                    busca = dados1.find(x => dadosFiltrado[i].guia.codigo in x);

                    if (busca) {
                        if (dadosFiltrado[i].tipo in busca[dadosFiltrado[i].guia.codigo]) {
                            busca[dadosFiltrado[i].guia.codigo][dadosFiltrado[i].tipo] += 1;
                        } else {
                            busca[dadosFiltrado[i].guia.codigo][dadosFiltrado[i].tipo] = 1;
                        }
                    } else {
                        let novoItem = {};
                        novoItem[dadosFiltrado[i].guia.codigo] = {};
                        novoItem[dadosFiltrado[i].guia.codigo][dadosFiltrado[i].tipo] = 1;
                        dados1.push(novoItem);
                    }

                    if (dadosFiltrado[i].tipo === "PROMOCIONAL-ONLINE" || dadosFiltrado[i].tipo === "INFANTIL-ONLINE") {
                        console.log(dadosFiltrado[i].tipo)
                        busca = dados2.find(x => dadosFiltrado[i].guia.codigo in x);

                        if (busca) {
                            if (dadosFiltrado[i].tipo in busca[dadosFiltrado[i].guia.codigo]) {
                                busca[dadosFiltrado[i].guia.codigo][dadosFiltrado[i].tipo] += 1;
                            } else {
                                busca[dadosFiltrado[i].guia.codigo][dadosFiltrado[i].tipo] = 1;
                            }
                        } else {
                            let novoItem = {};
                            novoItem[dadosFiltrado[i].guia.codigo] = {};
                            novoItem[dadosFiltrado[i].guia.codigo][dadosFiltrado[i].tipo] = 1;
                            dados2.push(novoItem);
                        }
                    }
                    console.log(dados2)
                }

                const dados1ordenado = dados1.sort((a, b) => {
                    return Object.values(b[Object.keys(b)[0]]).reduce((soma, valor) => soma + valor, 0) - Object.values(a[Object.keys(a)[0]]).reduce((soma, valor) => soma + valor, 0);
                });
                const dados2ordenado = dados2.sort((a, b) => {
                    return Object.values(b[Object.keys(b)[0]]).reduce((soma, valor) => soma + valor, 0) - Object.values(a[Object.keys(a)[0]]).reduce((soma, valor) => soma + valor, 0);
                });
                // const dados2ordenado = Object.entries(dados2).sort((a, b) => b[1] - a[1]);

                setDadosSemana({
                    labels: [
                        "Domingo",
                        "Segunda-feira",
                        "Terça-feira",
                        "Quarta-feira",
                        "Quinta-feira",
                        "Sexta-feira",
                        "Sábado"
                    ],
                    datasets: [
                        {
                            label: "Quantidade de ingressos",
                            data: dados3,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosTipo({
                    labels: tipos,
                    datasets: [
                        {
                            label: "Ingressos por Tipo",
                            data: dados4,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(255, 98, 7, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(134, 255, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 99, 132, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgb(255, 98, 7)',
                                'rgba(153, 102, 255, 1)',
                                'rgb(134, 255, 64)'
                            ],
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosTotal({
                    labels: dados1ordenado.map(e => (guias.find(guia => guia.value === Number(Object.keys(e)[0]))).label),
                    datasets: [
                        {
                            label: "PROMOCIONAL",
                            data: dados1ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave].PROMOCIONAL;
                            }),
                            borderColor: 'rgba(54, 162, 235, 1)',
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderWidth: 1,
                        },
                        {
                            label: "PROMOCIONAL-ONLINE",
                            data: dados1ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["PROMOCIONAL-ONLINE"];
                            }),
                            borderColor: 'rgba(255, 99, 132, 1)',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderWidth: 1,
                        },
                        {
                            label: "PROMOCIONAL-EXCURSÃO",
                            data: dados1ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["PROMOCIONAL-EXCURSÃO"];
                            }),
                            borderColor: 'rgba(255, 206, 86, 1)',
                            backgroundColor: 'rgba(255, 206, 86, 0.2)',
                            borderWidth: 1,
                        },
                        {
                            label: "INFANTIL",
                            data: dados1ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["INFANTIL"];
                            }),
                            borderColor: 'rgba(255, 98, 7, 1)',
                            backgroundColor: 'rgba(255, 98, 7, 0.2)',
                            borderWidth: 1,
                        },
                        {
                            label: "INFANTIL-ONLINE",
                            data: dados1ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["INFANTIL-ONLINE"];
                            }),
                            borderColor: 'rgba(153, 102, 255, 1)',
                            backgroundColor: 'rgba(153, 102, 255, 0.2)',
                            borderWidth: 1,
                        },
                        {
                            label: "CORTESIA",
                            data: dados1ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["CORTESIA"];
                            }),
                            borderColor: 'rgba(134, 255, 64, 1)',
                            backgroundColor: 'rgba(134, 255, 64, 0.2)',
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosOnline({
                    labels: dados2ordenado.map(e => (guias.find(guia => guia.value === Number(Object.keys(e)[0]))).label),
                    datasets: [
                        {
                            label: "PROMOCIONAL-ONLINE",
                            data: dados2ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["PROMOCIONAL-ONLINE"];
                            }),
                            borderColor: 'rgba(255, 99, 132, 1)',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderWidth: 1,
                        },
                        {
                            label: "INFANTIL-ONLINE",
                            data: dados2ordenado.map(e => {
                                const chave = Object.keys(e)[0];
                                return e[chave]["INFANTIL-ONLINE"];
                            }),
                            borderColor: 'rgba(153, 102, 255, 1)',
                            backgroundColor: 'rgba(153, 102, 255, 0.2)',
                            borderWidth: 1,
                        }
                    ]
                });

                setCarregando(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, guias]);

    // Atualizar guias selecionados com base nos parametros
    useEffect(() => {
        if (params.get("guia")) {
            const selecionados = [];
            let add = {};
            params.get("guia").split("-").forEach(cod => {
                add = guias.find(guia => guia.value === Number(cod));
                selecionados.push(add);
            });
            setGuiaSelecionado(selecionados);
        }
    }, [params, guias]);

    function atualizarParametros() {
        if (guiaSelecionado.length > 0) {
            let paramGuia = `${guiaSelecionado[0].value}`;
            for (let i = 1; i < guiaSelecionado.length; i++) {
                paramGuia += `-${guiaSelecionado[i].value}`;
            }
            setParams(params => {
                params.set("dataInicial", datas.dataInicial);
                params.set("dataFinal", datas.dataFinal);
                params.set("guia", paramGuia);
                return params;
            })
        } else {
            setParams(params => {
                params.set("dataInicial", datas.dataInicial);
                params.set("dataFinal", datas.dataFinal);
                params.delete("guia");
                return params;
            });
        }
    }

    return (<SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Análise de guias e parceiros")}
            <AreaSuperior>
                <OpcoesFiltragem>
                    <FiltrarData $marginTop="1px">
                        <label>Data inicial</label>
                        <InputMes type="date" defaultValue={params.get("dataInicial")} onChange={event => setDatas({ ...datas, dataInicial: event.target.value })} />
                    </FiltrarData>
                    <FiltrarData $marginTop="1px">
                        <label>Data final</label>
                        <InputMes type="date" defaultValue={params.get("dataFinal")} onChange={event => setDatas({ ...datas, dataFinal: event.target.value })} />
                    </FiltrarData>
                    <FiltrarData>
                        <label>Guia</label>
                        <Select
                            options={guias}
                            isMulti
                            isClearable="false"
                            placeholder="Guia"
                            value={guiaSelecionado}
                            onChange={e => setGuiaSelecionado(e)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "2px",
                                    border: "1px solid #252525",
                                    width: "700px",
                                    height: "31px",
                                    minHeight: '30px',
                                    fontSize: "14px",
                                    borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)'
                                }),
                                input: (provided, state) => ({
                                    ...provided,
                                    margin: '0px',
                                }),
                                valueContainer: (provided, state) => ({
                                    ...provided,
                                    height: '30px',
                                    padding: '0 6px'
                                }),
                                indicatorsContainer: (provided, state) => ({
                                    ...provided,
                                    height: '30px',
                                }),
                                option: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "black"
                                })
                            }}
                        />
                    </FiltrarData>
                    <IoMdRefresh className="Icone" onClick={() => atualizarParametros()} />
                </OpcoesFiltragem>
            </AreaSuperior>
            {carregando && <ErroSpan>Carregando...</ErroSpan>}
            <LadoALado $margem="20px 5px 0 5px">
                {dadosSemana && <AreaRelatorio $maxLargura="50%" $altura="300px">
                    <Bar data={dadosSemana} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos de guia na semana",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                            datalabels: {
                                display: false
                            }
                        }
                    }} />
                </AreaRelatorio>}
                {dadosTipo && <AreaRelatorio $maxLargura="40%" $altura="300px">
                    <Pie data={dadosTipo} options={{
                        plugins: {
                            legend: {
                                position: 'bottom',
                            },
                            title: {
                                text: "Ingressos por tipo",
                                display: true,
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                            datalabels: {
                                formatter: (value, ctx) => {
                                    if (value === 0) {
                                        return '';  // Hide label for zero values
                                    }
                                    const datapoints = ctx.chart.data.datasets[0].data;
                                    const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                                    const percentage = value / total * 100;
                                    return percentage.toFixed(2) + "%";
                                },
                                color: '#25252594'
                            }
                        }
                    }} />
                </AreaRelatorio>}
            </LadoALado>
            {dadosOnline && <AreaRelatorio $altura={`${dadosOnline.labels.length * 22 + 100}px`} $maxLargura="95%">
                <Bar data={dadosOnline} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: "Ingressos online por guia",
                            font: {
                                size: "16px"
                            },
                            color: '#252525'
                        },
                        tooltip: {  // Use 'tooltip' instead of 'tooltips' in Chart.js v3/v4
                            mode: 'index',
                            callbacks: {
                                label: function (tooltipItem) {
                                    const datasetLabel = tooltipItem.dataset.label || '';
                                    const currentValue = tooltipItem.raw || 0;  // tooltipItem.raw gives the value of the current item
                                    const dataIndex = tooltipItem.dataIndex;

                                    // Calculate the total for the current index across all datasets
                                    const total = tooltipItem.chart.data.datasets.reduce((acc, dataset) => {
                                        const value = dataset.data[dataIndex];
                                        return acc + (typeof value === 'number' ? value : 0);
                                    }, 0);

                                    if (tooltipItem.datasetIndex !== dadosTotal.datasets.length - 1) {
                                        return [
                                            `${datasetLabel}: ${currentValue}`
                                        ];
                                    } else {
                                        return [
                                            `${datasetLabel}: ${currentValue}`,
                                            `Total: ${total.toLocaleString()}`
                                        ];
                                    }
                                }
                            }
                        },
                        datalabels: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            beginAtZero: true
                        },
                        y: {
                            beginAtZero: true,
                            stacked: true
                        }
                    }
                }} />
            </AreaRelatorio>}
            {dadosTotal && <AreaRelatorio $altura={`${dadosTotal.labels.length * 22 + 100}px`} $maxLargura="95%" $pad="0 0 80px 0">
                <Bar data={dadosTotal} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: "Ingressos totais por guia",
                            font: {
                                size: "16px"
                            },
                            color: '#252525'
                        },
                        tooltip: {  // Use 'tooltip' instead of 'tooltips' in Chart.js v3/v4
                            mode: 'index',
                            callbacks: {
                                label: function (tooltipItem) {
                                    const datasetLabel = tooltipItem.dataset.label || '';
                                    const currentValue = tooltipItem.raw || 0;

                                    const retorno = [];
                                    if (currentValue !== 0) {
                                        retorno.push(`${datasetLabel}: ${currentValue}`);
                                    }

                                    return retorno;
                                },
                                afterBody: function (tooltipItems) {
                                    const dataIndex = tooltipItems[0].dataIndex;
                                    const total = tooltipItems[0].chart.data.datasets.reduce((acc, dataset) => {
                                        const value = dataset.data[dataIndex];
                                        return acc + (typeof value === 'number' ? value : 0);
                                    }, 0);

                                    return `Total: ${total.toLocaleString()}`;  // Add "Total" without color
                                }
                            }
                        },
                        datalabels: {
                            display: false
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            beginAtZero: true
                        },
                        y: {
                            beginAtZero: true,
                            stacked: true
                        }
                    }
                }}/>
            </AreaRelatorio>}
        </SecaoPrincipal>
    </SecaoAdmin>)
}