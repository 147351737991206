import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import { BiUpArrowAlt } from "react-icons/bi";
import { BotaoComecar, Flash, ContagemRegressiva, DivCamera, MascaraQrCode, MostradorFotos, Quadro, SecaoFotos, Texto, BordaQr, CameraFotos, SecaoFotosSemBorda, Alinhar, MensagemFinal } from "./FotosStyled";
import { atualizaEtapa, getTicket } from "../../servicos/ticketsServices";
import { salvarFoto } from "../../servicos/fotosServices";
import { usuarioAtual } from "../../servicos/usuariosServices";
import Titulo1 from "../../componentes/Titulo1";
import ModalAviso from "../../componentes/ModalAviso";

export default function Fotos() {
	const [ticketLido, setTicketLido] = useState({});
	const [etapa, setEtapa] = useState(1);
	const [foto, setFoto] = useState([]);
	const [selecionado, setSelecionado] = useState(0);
	const [piscar, setPiscar] = useState("0");
	const [corBordas, setCorBordas] = useState("white");
	const [regressiva, setRegressiva] = useState({
		mostrar: false,
		contagem: 5
	});
	const [regressiva2, setRegressiva2] = useState(null);
	const [aviso, setAviso] = useState({
		mostrar: false,
		texto: ""
	});
	const [carregando, setCarregando] = useState(false);
	const videoRef = useRef();
	const canvasRef = useRef();
	const timeout = 90;		// Tempo em segundos para sair da tela se nenhuma ação for tomada
	const [dimensoesJanela, setDimensoesJanela] = useState({
		largura: window.innerWidth,
		altura: window.innerHeight
	});
	const navigate = useNavigate();
	const avisoMostrarRef = useRef(aviso.mostrar);

	async function startCamera() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ facingMode: 'user', video: { width: 1920, height: 1080 }, audio: false });
			videoRef.current.srcObject = stream;
		} catch (error) {
			console.error('Erro ao acessar webcam:', error);
		}
	};
	async function stopCamera() {
		try {
			const video = videoRef.current;
			video.srcObject.getTracks().forEach((track) => {
				track.stop();
			});
		} catch (e) {
			console.log(e.message);
		}
	}
	function tirarFoto() {
		setPiscar("1");
		const video = videoRef.current;
		const canvas = canvasRef.current;

		const context = canvas.getContext('2d');
		canvas.width = 1920;
		canvas.height = 1080;
		canvas.aspectRatio = 1920 / 1080;
		context.drawImage(video, 0, 0, canvas.width, canvas.height);

		// Convert the captured frame to a data URL
		const dataUri = canvas.toDataURL('image/jpeg', 0.85);

		return dataUri;
	}
	function selecionouFoto(numero) {
		setSelecionado(numero);
		salvarFoto(foto[numero], ticketLido.numero, ticketLido.visitante);
		setEtapa(5);
		setRegressiva({
			mostrar: false,
			contagem: 5,
			inicio: Math.floor(Date.now() / 1000) + 15
		});
		setRegressiva2(null);
		atualizaEtapa(ticketLido.numero, 0);
	}
	useEffect(() => {
		avisoMostrarRef.current = aviso.mostrar;
	}, [aviso.mostrar]);
	useEffect(() => {
		if (etapa === 3) {
			const interval = setInterval(() => {
				setPiscar("0");
				if (foto.length === 0) setRegressiva({
					...regressiva,
					contagem: regressiva.inicio - Math.floor(Date.now() / 1000) + 5
				}); else setRegressiva({
					...regressiva,
					contagem: regressiva.inicio - Math.floor(Date.now() / 1000) + 3
				});
				if (regressiva.contagem <= 1) {
					setFoto([...foto, tirarFoto()]);
					if (foto.length === 2) {
						setRegressiva({
							mostrar: false,
							contagem: 5
						});
						atualizaEtapa(ticketLido.numero, 4);
						setEtapa(4);
						setRegressiva2(timeout);
					} else {
						setRegressiva({
							mostrar: true,
							contagem: 3,
							inicio: Math.floor(Date.now() / 1000)
						});
					}
				}
			}, 1000);
			return () => clearInterval(interval);
		} else if (etapa === 5) {
			const interval = setInterval(() => {
				setRegressiva({
					...regressiva,
					contagem: regressiva.inicio - Math.floor(Date.now() / 1000) + 1
				});
				if (regressiva.contagem <= 0) {
					setFoto([]);
					setEtapa(1);
				}
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [regressiva, etapa, foto, ticketLido]);
	useEffect(() => {
		if (regressiva2) {
			const interval = setInterval(() => {
				setRegressiva2(regressiva2 - 1);
				if (regressiva2 <= 1) {
					if (aviso.mostrar) {
						setAviso({
							mostrar: false,
							texto: ""
						});
					} else {
						setEtapa(1);
						atualizaEtapa(ticketLido.numero, -1);
						setAviso({
							mostrar: true,
							texto: "Tempo esgotado."
						});
						setRegressiva2(10);
						setFoto([]);
					}
				}
			}, 1000);
			return () => clearInterval(interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [regressiva2])
	useEffect(() => {
		usuarioAtual().then(res => {
			if (!res || res.status !== 200) navigate("/login", { state: { redirecionar: window.location.pathname } });
		});
		const handleResize = () => {
			setDimensoesJanela({
				largura: window.innerWidth,
				altura: window.innerHeight,
			});
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	function verificaTicket(ticket) {
		getTicket(ticket).then(res => {
			if (etapa === 1) {
				if (res.mensagem === "Token inválido!") {
					navigate("/login", { state: { redirecionar: window.location.pathname } });
				} else if (res.mensagem === "Ticket inválido!") {
					setAviso({
						mostrar: true,
						texto: "Ticket inválido!"
					});
					setRegressiva2(15);
				} else if (res.tickets.length === 0) {
					setAviso({
						mostrar: true,
						texto: "Ticket não econtrado!"
					});
					setRegressiva2(15);
				} else if (res.tickets[0]?.etapaFoto >= 0) {
					setAviso({
						mostrar: true,
						texto: "Ticket já utilizado!"
					});
					setRegressiva2(15);
				} else {
					atualizaEtapa(res.tickets[0].numero, 2);
					setTicketLido(res.tickets[0]);
					setEtapa(2);
					setRegressiva2(timeout);
				}
			}
			setCorBordas("white");
		});
	}
	function mensagemFinal() {
		if (ticketLido.visitante.celular) {
			return (<>
				<Texto $bold="300" $tamanho="30px">Sua foto será adicionada</Texto>
				<Texto $bold="300" $tamanho="30px">ao mural de visitantes e enviada</Texto>
				<Texto $bold="300" $tamanho="30px">para o telefone cadastrado!</Texto>
			</>);
		} else if (ticketLido.visitante.email) {
			return (<>
				<Texto $bold="300" $tamanho="30px">Sua foto será adicionada</Texto>
				<Texto $bold="300" $tamanho="30px">ao mural de visitantes e enviada</Texto>
				<Texto $bold="300" $tamanho="30px">para o email cadastrado!</Texto>
			</>);
		} else {
			return (<>
				<Texto $bold="300" $tamanho="30px">Sua foto será adicionada</Texto>
				<Texto $bold="300" $tamanho="30px">ao mural de visitantes!</Texto>
			</>);
		}
	}

	function etapas() {
		if (etapa === 1) {
			return (<>
				<Texto $pad={`${15 * dimensoesJanela.altura / 768}px`} $fundo="var(--corNugaliEscura)" $bold="300" $largura="600px" $cor="#F3DEC4" $tamanho="32px" $margem={`${10 * dimensoesJanela.altura / 768}px auto 0 auto`}>Olá! Vamos fazer uma foto da sua visita?</Texto>
				<Texto $tamanho={`${32 * dimensoesJanela.altura / 768}px`} $margem={`${20 * dimensoesJanela.altura / 768}px 0 0 0`}>Posicione o QR Code de seu ingresso</Texto>
				<Texto $tamanho={`${32 * dimensoesJanela.altura / 768}px`} $margem={`0 0 ${30 * dimensoesJanela.altura / 768}px 0`}>em frente à câmera acima!</Texto>
				<DivCamera $altura={`${dimensoesJanela.altura * 480 / 768}px`} $largura={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333}px`}>
					<BordaQr $cor={corBordas}
						$largura={`${40 * dimensoesJanela.altura / 768}px`}
						$altura="5px"
						$topo={`${dimensoesJanela.altura / 768 * 115}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 413 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura={`${40 * dimensoesJanela.altura / 768}px`}
						$altura="5px"
						$topo={`${dimensoesJanela.altura / 768 * 115}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 187 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura={`${40 * dimensoesJanela.altura / 768}px`}
						$altura="5px"
						$topo={`${dimensoesJanela.altura / 768 * 360}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 413 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura={`${40 * dimensoesJanela.altura / 768}px`}
						$altura="5px"
						$topo={`${dimensoesJanela.altura / 768 * 360}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 187 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura="5px"
						$altura={`${40 * dimensoesJanela.altura / 768}px`}
						$topo={`${dimensoesJanela.altura / 768 * 120}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 448 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura="5px"
						$altura={`${40 * dimensoesJanela.altura / 768}px`}
						$topo={`${dimensoesJanela.altura / 768 * 120}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 187 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura="5px"
						$altura={`${40 * dimensoesJanela.altura / 768}px`}
						$topo={`${dimensoesJanela.altura / 768 * 325}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 448 / 640}px`} />
					<BordaQr $cor={corBordas}
						$largura="5px"
						$altura={`${40 * dimensoesJanela.altura / 768}px`}
						$topo={`${dimensoesJanela.altura / 768 * 325}px`}
						$esquerda={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333 * 187 / 640}px`} />
					<MascaraQrCode $altura={`${dimensoesJanela.altura * 480 / 768}px`} $largura={`${dimensoesJanela.altura * 480 / 768 * 1.3333333333}px`} />
					<QrReader
						onResult={(resultado) => {
							if (!!resultado && !carregando && !avisoMostrarRef.current) {
								setCarregando((prev) => {
									if (prev) return prev;
									setCorBordas("#41cc00");
									verificaTicket(resultado.text);
									setTimeout(() => {
										setCarregando(false);
									}, 3000);
									return true;
								});
							}
						}}
						constraints={{ facingMode: 'user', zoom: "180", video: { width: 1280, height: 960 }, audio: false }}
						containerStyle={{ height: `${window.innerHeight * 480 / 768}px`, margin: 0, padding: "0" }}
						videoContainerStyle={{ height: `${window.innerHeight * 480 / 768}px`, padding: 0 }}
						videoStyle={{ height: `${window.innerHeight * 480 / 768}px`, padding: 0, margin: 0 }}
					/>
				</DivCamera>
			</>);
		} else if (etapa === 2) {
			return (<>
				<Titulo1
					$tamanho="42px"
					$alinhamento="center"
					$margem={`${20 * dimensoesJanela.altura / 768}px 0 0 0`}
					$pad="0"
					$cor="var(--corNugaliEscura)"
					$corFundo="transparent">Vamos tirar três fotos e você</Titulo1>
				<Titulo1
					$tamanho="42px"
					$alinhamento="center"
					$margem={`0 0 0 0`}
					$pad="0"
					$cor="var(--corNugaliEscura)"
					$corFundo="transparent">escolhe a que mais gostou</Titulo1>
				<Texto
					$tamanho="42px"
					$bold="300"
					$margem={`${40 * dimensoesJanela.altura / 768}px 0 ${80 * dimensoesJanela.altura / 768}px 0`}><BiUpArrowAlt className="Icone" /> Não esqueça de olhar para a câmera <BiUpArrowAlt className="Icone" /></Texto>
				<BotaoComecar $margem="auto" onClick={() => {
					atualizaEtapa(ticketLido.numero, 3);
					setEtapa(3);
					startCamera();
					setRegressiva({
						mostrar: true,
						contagem: 5,
						inicio: Math.floor(Date.now() / 1000)
					});
					setRegressiva2(null);
				}}>COMEÇAR</BotaoComecar>
			</>);
		} else if (etapa === 3) {
			return (<>
				<ContagemRegressiva $mostrar={regressiva.mostrar}>{regressiva.contagem}</ContagemRegressiva>
				<CameraFotos constraints={{ aspectRatio: "1.7777" }} ref={videoRef} autoPlay={true} />
				<canvas ref={canvasRef} style={{ display: 'none' }} />
			</>);
		} else if (etapa === 4) {
			if (piscar === "1") {
				setPiscar("0");
				stopCamera();
			}
			return (<>
				<Texto $tamanho="2.5em" $margem={`${50 * dimensoesJanela.altura / 768}px 0 ${150 * dimensoesJanela.altura / 768}px 0`} $cor="var(--corNugaliEscura)" $corFundo="transparent">Toque na foto que mais gostou!</Texto>
				<MostradorFotos>
					<Quadro onClick={() => selecionouFoto(0)} src={foto[0]} alt="Foto1" />
					<Quadro onClick={() => selecionouFoto(1)} src={foto[1]} alt="Foto2" />
					<Quadro onClick={() => selecionouFoto(2)} src={foto[2]} alt="Foto3" />
				</MostradorFotos>
			</>)
		} else if (etapa === 5) {
			return (<>
				<Alinhar>
					<MensagemFinal>
						<Texto $tamanho="2.5em" $margem={`${100 * dimensoesJanela.altura / 768}px 200px 30px 0`} $cor="var(--corNugaliEscura)" $corFundo="transparent">Obrigado!</Texto>
						{mensagemFinal()}
					</MensagemFinal>
					<Quadro src={foto[selecionado]} alt="Foto Escolhida" $largura="700px" $margem={`${40 * dimensoesJanela.altura / 768}px ${40 * dimensoesJanela.altura / 768}px ${10 * dimensoesJanela.altura / 768}px ${100 * dimensoesJanela.altura / 768}px`} $roda="rotate(8deg)" $pad={`${15 * dimensoesJanela.altura / 768}px ${15 * dimensoesJanela.altura / 768}px ${40 * dimensoesJanela.altura / 768}px ${15 * dimensoesJanela.altura / 768}px`} />
				</Alinhar>
			</>)
		}
	}

	const fecharAviso = () => {
		setAviso({
			mostrar: false,
			texto: ""
		});
		setRegressiva2(0);
	}
	if (etapa === 3)
		return (
			<SecaoFotosSemBorda>
				{ModalAviso(aviso.texto, aviso.mostrar, fecharAviso, "80px")}
				{piscar === "1" && <Flash />}
				{etapas()}
			</SecaoFotosSemBorda>
		);
	else
		return (
			<SecaoFotos>
				{ModalAviso(aviso.texto, aviso.mostrar, fecharAviso, "auto")}
				{piscar === "1" && <Flash />}
				{etapas()}
			</SecaoFotos>
		);
}
