import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFoto } from "../../servicos/fotosServices";

const SecaoVisualizar = styled.section`
    width: 100vw;
    height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: white;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`

export default function VisualizadorFoto(){
    const [imagem, setImagem] = useState(null);
    const [texto, setTexto] = useState("Carregando...")
    const { foto } = useParams();

    useEffect(() => {
        obterFoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function obterFoto(){
        const resposta = await getFoto(foto);
        if (resposta.imagem) setImagem(`data:image/png;base64,${resposta.imagem}`);
        else setTexto("Foto não encontrada!");
    }

    return (<SecaoVisualizar>
        {(imagem && <img src={imagem} alt="Foto Tour Nugali" />) || <p>{texto}</p>}
    </SecaoVisualizar>)
}