export async function verificarCupom(cupom){
    try {
        cupom = cupom.replace(" ", "");
        const resposta = await (await fetch(`https://www.nugali.com.br/adm7541/cupons/?cupom=${cupom}`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            }
        })).json();
        if (resposta.status === "404") return { valido: false, mensagem: "Cupom não encontrado!" };
        else if (resposta.payment_status !== "approved") return { valido: false, mensagem: "Cupom inválido!" };
        else if (resposta.used) return { valido: false, mensagem: "Cupom já utilizado!" };
        const respostaConsumir = await (await fetch(`https://www.nugali.com.br/adm7541/cupons/?cupom=${cupom}&action=take`, {
            method: "put",
            headers: {
                "Content-Type": "application/json"
            }
        })).json();
        console.log(respostaConsumir)
        return { valido: true, mensagem: "Cupom utilizado com sucesso!" }
    } catch (e) {
        console.log(e.message);
        return { valido: false, mensagem: e.message };
    }
}