import { useEffect, useState } from "react";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { AreaSuperior, ArquivoEstilo, FiltrarData, ImportarArquivo, InputMes, LinksPaginas, OpcoesFiltragem, PaginasTabela, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { enviarOcorrencias, getComissoesSalvas, removerResiduos } from "../../../servicos/comissoesServices";
import Botao from "../../../componentes/Botao";
import ErroSpan from "../../../componentes/ErroSpan";
import { TabelaHistorico } from "./TabelaHistorico";

export default function Historico(){
    const [dataPagamento, setDataPagamento] = useState(null);
    const [dataAtualizar, setDataAtualizar] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState({
        erro: false,
        sucesso: false,
        mensagem: undefined
    });
    const [comissoesSalvas, setComissoesSalvas] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [ordenacaoAtual, setOrdenacaoAtual] = useState("dataPagamento");
    const [ordemAtual, setOrdemAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    useEffect(() => {
        findComissoes(dataPagamento, ordenacaoAtual, ordemAtual, paginaAtual)
    }, [dataPagamento, ordenacaoAtual, ordemAtual, paginaAtual]);

    function atualizaFiltros(){
        setDataPagamento(dataAtualizar);
        if (status.erro) setStatus({
            erro: false,
            sucesso: false,
            mensagem: undefined
        });
    }
    
    async function findComissoes(data, ordenacao, ordem, pagina){
        setIsLoading(true);
        const resposta = await getComissoesSalvas("", data, pagina, ordenacao, ordem);
        setTotalPaginas(parseInt((resposta.totalDocs-1)/30)+1);
        setComissoesSalvas(resposta.dados);
        setIsLoading(false);
    }

    function analisaOcorrenciasBanco(ocor){
        let ocorrencias = [];
        let mensagem = "";
        let efetivado = false;
        for (let i = 0; i < ocor.length; i += 2){
            ocorrencias.push(ocor.slice(i, i+2));
            if (ocorrencias[i/2] === "BD") {
                mensagem = "Processando pelo banco"
            } else if (ocorrencias[i/2] === "AT"){
                mensagem = "CPF/CNPJ inválido";
            } else if (ocorrencias[i/2] === "00"){
                efetivado = true;
                mensagem = "Pagamento efetivado";
            } else if (ocorrencias[i/2] === "PG"){
                mensagem = "CPF/CNPJ diferente do recebedor";
            } else if (ocorrencias[i/2] === "PJ"){
                mensagem = "Chave Pix inexistente";
            } else if (ocorrencias[i/2] === "PA") {
                mensagem = "Pix não efetivado - Tente mais tarde";
            } else {
                mensagem = "Código de ocorrência não identificado"
            }
        }
        return [ocorrencias, efetivado, mensagem];
    }

    async function lerArquivoRetorno(arquivo){
        const fr = new FileReader();
        fr.readAsText(arquivo.target.files[0]);
        fr.onload = function(event) {
            setStatus({
                erro: false,
                sucesso: false,
                mensagem: undefined
            });
            const separado = event.target.result.split("\r\n").slice(2, -3);
            separado.map(async (linha) => {
                if (linha[13] === "A") {
                    let [ocorrencias, efetivado, mensagem] = analisaOcorrenciasBanco(linha.slice(230).trim());
                    if (await enviarOcorrencias(linha.slice(73, 93).trim(), {ocorrencias: ocorrencias, mensagem: mensagem, pagamentoEfetivado: efetivado}) !== 200) setStatus({
                        erro: true,
                        sucesso: false,
                        mensagem: "Erro ao salvar as ocorrências"
                    }); else setStatus({
                        erro: false,
                        sucesso: true,
                        mensagem: "Ocorrências salvas com sucesso"
                    });
                }
            });
        }
    }

    async function deletarResiduo(){
        const resposta = await removerResiduos();
        if (resposta.status === 200) {
            setStatus({
                erro: false,
                sucesso: true,
                mensagem: resposta.mensagem
            });
        } else {
            setStatus({
                erro: true,
                sucesso: false,
                mensagem: "Erro ao tentar deletar residuos"
            });
        }
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Histórico comissões")}
                <AreaSuperior $margem="10px 50px 20px 40px">
                    <OpcoesFiltragem>
                        <FiltrarData $marginTop="1px">
                            <label>Data pagamento</label>
                            <InputMes type="date" onInput={ evento => setDataAtualizar(evento.target.value)}/>
                        </FiltrarData>
                        <Botao $marginTop="15px" $cursorDesabilitado={"wait"} disabled={isLoading} onClick={() => atualizaFiltros()}>Atualizar</Botao>
                    </OpcoesFiltragem>
                    <div style={{ display: "flex" }}>
                        <ArquivoEstilo>
                            Importar Retorno
                            <ImportarArquivo disabled={isLoading} type="file" accept=".ret" onChange={e => lerArquivoRetorno(e)}/>
                        </ArquivoEstilo> 
                        <Botao $marginTop="25px" $cursorDesabilitado={"wait"} disabled={isLoading} onClick={() => deletarResiduo()}>Remover Resíduo</Botao>
                    </div>
                </AreaSuperior>
                {isLoading && <ErroSpan>Carregando...</ErroSpan>}
                {status.erro && <ErroSpan>{status.mensagem}</ErroSpan>}
                {status.sucesso && <ErroSpan $corFundo={"#ddffcd"} $corTexto={"#209e00"} >{status.mensagem}</ErroSpan>}
                {TabelaHistorico(comissoesSalvas, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual, setStatus)}
                <PaginasTabela>
                    <p onClick={() => setPaginaAtual(1)}>Primeira</p>
                    <p onClick={() => {
                        if (paginaAtual > 1){
                            setPaginaAtual(paginaAtual-1)}
                        }
                    }>Anterior</p>
                    {paginas()}
                    <p onClick={() => setPaginaAtual(paginaAtual+1)}>Próxima</p>
                    <p onClick={() => setPaginaAtual(totalPaginas)}>Última</p>
                </PaginasTabela>
            </SecaoPrincipal>
        </SecaoAdmin>
    );

    function paginas() {
        let pages = [];
        let ajuste = 2;
        if (paginaAtual === 1){
            ajuste = 0;
        } else if (paginaAtual === 2){
            ajuste = 1;
        } else if (paginaAtual === totalPaginas-1 && totalPaginas > 4){
            ajuste = 3;
        } else if (paginaAtual === totalPaginas && totalPaginas > 4){
            ajuste = 4;
        }
        if (totalPaginas < 5){
            for (let i = 0; i < totalPaginas; i++){
                let pageNum = parseInt(i+1);
                if (pageNum === paginaAtual) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPaginaAtual(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
                let pageNum = parseInt(paginaAtual) + parseInt(i) - ajuste;
                if (pageNum === paginaAtual) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPaginaAtual(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}