import styled from "styled-components";

// outline: 3px solid var(--corNugaliEscura);
// outline-offset: -15px;

export const SecaoFotos = styled.section`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: hidden;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
`
export const SecaoFotosSemBorda = styled.section`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: hidden;
    outline-offset: -15px;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
`
export const DivCamera = styled.div`
    width: ${props => props.$largura || "640px"};
    height: ${props => props.$altura || "480px"};
    margin: auto;
    margin-top: 0;
    padding: 0;
    position: relative;
`
export const MascaraQrCode = styled.div`
    width: ${props => props.$largura || "640px"};
    height: ${props => props.$altura || "480px"};
    background-color: #0000007d;
    position: absolute;
    align-self: center;
    z-index: 1;

    clip-path: polygon(0% 0%, 0% 100%, 30% 100%, 30% 25%, 70% 25%, 70% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
`
export const BordaQr = styled.div`
    z-index: 2;
    position: absolute;
    background-color: ${props => props.$cor || "rgb(255, 255, 255)"};
    width: ${props => props.$largura || "40px"};
    height: ${props => props.$altura || "5px"};
    top: ${props => props.$topo || "120px"};
    left: ${props => props.$esquerda || "408px"};

`
export const Texto = styled.p`
    font-size: ${props => props.$tamanho || "18px"};
    font-weight: ${props => props.$bold || "normal"};
    color: ${props => props.$cor || "var(--corNugaliEscura)"};
    margin: ${props => props.$margem || "0"};
    background-color: ${props => props.$fundo || "transparent"};
    width: ${props => props.$largura || "auto"};
    padding: ${props => props.$pad || "0"};

    .Icone {
        width: 2em;
        height: 1.8em;
        position: relative;
        top: 20px;
    }
`
export const Forte = styled.strong`
    font-size: 36px;
    text-align: justify;
    display: inline-block;
`
export const Alinhar = styled.div`
    width: 100%;
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
`
export const BotaoComecar = styled.div`
    border: "none";
    background-color: var(--corNugaliEscura);
    color: var(--corNugaliClara);
    border-radius: 10px;
    width: 450px;
    padding: 8px;
    font-weight: bold;
    font-size: 50px;
    margin: ${props => props.$margem || "auto"};
    box-shadow: 2px 2px 20px 3px #414141;
    cursor: pointer;
`
export const Flash = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    animation: blink .2s;

    @keyframes blink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`
export const CameraFotos = styled.video`
    z-index: 10;
    width: 100vw;
    height: 100vh;
`
export const ContagemRegressiva = styled.p`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    text-align: center;
    background-color: transparent;
    font-size: 70px;
    font-weight: bold;
    color: white;
    z-index: 1;
    display: ${props => props.$mostrar? "block": "none"};
`
export const MostradorFotos = styled.div`
    display: flex;
    width: 1278px;
    /* background-color: blue; */
    margin: auto;
`
export const Quadro = styled.img`
    width: ${props => props.$largura || "400px"};
    background-color: white;
    padding: ${props => props.$pad || "8px 8px 20px 8px"};
    margin: ${props => props.$margem || "5px"};
    transform: ${props => props.$roda || "none"};
`
export const MensagemFinal = styled.div`
    display: block;
    text-justify: left;
    text-align: left;
`
