import { Link, useRouteError } from "react-router-dom";
import styled from "styled-components";

const PaginaErroStyled = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
`
const BotaoHome = styled.button`
    background-color: transparent;
    border-radius: 5px;
    font-size: 24px;
    border: 2px solid var(--corNugaliEscura);
    padding: 5px;
    
    &:hover{
        background-color: var(--corNugaliEscura);
        cursor: pointer;
    }
`

function PaginaErro() {
    const erro = useRouteError();

    return (
        <PaginaErroStyled>
            <h1>Opa!</h1>
            <p>Essa página não existe</p>
            <br/>
            <p>{erro.statusText || erro.message}</p>
            <Link to="/"><BotaoHome>Home</BotaoHome></Link>
        </PaginaErroStyled>
    )
}

export default PaginaErro;