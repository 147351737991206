import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColunaTabela, LinhaTabela, SecaoIngressosDia, TabelaHorarios } from "./IngressosDiaStyled";
import { buscaSessao } from "../../servicos/sessoesServices";
import ErroSpan from "../../componentes/ErroSpan";
import Titulo1 from "../../componentes/Titulo1";

export default function IngressosDia() {
    const [sessoes, setSessoes] = useState([]);
    const navigate = useNavigate();

    async function obterSessoes() {
        buscaSessao(new Date().toISOString().split("T")[0]).then(resposta => {
            if (resposta.status) navigate("/login", { state: { redirecionar: window.location.pathname }}); else setSessoes(resposta.dados);
        });
    }

    useEffect(() => {
        obterSessoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            obterSessoes();
        }, 20000); // 20000 milliseconds = 20 seconds
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (sessoes.length === 0) {
        return <ErroSpan>Carregando...</ErroSpan>
    } else {
        return (
            <SecaoIngressosDia>
                <Titulo1 $margem="20px 0 10px 0" $tamanho="2.5em" $alinhamento="center" $corFundo="transparent" $cor="var(--corNugaliEscura)">INGRESSOS DISPONÍVEIS POR SESSÃO</Titulo1>
                <TabelaHorarios>
                    <tbody>
                        {sessoes.slice(0, 9).map((sessao, index) => {
                            const atraso = - 5*60;
                            return (<LinhaTabela key={index}>
                                {((new Date().getTime() / 1000 + atraso > new Date(sessao.diaHora).getTime() / 1000 + 3 * 60 * 60 || sessao.ticketsDisponiveis === 0) && (((new Date().getTime() / 1000 + atraso < new Date(sessao.diaHora).getTime() / 1000 + 3 * 60 * 60 && new Date().getTime() / 1000 + atraso > new Date(sessao.diaHora).getTime() / 1000 + 3 * 60 * 60 - 10*60) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessao.diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>) || <ColunaTabela $cor={"#bbad94"}>{sessao.diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>)) || (new Date().getTime() / 1000 + atraso > new Date(sessao.diaHora).getTime() / 1000 + 3 * 60 * 60 - 60 * 10 && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessao.diaHora.split("T")[1].slice(0, 5)}   {sessao.ticketsDisponiveis}</ColunaTabela>) || <ColunaTabela>{sessao.diaHora.split("T")[1].slice(0, 5)}   {sessao.ticketsDisponiveis}</ColunaTabela>}

                                {((new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 9].diaHora).getTime() / 1000 + 3 * 60 * 60 || sessoes[index + 9].ticketsDisponiveis === 0) && (((new Date().getTime() / 1000 + atraso < new Date(sessoes[index + 9].diaHora).getTime() / 1000 + 3 * 60 * 60 + 60 * 10 && new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 9].diaHora).getTime() / 1000 + 3 * 60 * 60) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 9].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>) || <ColunaTabela $cor={"#bbad94"}>{sessoes[index + 9].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>)) || ((new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 9].diaHora).getTime() / 1000 + 3 * 60 * 60 + 60 * 10) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 9].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 9].ticketsDisponiveis}</ColunaTabela>) || <ColunaTabela>{sessoes[index + 9].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 9].ticketsDisponiveis}</ColunaTabela>}

                                {((new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 18].diaHora).getTime() / 1000 + 3 * 60 * 60 || sessoes[index + 18].ticketsDisponiveis === 0) && (((new Date().getTime() / 1000 + atraso < new Date(sessoes[index + 18].diaHora).getTime() / 1000 + 3 * 60 * 60 && new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 18].diaHora).getTime() / 1000 + 3 * 60 * 60 - 10*60) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 18].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>) || <ColunaTabela $cor={"#bbad94"}>{sessoes[index + 18].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>)) || (new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 18].diaHora).getTime() / 1000 + 3 * 60 * 60 - 60 * 10 && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 18].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 18].ticketsDisponiveis}</ColunaTabela>) || <ColunaTabela>{sessoes[index + 18].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 18].ticketsDisponiveis}</ColunaTabela>}

                                {((new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 27].diaHora).getTime() / 1000 + 3 * 60 * 60 || sessoes[index + 27].ticketsDisponiveis === 0) && (((new Date().getTime() / 1000 + atraso < new Date(sessoes[index + 27].diaHora).getTime() / 1000 + 3 * 60 * 60 && new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 27].diaHora).getTime() / 1000 + 3 * 60 * 60 - 10*60) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 27].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>) || <ColunaTabela $cor={"#bbad94"}>{sessoes[index + 27].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>)) || (new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 27].diaHora).getTime() / 1000 + 3 * 60 * 60 - 60 * 10 && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 27].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 27].ticketsDisponiveis}</ColunaTabela>) || <ColunaTabela>{sessoes[index + 27].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 27].ticketsDisponiveis}</ColunaTabela>}

                                {((new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 36].diaHora).getTime() / 1000 + 3 * 60 * 60 || sessoes[index + 36].ticketsDisponiveis === 0) && (((new Date().getTime() / 1000 + atraso < new Date(sessoes[index + 36].diaHora).getTime() / 1000 + 3 * 60 * 60 && new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 36].diaHora).getTime() / 1000 + 3 * 60 * 60 - 10*60) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 36].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>) || <ColunaTabela $cor={"#bbad94"}>{sessoes[index + 36].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>)) || (new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 36].diaHora).getTime() / 1000 + 3 * 60 * 60 - 60 * 10 && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 36].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 36].ticketsDisponiveis}</ColunaTabela>) || <ColunaTabela>{sessoes[index + 36].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 36].ticketsDisponiveis}</ColunaTabela>}

                                {((new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 45].diaHora).getTime() / 1000 + 3 * 60 * 60 || sessoes[index + 45].ticketsDisponiveis === 0) && (((new Date().getTime() / 1000 + atraso < new Date(sessoes[index + 45].diaHora).getTime() / 1000 + 3 * 60 * 60 && new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 45].diaHora).getTime() / 1000 + 3 * 60 * 60 - 10*60) && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 45].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>) || <ColunaTabela $cor={"#bbad94"}>{sessoes[index + 45].diaHora.split("T")[1].slice(0, 5)}   -</ColunaTabela>)) || (new Date().getTime() / 1000 + atraso > new Date(sessoes[index + 45].diaHora).getTime() / 1000 + 3 * 60 * 60 - 60 * 10 && <ColunaTabela $cor={"var(--corNugaliClara)"} $fundo="var(--corNugaliEscura)">{sessoes[index + 45].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 45].ticketsDisponiveis}</ColunaTabela>) || <ColunaTabela>{sessoes[index + 45].diaHora.split("T")[1].slice(0, 5)}   {sessoes[index + 45].ticketsDisponiveis}</ColunaTabela>}
                            </LinhaTabela>)
                        })}
                    </tbody>
                </TabelaHorarios>
            </SecaoIngressosDia>
        )
    }
}