import { z } from "zod";

const novaSenhaSchema = z.object({
    senhaAtual: z.string().min(6, { message: "Senha atual incorreta"}),
    novaSenha: z.string().min(6, { message: "A senha deve ter no mínimo 6 caracteres" }),
    confirmNovaSenha: z.string().min(6, { message: "Senha deve ter no mínimo 6 caracteres" })
}).refine( dados => dados.novaSenha === dados.confirmNovaSenha, {
    message: "As senhas não correspondem",
    path: ["confirmNovaSenha"]
});

export default novaSenhaSchema;