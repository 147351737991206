import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { BotaoAcoes } from "../AdminStyled";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
`
export function TabelaSessoes(sessoes, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual) {

    const campos = [
        {chave: "diaHora", label: "Dia"},
        {chave: "horario", label: "Horário"},
        {chave: "ticketsDisponiveis", label: "Tickets Disponíveis"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }
    
    try {
        return (
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                        <th style={{ color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>
                    {sessoes.map((sessao) => {
                        return (<LinhaTabela key={sessao._id}> 
                            <ColunaTabela>{new Date(sessao.diaHora).toLocaleDateString('en-GB')}</ColunaTabela>
                            <ColunaTabela>{sessao.diaHora.split("T")[1].slice(0,5)}</ColunaTabela>
                            <ColunaTabela>{sessao.ticketsDisponiveis}</ColunaTabela>
                            <ColunaTabela>
                                <BotaoAcoes><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${sessao._id}`}> Visualizar</Link></BotaoAcoes>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
        )
    } catch (e) {
        return <></>
    }
}