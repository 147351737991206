import { salvarComissoes } from "../../../servicos/comissoesServices.js";

function completaComEspacos(arquivo, posicaoFinal = 240){
    for (let j = arquivo.length; j < posicaoFinal; j++){
        arquivo = arquivo + " ";
    }
    return arquivo;
}

function formatarChavePix(chavePix, tipoChavePix){
    switch (tipoChavePix) {
        case "Celular":
            tipoChavePix = "01 ";
            chavePix = chavePix.replace(/[^0-9]/g, "");     // Deixa apenas números
            if (chavePix.length === 10) chavePix = chavePix.substring(0, 2) + "9" + chavePix.substring(2);
            if (chavePix.length === 11) chavePix = "55" + chavePix;
            chavePix = "+" + chavePix;
            break;
        case "E-mail":
            tipoChavePix = "02 ";
            chavePix = chavePix.toLowerCase();
            break;
        case "CPF/CNPJ":
            tipoChavePix = "03 ";
            chavePix = chavePix.replace(/[^0-9]/g, "");     // Deixa apenas números
            break;
        default:
            tipoChavePix = "04 ";
    }
    return [chavePix, tipoChavePix];
}

async function salvarComissoesBD(comissoes){
    let salvar = [];
    comissoes.forEach( com => {
        salvar.push( {
            ...com,
            guia: com.guia.id
        });
    })
    salvarComissoes(salvar);
}

export function baixarArquivo(comissoes, setErro){
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const data = new Date(Date.now());
    setErro("");
    salvarComissoesBD(comissoes);
    
    const empresa = "206229154000136448566              0185460000000207403 NUGALI CHOCOLATES INDUSTRIA E ";
    const enderecoEmpresa = "Rua Testo Alto                04848               POMERODE            89107000SC";
    const banco = "BRADESCO                      ";
    const bancoCod = "237";
    const observacao = "PAGAMENTO GUIAS     ";
    const ispb = "60746948";
    let seqLote = 1;
    
    // ---- HEADER ARQUIVO - Linha 1 ---- //
    let headerArquivo = completaComEspacos(bancoCod + "00000         " + empresa + banco + "          1" + comissoes[0].identificador.slice(0,14) + "00000108901600PIX                 " + observacao);
    // ---- HEADER ARQUIVO - Linha 1 ---- //
    
    // ------ HEADER LOTE - Linha 2 ----- //
    const mensagem1 = "COMISSIONAMENTO GUIAS " + zeroPad(data.getDate(), 2) + "-" + zeroPad(data.getMonth() + 1, 2) + "-" + data.getFullYear() + "        ";
    let headerLote = completaComEspacos(bancoCod + zeroPad(seqLote, 4) + "1C2045045 " + empresa + mensagem1 + enderecoEmpresa + "01");
    // ------ HEADER LOTE - Linha 2 ----- //
    
    let arquivo = headerArquivo + "\r\n" + headerLote + "\r\n"; // \r\n é a forma de pular linha no padrão windows (aceito pelo multipag)
    
    // --------- REGISTROS --------- //
    let seqRegistro = 1;
    const bancoFavorecido = "00000000 000000000000  ";
    const mensagem2 = "06229154000136" + ispb + "01                ";
    let registroDetalheA = "";
    let registroDetalheB = "";
    let guiasSemCpf = "";
    // let registroDetalheJ = "";
    
    for (let i = 0; i < comissoes.length; i++){
        let nomeGuia;
        let [pixGuia, tipoPix] = formatarChavePix(comissoes[i].guia.chavePix, comissoes[i].guia.tipoChavePix);
        let beneficiario = "";

        if (tipoPix === "03 ") {
            beneficiario = pixGuia;
        } else {
            if (!comissoes[i].guia.cpfCnpjBeneficiario) {
                guiasSemCpf += `${comissoes[i].guia.nome}, `;
            } else {
                beneficiario = comissoes[i].guia.cpfCnpjBeneficiario.replace(/[^0-9]/g, "");
            }
        }
        let mudar = "2";
        if (beneficiario.length < 14) mudar = "1";
        beneficiario = zeroPad(beneficiario, 14);
        beneficiario = mudar + beneficiario;
    
        if (comissoes[i].guia.nome.length > 30){
            nomeGuia = comissoes[i].guia.nome.slice(0, 30);
        } else {
            nomeGuia = comissoes[i].guia.nome;
            nomeGuia = completaComEspacos(nomeGuia, 30);
        }
        // --- REGISTRO DETALHE A - Linha 3 ---- //
        registroDetalheA = bancoCod + zeroPad(seqLote, 4) + "3" + zeroPad(seqRegistro, 5) + "A000009" + bancoFavorecido + nomeGuia + completaComEspacos(comissoes[i].identificador, 20) + zeroPad(data.getDate(), 2) + zeroPad(data.getMonth() + 1,2) + data.getFullYear() + "BRL" + zeroPad(Math.floor(comissoes[i].comissaoTotal), 10) + (comissoes[i].comissaoTotal.toFixed(2).split(".")[1] || 0);
        if (registroDetalheA.length === 115) registroDetalheA += "0000"; else registroDetalheA += "000"
        registroDetalheA += zeroPad(Math.floor(comissoes[i].comissaoTotal), 13)+ (comissoes[i].comissaoTotal.toFixed(2).split(".")[1] || "00");
        if (registroDetalheA.length === 133) registroDetalheA += "0";
        registroDetalheA += "                    00000000000000000000000" + mensagem2 + "            6";
        registroDetalheA = completaComEspacos(registroDetalheA);
        // --- REGISTRO DETALHE A - Linha 3 ---- //
    
        seqRegistro++;
    
        // --- REGISTRO DETALHE B - Linha 4 ---- //
        registroDetalheB = completaComEspacos(completaComEspacos(bancoCod + zeroPad(seqLote, 4) + "3" + zeroPad(seqRegistro, 5) + "B" + tipoPix + beneficiario, 127) + pixGuia, 226) + "000000" + ispb;
        // --- REGISTRO DETALHE B - Linha 4 ---- //
    
        arquivo = arquivo + registroDetalheA + "\r\n" + registroDetalheB + "\r\n";
        seqRegistro++;
    }
    
    const totalLote = Number(comissoes.reduce( (total, atual) => total + atual.comissaoTotal, 0).toFixed(2));
    
    // ----- TRAILER DE LOTE ----- //
    let trailerLote = bancoCod + zeroPad(seqLote, 4) + "5         " + zeroPad(seqRegistro + 1, 6) + zeroPad(Math.floor(totalLote), 16) + (String(totalLote).split(".")[1] || "00");
    if (trailerLote.length === 40) trailerLote += "0";
    trailerLote = completaComEspacos(trailerLote + "000000000000000000000000"); 
    // ----- TRAILER DE LOTE ----- //
    
    // ---- TRAILER DE ARQUIVO --- //
    let trailerAqruivo = completaComEspacos(bancoCod + "99999         " + zeroPad(seqLote, 6) + zeroPad(seqRegistro + 3, 6) + "000000");
    // ---- TRAILER DE ARQUIVO --- //
    
    arquivo = arquivo + trailerLote + "\r\n" + trailerAqruivo + "\r\n";
    
    try {
        const blob = new Blob([arquivo], { type: "text/plain" });
        
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `Guias${data.getFullYear()}${zeroPad(data.getMonth() + 1, 2)}${zeroPad(data.getDate(), 2)}.txt`;
        link.href = url;
        link.click();
        if (guiasSemCpf) setErro(`Guias sem CPF/CNPJ: ${guiasSemCpf}`)
    } catch (e) {
        setErro(e.message);
    }
}