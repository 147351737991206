import Cookies from "js-cookie";

export async function obterMensagemPorId(id) {
    try {
        const resposta = await fetch(`/api/whatsapp/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterMensagens(contato) {
    try {
        const resposta = await fetch(`/api/whatsapp/busca?wa_id=${contato}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}