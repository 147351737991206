import styled from "styled-components";

export const SecaoSessoes = styled.section`
    background-color: white;
`
export const VisualizaSessao = styled.div`
    display: flex;
    padding-left: 30px;
    border-bottom: 1px solid #aaaaaa;
`
export const VisualizaTickets = styled.div`
    display: block;
    margin: 0;
`
export const InfosSessao = styled.p`
    color: ${props => props.$cor || "var(--corNugaliEscura)"};
    margin: ${props => props.$margem || "15px 30px 15px 0"};
    font-size: ${props => props.$fonte || "20px"};
    font-weight: ${props => props.$bold || "normal"};
    cursor: ${props => props.$cursor || "normal"};
`