import Cookies from "js-cookie";

export async function ValidaLogin(email, senha) {
    try {
        const resposta = await (await fetch("/api/login", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                senha: senha
            })
        })).json();
        Cookies.set("token_tour", resposta.token, { expires: 1000 });
        return resposta;
    } catch (e) {
        console.log(e);
    }
}

export async function usuarioAtual() {
    try {
        const resposta = await fetch("/api/usuario-logado", {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

export async function listarUsuarios(busca, ordenacao, ordem) {
    try {
        const resposta = await fetch(`/api/usuarios?busca=${busca}&ordenacao=${ordenacao}&ordem=${ordem}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function deletarUsuario(id) {
    try {
        const resposta = await fetch(`/api/usuarios/${id}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function criarUsuario(dados) {
    try {
        delete dados.confirmPassword;
        dados.permissoes = dados.permissoes.value;
        const resposta = await fetch("/api/usuarios", {
            method: "post",
            body: JSON.stringify(dados),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        })
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function alterarSenha(usuario, senhaAtual, senhaNova) {
    try {
        const resposta = await fetch("/api/login", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({ email: usuario.email, senha: senhaAtual })
        });
        if ((await resposta.json()).status !== 200) return { senhaAlterada: false, mensagem: "Senha atual inválida!" };

        const resposta2 = await fetch("/api/usuarios/mudar-senha", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({ senha: senhaNova })
        });
        if ((await resposta2.json()).status !== 200) return { senhaAlterada: false, mensagem: "Erro ao tentar salvar nova senha" };
        return {
            senhaAlterada: true,
            mensagem: "Senha atualizada"
        }
    } catch (e) {
        console.log(e.message);
        return null;
    }
}