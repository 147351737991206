import { useEffect, useState } from "react";
import { AreaFotos, Piscar, SecaoMural } from "./MuralStyled";
import { getFotosMural } from "../../servicos/fotosServices";
import img from "../../assets/imagens/mural.png";
import { useNavigate } from "react-router-dom";

export default function Mural(){
    const [fotos, setFotos] = useState([]);
    const [nomesFotos, setNomesFotos] = useState([]);
    const [posicoes, setPosicoes] = useState([0,1,2,3,4,5,6,7]);
    const [pisca, setPisca] = useState([false, false, false, false, false, false, false, false]);
    const navigate = useNavigate();
    const dimensoes = { largura: window.innerWidth, altura: window.innerHeight }

    async function obterFotos(){
        return await getFotosMural(nomesFotos);
    }
    
    useEffect(() => {
        obterFotos().then( resposta => {
            if (!resposta.status || resposta.status !== 200) navigate("/login", { state: { redirecionar: window.location.pathname }}); else {
                setFotos(resposta.fotos);
                setNomesFotos(resposta.nomes);
            };
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const intervalo = setInterval(() => {
            if(fotos.length !== 0) {
                obterFotos().then( async resposta => {
                    if (resposta.status === 200 && resposta.fotos.length > 0){
                        setNomesFotos(resposta.nomes);
                        let novasFotos = fotos;
                        for (let i = 0; i < resposta.fotos.length; i++){
                            novasFotos = [resposta.fotos[i], ...novasFotos.slice(0, 7)];
                        };
                        for (let i = 0; i < novasFotos.length; i++) {
                            setPosicoes( posAtual => {
                                setPisca( atual => {
                                    const novo = [...atual];
                                    novo[posAtual.findIndex( e => e === i)] = true;
                                    return novo;
                                })
                                // novo[posicoes[i]] = true;
                                return posAtual;
                            });
                            await new Promise(resolve => setTimeout(resolve, 500));
                            setFotos(prevFotos => {
                                const novas = [...prevFotos];
                                novas[i] = novasFotos[i];
                                return novas;
                            });
                            setPisca([false, false, false, false, false, false, false, false]);
                            await new Promise(resolve => setTimeout(resolve, 500));
                        }
                    } else {
                        const novasPosicoes = [0,1,2,3,4,5,6,7].sort(() => (Math.random() - 0.5));
                        for (let i = resposta.fotos.length; i < 8; i++){
                            setPisca( atual => {
                                const novo = [...atual];
                                novo[i] = true;
                                return novo;
                            });
                            await new Promise(resolve => setTimeout(resolve, 500));
                            setPosicoes(prevPosicoes => {
                                const novas = [...prevPosicoes];
                                novas[i] = novasPosicoes[i];
                                return novas;
                            });
                            setPisca([false, false, false, false, false, false, false, false]);
                            await new Promise(resolve => setTimeout(resolve, 200));
                        }
                    }
                });
            }
        }, 20000);
        return () => clearInterval(intervalo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fotos]);

    if (fotos.length === 0) {
        return (<p>Carregando...</p>)
    } else {
        return (
            <SecaoMural>
                <AreaFotos $largura="100%" $altura="100%" $z="20" src={img} alt="Imagem fundo"/>
                <Piscar 
                    $esquerda={`${59*dimensoes.largura/1366}px`} 
                    $topo={`${67*dimensoes.altura/768}px`} 
                    $roda={Math.atan((78*dimensoes.altura/1524)/(564*dimensoes.largura/2730))*180/Math.PI} 
                    $z="10"
                    $pisca={pisca[0]}
                />
                <AreaFotos 
                    $z="9" 
                    $esquerda={`${59*dimensoes.largura/1366}px`} 
                    $topo={`${67*dimensoes.altura/768}px`} 
                    $roda={Math.atan((78*dimensoes.altura/1524)/(564*dimensoes.largura/2730))*180/Math.PI} 
                    src={`data:image/jpeg;base64,${fotos[posicoes[0]]}`} 
                    alt="Foto 1"
                />
                <Piscar 
                    $esquerda={`${532*dimensoes.largura/1366}px`}  
                    $topo={`${40*dimensoes.altura/768}px`}  
                    $roda={Math.atan((-99*dimensoes.altura/1524)/(630*dimensoes.largura/2730))*180/Math.PI}  
                    $z="8"
                    $pisca={pisca[1]}
                />
                {fotos[1] && <AreaFotos 
                    $z="7" 
                    $esquerda={`${532*dimensoes.largura/1366}px`}  
                    $topo={`${40*dimensoes.altura/768}px`}  
                    $roda={Math.atan((-99*dimensoes.altura/1524)/(630*dimensoes.largura/2730))*180/Math.PI}  
                    src={`data:image/jpeg;base64,${fotos[posicoes[1]]}`} 
                    alt="Foto 2"
                />}
                <Piscar 
                    $esquerda={`${56*dimensoes.largura/1366}px`}  
                    $topo={`${388*dimensoes.altura/768}px`} 
                    $roda={Math.atan((-142*dimensoes.altura/1524)/(562*dimensoes.largura/2730))*180/Math.PI} 
                    $z="8"
                    $pisca={pisca[2]}
                />
                {fotos[2] && <AreaFotos 
                    $z="7" 
                    $esquerda={`${56*dimensoes.largura/1366}px`}  
                    $topo={`${388*dimensoes.altura/768}px`} 
                    $roda={Math.atan((-142*dimensoes.altura/1524)/(562*dimensoes.largura/2730))*180/Math.PI} 
                    src={`data:image/jpeg;base64,${fotos[posicoes[2]]}`} 
                    alt="Foto 3"
                />}
                <Piscar 
                    $esquerda={`${534*dimensoes.largura/1366}px`}  
                    $topo={`${326*dimensoes.altura/768}px`} 
                    $roda={Math.atan((32*dimensoes.altura/1524)/(607*dimensoes.largura/2730))*180/Math.PI}  
                    $z="8"
                    $pisca={pisca[3]}
                />
                {fotos[3] && <AreaFotos 
                    $z="7" 
                    $esquerda={`${534*dimensoes.largura/1366}px`}  
                    $topo={`${326*dimensoes.altura/768}px`} 
                    $roda={Math.atan((32*dimensoes.altura/1524)/(607*dimensoes.largura/2730))*180/Math.PI}  
                    src={`data:image/jpeg;base64,${fotos[posicoes[3]]}`} 
                    alt="Foto 4"
                />}
                <Piscar 
                    $esquerda={`${566*dimensoes.largura/1366}px`}  
                    $topo={`${596*dimensoes.altura/768}px`} 
                    $roda={Math.atan((98*dimensoes.altura/1524)/(520*dimensoes.largura/2730))*180/Math.PI} 
                    $z="6"
                    $pisca={pisca[4]}
                />
                {fotos[4] && <AreaFotos 
                    $z="5" 
                    $esquerda={`${566*dimensoes.largura/1366}px`}  
                    $topo={`${596*dimensoes.altura/768}px`} 
                    $roda={Math.atan((98*dimensoes.altura/1524)/(520*dimensoes.largura/2730))*180/Math.PI} 
                    src={`data:image/jpeg;base64,${fotos[posicoes[4]]}`} 
                    alt="Foto 5"
                />}
                <Piscar 
                    $esquerda={`${1010*dimensoes.largura/1366}px`}  
                    $topo={`${176*dimensoes.altura/768}px`} 
                    $roda={Math.atan((172*dimensoes.altura/1524)/(540*dimensoes.largura/2730))*180/Math.PI}  
                    $z="9"
                    $pisca={pisca[5]}
                />
                {fotos[5] && <AreaFotos 
                    $z="8" 
                    $esquerda={`${1010*dimensoes.largura/1366}px`}  
                    $topo={`${176*dimensoes.altura/768}px`} 
                    $roda={Math.atan((172*dimensoes.altura/1524)/(540*dimensoes.largura/2730))*180/Math.PI}   
                    src={`data:image/jpeg;base64,${fotos[posicoes[5]]}`} 
                    alt="Foto 6"
                />}
                <Piscar 
                    $esquerda={`${1028*dimensoes.largura/1366}px`}  
                    $topo={`${507*dimensoes.altura/768}px`} 
                    $roda={Math.atan((171*dimensoes.altura/1524)/(496*dimensoes.largura/2730))*180/Math.PI}   
                    $z="4"
                    $pisca={pisca[6]}
                />
                {fotos[6] && <AreaFotos 
                    $z="3" 
                    $esquerda={`${1028*dimensoes.largura/1366}px`}  
                    $topo={`${507*dimensoes.altura/768}px`} 
                    $roda={Math.atan((171*dimensoes.altura/1524)/(496*dimensoes.largura/2730))*180/Math.PI}  
                    src={`data:image/jpeg;base64,${fotos[posicoes[6]]}`} 
                    alt="Foto 7"
                />}
            </SecaoMural>
        )
    }
}