import { Link } from "react-router-dom";
import Botao from "../../../componentes/Botao";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { AreaBotoesAdd, DivFormulario, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { alterarSenha } from "../../../servicos/usuariosServices";
import { zodResolver } from "@hookform/resolvers/zod";
import ErroSpan from "../../../componentes/ErroSpan";
import novaSenhaSchema from "../../../schemas/novaSenhaSchema";
import { useUsuarioContext } from "../../../contexto/SessaoUsuario";

export default function AlterarSenha(){
    const [status, setStatus] = useState({
        sucesso: false,
        erro: false,
        mensagem: ""
    });
    const { usuario } = useUsuarioContext();

    const { 
        register: registerSenha, 
        handleSubmit: handleSubmitSenha, 
        reset: resetSenha,
        formState: { errors: errosSenha }
    } = useForm({
        resolver: zodResolver(novaSenhaSchema)
    });

    async function AtualizarSenha(dados){
        const resposta = await alterarSenha(usuario, dados.senhaAtual, dados.novaSenha);
        if (resposta.senhaAlterada) {
            resetSenha();
            setStatus({
                sucesso: true,
                erro: false,
                mensagem: ""
            });
        } else {
            setStatus({
                sucesso: false,
                erro: true,
                mensagem: resposta.mensagem
            });
        }
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Alterar senha")}
                {status.erro && <ErroSpan>{"Erro ao alterar senha. " + status.mensagem}</ErroSpan>}
                {status.sucesso && <ErroSpan $corFundo={"#ddffcd"} $corTexto={"#209e00"} >{`Senha alterada com sucesso.`}</ErroSpan>}
                <form onSubmit={handleSubmitSenha(AtualizarSenha)}>
                <AreaBotoesAdd style={{ marginTop: "20px" }}>
                    <Botao type="submit" $corFundo="#3a170ef8" $cor="white" $marginRight="10px">Salvar</Botao>
                    <Link to="/interno"><Botao $marginRight="40px">Cancelar</Botao></Link>
                </AreaBotoesAdd>
                <DivFormulario>
                    <label>Senha Atual</label>
                    <input {...registerSenha("senhaAtual", {required: "Informe a senha atual"})} onChange={() => setStatus({sucesso: false, erro: false, mensagem: ""})} type="password"></input>
                    {errosSenha.senhaAtual && <ErroSpan>{errosSenha.senhaAtual.message}</ErroSpan>}
                    <label>Nova senha</label>
                    <input {...registerSenha("novaSenha", {required: "Campo obrigatório"})} onChange={() => setStatus({sucesso: false, erro: false, mensagem: ""})} type="password"></input>
                    {errosSenha.novaSenha && <ErroSpan>{errosSenha.novaSenha.message}</ErroSpan>}
                    <label>Confirmar nova senha</label>
                    <input {...registerSenha("confirmNovaSenha", {required: "Campo obrigatório"})} onChange={() => setStatus({sucesso: false, erro: false, mensagem: ""})} type="password"></input>
                    {errosSenha.confirmNovaSenha && <ErroSpan>{errosSenha.confirmNovaSenha.message}</ErroSpan>}
                </DivFormulario>

            </form>
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}