import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PaginaErro from "./paginas/paginaErro/PaginaErro";
import Tickets from "./paginas/tickets/Tickets";
import Fotos from "./paginas/fotos/Fotos";
import Login from "./paginas/login/Login";
import IngressosDia from "./paginas/ingressosDia/IngressosDia";
import Mural from "./paginas/fotos/Mural";
import Admin from "./paginas/admin/Admin";
import Sessoes from "./paginas/admin/sessoes/Sessoes";
import SessaoId from "./paginas/admin/sessoes/SessaoId";
import Visitantes from "./paginas/admin/visitantes/Visitantes";
import VisitanteId from "./paginas/admin/visitantes/VisitanteId";
import Ingressos from "./paginas/admin/ingressos/Ingressos";
import Usuarios from "./paginas/admin/usuarios/Usuarios";
import VisualizadorFoto from "./paginas/fotos/VisualizadorFoto";
import CadastrarUsuario from "./paginas/admin/usuarios/CadastrarUsuario";
import AlterarSenha from "./paginas/admin/usuarios/AlterarSenha";
import IngressoId from "./paginas/admin/ingressos/IngressoId";
import Guias from "./paginas/admin/guias/Guias";
import CriarTickets from "./paginas/tickets/CriarTickets";
import GuiaId from "./paginas/admin/guias/GuiaId";
import AdicionarGuia from "./paginas/admin/guias/AdicionarGuia";
import Comissoes from "./paginas/admin/comissoes/Comissoes";
import Historico from "./paginas/admin/comissoes/Historico";
import CoresGuias from "./paginas/coresGuias/coresGuias";
import HistoricoGuia from "./paginas/admin/guias/HistoricoGuia";
import WhatsApp from "./paginas/admin/whatsapp/WhatsApp";
import CancelarIngresso from "./paginas/admin/ingressos/CancelarIngresso";
import Indicadores from "./paginas/admin/relatorios/Indicadores";
import PorTipo from "./paginas/admin/relatorios/PorTipo/PorTipo";
import Excursoes from "./paginas/admin/relatorios/PorTipo/Excursoes";
import Onlines from "./paginas/admin/relatorios/PorTipo/Online";
import PorGuias from "./paginas/admin/relatorios/PorTipo/PorGuias";
import Cancelados from "./paginas/admin/ingressos/Cancelados";

function App() {
	const rotas = createBrowserRouter([
		{
			path: "/",
			element: <Tickets />,
			errorElement: <PaginaErro />
		},
		{
			path: "/login",
			element: <Login />,
			errorElement: <PaginaErro />
		},
		{
			path: "/fotos",
			element: <Fotos />,
			errorElement: <PaginaErro />
		},
		{
			path: "/mural",
			element: <Mural />,
			errorElement: <PaginaErro />
		},
		{
			path: "/ingressos-dia",
			element: <IngressosDia />,
			errorElement: <PaginaErro />
		},
		{
			path: "/criar-tickets",
			element: <CriarTickets />,
			errorElement: <PaginaErro />
		},
		{
			path: "/cores-guias/:loja",
			element: <CoresGuias />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin",
			element: <Admin />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/visitantes",
			element: <Visitantes />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/visitantes/:id",
			element: <VisitanteId />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/ingressos",
			element: <Ingressos />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/ingressos/:id",
			element: <IngressoId />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/ingressos/cancelar",
			element: <CancelarIngresso />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/ingressos/cancelados",
			element: <Cancelados />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/sessoes",
			element: <Sessoes />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/sessoes/:id",
			element: <SessaoId />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/usuarios",
			element: <Usuarios />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/usuarios/cadastrar",
			element: <CadastrarUsuario />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/alterar-senha",
			element: <AlterarSenha />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/guias",
			element: <Guias />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/guias/cadastrar",
			element: <AdicionarGuia />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/guias/:id",
			element: <GuiaId />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/guias/:id/historico",
			element: <HistoricoGuia />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/comissoes",
			element: <Comissoes />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/comissoes/historico",
			element: <Historico />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/whatsapp",
			element: <WhatsApp />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/indicadores",
			element: <Indicadores />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/indicadores/tipo",
			element: <PorTipo />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/indicadores/excursao",
			element: <Excursoes />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/indicadores/online",
			element: <Onlines />,
			errorElement: <PaginaErro />
		},
		{
			path: "/admin/indicadores/guias",
			element: <PorGuias />,
			errorElement: <PaginaErro />
		},
		{
			path: "/foto/:foto",
			element: <VisualizadorFoto />,
			errorElement: <PaginaErro />
		}
	]);

	return (
		<RouterProvider router={rotas} />
	);
}

export default App;
