import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AreaSuperior, BotaoFiltro, LinksPaginas, PaginasTabela, RodapePaginas, SecaoAdmin, SecaoPrincipal, TotalDocs } from "../AdminStyled";
import { getGuias } from "../../../servicos/guiasServices";
import { TabelaGuias } from "./TabelaGuias";
import { FiltrosGuias } from "./FiltrosGuia";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import Botao from "../../../componentes/Botao";
import Pesquisa from "../../../componentes/Pesquisa";

export default function Guias() {
    const [guias, setGuias] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [ordenacao, setOrdenacao] = useState("codigo");
    const [ordem, setOrdem] = useState(1);
    const [total, setTotal] = useState({ paginas: 1, docs: 0 });
    const [busca, setBusca] = useState(sessionStorage.getItem("busca") || "");
    const [filtros, setFiltros] = useState(JSON.parse(sessionStorage.getItem("filtros")) || {});
    const [showFiltros, setShowFiltros] = useState(false);
    const [aviso, setAviso] = useState({ mostrar: false, guia: null });

    useEffect(() => {
        obterGuias(filtros, ordenacao, ordem, pagina);
    }, [filtros, ordenacao, ordem, pagina, aviso]);

    useEffect(() => {
        setFiltros(f => ({
            ...f,
            nome: {
                $regex: busca?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                $options: "i"
            }
        }));
    }, [busca]);

    useEffect(() => {
        sessionStorage.setItem('filtros', JSON.stringify(filtros));
        sessionStorage.setItem('busca', busca);
    }, [filtros, busca]);

    async function obterGuias(filtros, ordenacao, ordem, pagina){
        const resposta = await getGuias(filtros, ordenacao, ordem, pagina);
        setTotal({ paginas: Math.ceil(resposta.totalDocs/20), docs: resposta.totalDocs });
        setGuias(resposta.dados);
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Guias")}
                <AreaSuperior>
                    <RodapePaginas>
                        <Link to="/admin/guias/cadastrar"><Botao $marginTop="10px" $marginRight="10px">Cadastrar guia</Botao></Link>
                        <Botao $marginTop="10px" onClick={() => setShowFiltros(true)}>Filtrar</Botao>
                        <BotaoFiltro $mostrar={filtros?.codigo || filtros?.cidade || filtros?.estado || filtros?.tipoChavePix || filtros?.cpfCnpjBeneficiario || filtros?.empresa || filtros?.$or || filtros?.whatsApp} onClick={() => {
                            setFiltros({nome: filtros.nome});
                        }}>Remover filtros</BotaoFiltro>
                    </RodapePaginas>
                    {Pesquisa(setBusca, setPagina, busca)}
                </AreaSuperior>
                {FiltrosGuias(showFiltros, setShowFiltros, filtros, setFiltros)}
                {guias && <>
                {TabelaGuias(guias, ordenacao, ordem, setOrdenacao, setOrdem, aviso, setAviso)}
                <RodapePaginas>
                    <TotalDocs>
                        <p>Total: {total.docs}</p>
                    </TotalDocs>
                    <PaginasTabela>
                        <p onClick={() => setPagina(1)}>Primeira</p>
                        <p onClick={() => {
                            if (pagina > 1) setPagina(pagina-1);
                            }
                        }>Anterior</p>
                        {paginas()}
                        <p onClick={() => {
                            if (pagina < total.paginas) setPagina(pagina+1);
                        }}>Próxima</p>
                        <p onClick={() => setPagina(total.paginas)}>Última</p>
                    </PaginasTabela>
                </RodapePaginas>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    );
    function paginas() {
        let pages = [];
        let ajuste = 2;
    
        if (pagina === 1){
            ajuste = 0;
        } else if (pagina === 2){
            ajuste = 1;
        } else if (pagina === total.paginas-1 && total.paginas > 4){
            ajuste = 3;
        } else if (pagina === total.paginas && total.paginas > 4){
            ajuste = 4;
        }
        if (total.paginas < 5){
            for (let i = 0; i < total.paginas; i++){
                let pageNum = parseInt(i+1);
    
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
                let pageNum = parseInt(pagina) + parseInt(i) - ajuste;
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}