import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    place-items: center;
`
export const Card = styled.div`
    background-color: #ffffff7f;
    padding: 30px;
    border-radius: 4%;
    width: 250px;
    box-shadow: 2px 3px 1px 1px #00000070;
    
    button {
        &:hover{
            background-color: var(--corNugaliEscura);
            color: var(--corNugaliClaraEscrita);
        }
    }
`
export const LabelFloat = styled.div`
    position: relative;
    padding-top: 13px;
    margin-top: 10%;
    margin-bottom: 5%;

    input {
        width: 100%;
        padding: 5px;
        display: inline-block;
        border: 0;
        border-bottom: 2px solid rgba(58, 23, 14, 0.562);
        color: var(--corNugaliEscura);
        background-color: transparent;
        outline: none;
        min-width: 180px;
        font-size: 16px;
        transition: all .2s ease-out;
        border-radius: 0;

        &:focus {
            border-bottom: 2px solid;
        }

        &:-webkit-autofill {
            -webkit-background-clip: text;
            box-shadow: inset 0 0 20px 20px transparent;
            -webkit-text-fill-color: #3a170e;
        }
    }

    label {
        color: var(--corNugaliEscura);
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -5px;
        transition: all .2s ease-out;
    }
`


// export default { Botao, Titulo1 };