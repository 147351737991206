import styled from "styled-components";

const Botao = styled.button`
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    border: 1px solid;
    border-color: var(--corNugaliEscura);
    display: ${props => props.$esconder? "none": "block"};
    background-color: ${props => props.$corFundo || "transparent"};
    color: ${props => props.$cor || "#3a170e"};
    padding: ${props => props.$pad || "5px"};
    font-weight: bold;
    font-size: ${props => props.$tamanho || "18px"};
    margin-top: ${props => props.$marginTop || "0"};
    margin-right: ${props => props.$marginRight || "0"};
    border-radius: 7px;
    outline: none;
    box-shadow: none;
    transition: border-color 0.25s;
    cursor: pointer;
    height: 40px; /* set your desired height */

    &:hover{
        box-shadow: 2px 1px 5px 1px lightgray;
    }
    &:disabled{
        color: lightgray;
        border-color: lightgray;
        cursor: ${props => props.$cursorDesabilitado || "not-allowed"};
        box-shadow: none;
    }
`
export default Botao;