import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaTicketAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";
import { GrSchedule } from "react-icons/gr";
import { FaUserCog } from "react-icons/fa";
import { FaPersonHiking } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { MdHistory } from "react-icons/md";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaChartBar } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { MdOutlineCancelPresentation } from "react-icons/md";

const BarraLateral = styled.div`
    flex: 0 0 200px;
    /* width: 250px; */
    background-color: #f3f4f7;
    color: #090538;
    border-right: 1px solid #aaaaaa;
    align-items: center;    
`
const ItemMenu = styled.p`
    cursor: pointer;
    color: #090538;
    margin: ${props => props.$margem || "10px auto 10px 30px"};
    font-size: ${props => props.$fonte || "16px"};
    display: flex;
    align-items: center;

    .Icone {
        margin: 0 8px 0 0;
    }
`
const SubMenu = styled.div`
    display: ${props => (props.isVisible ? "block" : "none")};
    margin-left: 10px; /* Adjust as needed for indentation */
`
export default function MenuLateral(){
    const [showIngressosSubmenu, setShowIngressosSubmenu] = useState(false);

    const toggleIngressosSubmenu = () => {
        setShowIngressosSubmenu(!showIngressosSubmenu);
    }

    return (
        <BarraLateral><nav>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin"><ItemMenu  $margem="30px auto 40px 30px" $fonte="20px"><IoMdHome className="Icone"/>Início</ItemMenu></Link>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/visitantes"><ItemMenu><FaUserAlt className="Icone"/>Visitantes</ItemMenu></Link>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/sessoes"><ItemMenu><GrSchedule className="Icone"/>Sessões</ItemMenu></Link>

            <ItemMenu onClick={toggleIngressosSubmenu}>
                {(showIngressosSubmenu && <FaCaretDown className="Icone"/>) || <FaCaretRight className="Icone"/>}
                Ingressos
            </ItemMenu>
            <SubMenu isVisible={showIngressosSubmenu}>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/ingressos?pagina=1&ordenacao=numero&ordem=-1"><ItemMenu><FaTicketAlt className="Icone"/>Listagem</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/ingressos/cancelados"><ItemMenu><MdOutlineCancelPresentation className="Icone"/>Cancelados</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/ingressos/cancelar"><ItemMenu><MdCancel className="Icone"/>Cancelar</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/criar-tickets"><ItemMenu><AiOutlineFileAdd className="Icone"/>Criar</ItemMenu></Link>
            </SubMenu>

            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/guias"><ItemMenu $margem="40px auto 40px 30px"><FaPersonHiking className="Icone"/>Guias</ItemMenu></Link>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/comissoes"><ItemMenu><GiReceiveMoney className="Icone"/>Comissões</ItemMenu></Link>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/comissoes/historico"><ItemMenu $margem="10px auto 40px 30px"><MdHistory className="Icone"/>Histórico</ItemMenu></Link>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/indicadores"><ItemMenu $margem="10px auto 40px 30px"><FaChartBar className="Icone"/> Indicadores</ItemMenu></Link>
            <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/usuarios"><ItemMenu><FaUserCog className="Icone"/>Usuários</ItemMenu></Link>
        </nav></BarraLateral>
    )
}