import styled from "styled-components";
import { memo } from "react";

export const SecaoMural = styled.section`
    width: 100vw;
    height: 100vh;
    z-index: 10;
    position: relative;
    overflow: hidden;
`
const styledAreaFotos = styled.img`
    position: absolute;
    top: ${props => props.$topo || "0"};
    left: ${props => props.$esquerda || "0"};
    width: ${props => props.$largura || "27.5%"};
    height: ${props => props.$altura || "27.6%"};
    background-color: transparent;
    padding: 0;
    margin: 0;
    transform: ${props => `rotate(${props.$roda}deg)` || "none"};
    z-index: ${props => props.$z || "1"};
    overflow: hidden;
`
export const AreaFotos = memo(styledAreaFotos);
export const Piscar = styled.div`
    position: absolute;
    overflow: hidden;
    background-color: white;
    padding: 0;
    margin: 0;
    z-index: ${props => props.$z || "1"};
    opacity: ${props => props.$pisca ? 1 : 0};
    transition: opacity 0.5s ease-in-out;
    top: ${props => props.$topo || "0"};
    left: ${props => props.$esquerda || "0"};
    height: ${props => props.$altura || "27.6%"};
    width: ${props => props.$largura || "27.5%"};
    transform: ${props => `rotate(${props.$roda}deg)` || "none"};
`