import styled from "styled-components";
import Botao from "./Botao";

const ContainerModal = styled.div`
    display: ${props => props.$mostrar? "block" : "none"};
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 3000px;
    background-color: #000000ab;
    z-index: 99;
`
const DivModal = styled.div`
    display: "block";
    height: ${props => props.$altura || "300px"};
    width: ${props => props.$largura || "400px"};
    margin-left: ${props => props.$largura? -Math.floor(Number(props.$largura.slice(0, -2)))/2+"px" : "-200px"};
    background-color: white;
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    overflow: auto;
    border-radius: 5px;
    outline: 1px solid black;

    p {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        margin-left: 10px;
    }
`
const DivBotoes = styled.div`
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin: 0;
    align-items: center;
    button {
        margin-right: 10px;
        margin-top: 10px;
    }
`
export default function ModalPergunta(texto, handleClicouSim, handleClicouNao, show, altura, largura){

    return (
        <ContainerModal $mostrar={show}>
            <DivModal $altura={altura} $largura={largura}>
                <p>{texto}</p>
                <DivBotoes>
                    <Botao onClick={handleClicouSim}>Sim</Botao>
                    <Botao onClick={handleClicouNao}>Não</Botao>
                </DivBotoes>
            </DivModal>
        </ContainerModal>
    )
}