import { Link } from "react-router-dom";
import styled from "styled-components";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { BotaoAcoes, BotaoExcluir } from "../AdminStyled";
import { deletarTicket } from "../../../servicos/ticketsServices";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
`
export function TabelaIngressos(ingressos, ordenacaoAtual, ordemAtual, atualizaParametro, showAviso, setShowAviso, selecionados, setSelecionados) {
    const campos = [
        {chave: "numero", label: "Número"},
        {chave: "sessao", label: "Sessão"},
        {chave: "visitante", label: "Visitante"},
        {chave: "tipo", label: "Tipo"},
        {chave: "motivoCortesia", label: "Mot. Cortesia"},
        {chave: "guia", label: "Guia"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            atualizaParametro("ordem", ordemAtual === "1"? "-1": "1");
        } else {
            atualizaParametro("ordenacao", ordenacao);
        }
    }

    const handleSim = async () => {
        await deletarTicket(showAviso.ingressoDeletar);
        setShowAviso({ mostrar: false, ingressoDeletar: null });
    };
    const handleNao = () => {
        setShowAviso({ mostrar: false, ingressoDeletar: null });
    };

    function atualizaSelecionados(id) {
        if (selecionados.includes(id)) {
            setSelecionados(selecionados.filter((item) => item !== id));
        } else {
            setSelecionados([...selecionados, id]);
        }
    }

    function selecionaTudo() {
        if (selecionados.length === ingressos.length) {
            setSelecionados([]);
        } else {
            setSelecionados(ingressos.map( ticket => ticket._id ));
        }
    }
    
    try {
        return (<>
            {ModalPergunta("Tem certeza que deseja deletar este ingresso?", handleSim, handleNao, showAviso.mostrar, "100px")}
            <Tabela>
                <thead>
                    <LinhaTabela>
                        <TituloColunas><input type="checkbox" checked={selecionados.length === ingressos.length} onChange={() => selecionaTudo()}/></TituloColunas>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === "-1" && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === "1" && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                        <th style={{color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>
                    {ingressos.map((ingresso) => {
                        return (<LinhaTabela key={ingresso._id}> 
                            <ColunaTabela>
                                <input 
                                    type="checkbox" 
                                    checked={selecionados.includes(ingresso._id)} 
                                    onChange={ () => atualizaSelecionados(ingresso._id)}
                                />
                            </ColunaTabela>
                            <ColunaTabela>{ingresso.numero || "-"}</ColunaTabela>
                            <ColunaTabela style={{cursor: "pointer" }}><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${ingresso.sessao._id}`}>{new Date(ingresso.sessao.diaHora).toLocaleDateString('en-GB')} {ingresso.sessao.diaHora.split("T")[1].slice(0,5) || "-"}</Link></ColunaTabela>
                            <ColunaTabela style={{cursor: "pointer"}}><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/visitantes/${ingresso.visitante._id}`}>{ingresso.visitante.nome.split(" ")[0] || ingresso.visitante.email || ingresso.visitante.celular || "-"}</Link></ColunaTabela>
                            <ColunaTabela>{ingresso.tipo || "-"}</ColunaTabela>
                            <ColunaTabela>{ingresso.motivoCortesia || "-"}</ColunaTabela>
                            <ColunaTabela>{ingresso.guia? (<Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/guias/${ingresso.guia._id}`}>{ingresso.guia.nome.split(" ")[0]}</Link>): "-"}</ColunaTabela>
                            <ColunaTabela>
                                <BotaoAcoes><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/ingressos/${ingresso._id}`}>Abrir</Link></BotaoAcoes>
                                <BotaoExcluir onClick={() => setShowAviso({ mostrar: true, ingressoDeletar: ingresso.numero })}>Excluir</BotaoExcluir>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
        </>);
    } catch (e) {
        return <></>
    }
}