import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import Titulo1 from "../../../componentes/Titulo1";
import Botao from "../../../componentes/Botao";
import ModalPergunta from "../../../componentes/ModalPergunta";
import ModalObservacao from "../../../componentes/ModalObservacao";
import { AreaSuperior, Inline, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { deletarTickets, obterTicketsSessao } from "../../../servicos/ticketsServices";
import { obterSessaoPorId, zerarLugaresSessao } from "../../../servicos/sessoesServices";
import { InfosSessao, VisualizaSessao, VisualizaTickets } from "./SessoesStyled";
import { TabelaTicketsSessao } from "./TabelaTicketsSessao";

export default function SessaoId(){
    const [tickets, setTickets] = useState([]);
    const [sessao, setSessao] = useState(null);
    const [showAviso, setShowAviso] = useState({
        mostrar: false,
        ticket: null
    });
    const [showAviso2, setShowAviso2] = useState(false);
    const [selecionados, setSelecionados] = useState([]);
    const [showObs, setShowObs] = useState(false);
    const [justificativa, setJustificativa] = useState("");
    const { id } = useParams();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (!showAviso.mostrar) {
            obterTickets();
            obterSessao();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAviso]);

    useEffect(() => {
        if (!showAviso2) {
            obterTickets();
            obterSessao();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAviso2]);

    async function obterTickets() {
        const resposta = await obterTicketsSessao(id);
        if (resposta.tickets) setTickets(resposta.tickets);
        setCarregando(false);
    }
    
    async function obterSessao() {
        const resposta = await obterSessaoPorId(id);
        if (resposta.sessao) {
            setSessao(resposta.sessao);
            if (resposta.sessao.observacao) setJustificativa(resposta.sessao.observacao);
        }
    }

    async function zerarLugares() {
        const resposta = await zerarLugaresSessao(id, justificativa);
        if (resposta.status === 200) obterSessao();
    }

    /*async function recalcularLugares() {
        const resposta = await recalcularLugaresSessao(id);
        if (resposta.status === 200) obterSessao();
    }*/

    async function reimprimirIngressos() {
        if (selecionados.length === 0) return;
        const ticketsReimprimir = tickets.filter( ticket => selecionados.includes(ticket._id) );

        let tesoura = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABuwAAAbsBOuzj4gAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATPSURBVHic7dtbiFVVGAfw3zqZF8xQSJNCwR7UwBTKIoSCtAsGRT6FSQ+9CGFQYlZPBRH0IN2gHoIgiEykIMKokGi6kERjkTes7ALmQFmWkuVoOquHvaeOx73P7LPPdsZx+4fvYdas9X3f/ztrrW/ddogxqjMaI+3ASKP2ARgzVIUQQgMLMRbHU/kHO2OMJ06ve8OAGGOm4DI8jr2IGfIj1mByno7RIFnEp+AdDOQQb5U/8TwuHGkyZSQ0Z4EQwjRsxoISnWkLbokxHi7RdsTw3yQYQrgUHytHHhZhUwhhQhWODRdCjFHq9C7MqkDnZiyNMQ5UoOu0YzAL3C2f/K94Ab3Yg8txLe7DpIz6N+NWvF2ppx0ghDAdyzBB0ssb+BwfxdaVX/r3LtkT3JuYmpMlZuKDnHbvjciMzjV4Fcdy/NqDR3BRUxs35VTuxZghDE7EdxltBzB7GIlPwcYcHlnyA2YNBuCNjAonMK+g8cU5Rp4cJvI3Yl8H5AdlH+bCJxn/3N2hE79n6Hj5NBMfj2cUX69kycYGznMqtmaUtcMXGWXjO9RRGCGEBZIh+gBCCRXHsBbLx8jeD3S6xs+qP65Tr4ZCui9Zgycke5My2IkVMcbtgwVfOrVr7OqwO/6WoeOtirv8TPRk2CkqA3ga41r0eimj8gnMKejYdTkG76+Q/Aoc7IL8T1iSo9uinEafojGEY+OwOyeA0ysgPhkbuiAe0/ZTcm2khr7OafyKnO0upmNTTrv3KyC/OP3lyhI/KBnr7e2kxh5qo6gPD6cOzZAsdR/FgTZt1nZBfBye0l1668HMQvZSoxfgsy67WrP0S2br0CH5K7C9C7tH8aAhhu4pAUiNT8FXFQYhSvYKMwoQD2nA+ruwtQPzO+5xLY5Mkz2pdSN/4K425GfI31QVkcz0VioAqUOXSDYW3YzBLHlNy4SK5WmAyurMTW+lA9Dk3Hy8jkM5xvdJ1uLL8X0HDi+WpLf1XQa0bXorKiedCWYhXX7OwZWS/H4Av2BHHBzAIUzCc7inrbIEUbJynFqgbhYOYVWMcX3J9i3edBnBll6zTHKCVOXQaZYeBdNb10OgiyBMx7sVE+84vY1YAJoCsQp/V0C+VHob8QCkQZgrOVsoQ7yr9HZGBCANwvmS/fvxDsh3nd6KSu1vh0/3rz9HcnRVvyGAe/FXSfKjdxLExZJbofqlQdyO/RWTb5YeFS+EqloKT0zH68q2yhJEo2EpbOjNUB+exZ34NqdOVno7ozZDWcTrux1W5wMRdT4SU/dDUeeOxWt8MaLuV2POXY7W+3q8ITmoaEVvRlk7ZL0oqfQhdYxxL5ZIJuxjJVQErMbWEML8wcK8AGQ9m2mHrPpHO9QxJGKMAzHGdZLncDtLqpmH3hDCgyGERkP2L7WwQ6VXZZT1d+xaQcQYt+FqyV4kllAxFuuwoSHZ2bVidghhXhFNIYTFklVkK34u4VhhxBj7Y4yrJeuSvhIq+vAYdX8omSqo7VPZwdfiK/FiTlfp9LE03BZjHBWPpWv/XL4BMcYjkhXdN13q+xB3jBbyNH0xEmPsw/XYVlLXFknXP1KFY8OFk26GYoz7cYP/b3eL4LBkjlgaR9n3QtBuRq3FZ3NFj8XP2g8nhwzA2Y7a3w7XPgD/ArwuhcM+YPXAAAAAAElFTkSuQmCC";
        let yMax;
        const doc = new jsPDF();
        doc.setFont(undefined, "bold");
        doc.setFontSize(15);
        doc.text("Estes são seus ingressos para o Tour Nugali Chocolates!", 105, 13, "center");
        doc.setFontSize(20).text(`Dia ${sessao.diaHora.slice(8,10)}/${sessao.diaHora.slice(5,7)}/${sessao.diaHora.slice(0,4)} às ${sessao.diaHora.slice(11,16)}`, 105, 23, "center");
        doc.setFontSize(12).text("Recorte os ingressos e distribua para os usuários.", 105, 30, "center");
        doc.text("Aproxime o Código QR nos leitores das catracas no horário indicado.", 105, 37, "center");
        doc.text("O não comparecimento na data e horário agendados resultará na perda destes ingressos.", 105, 44, "center");

        // Adição dos ingressos ao documento
        for (let i = 0; i < ticketsReimprimir.length; i ++) {
            let qrcode = await QRCode.toDataURL(ticketsReimprimir[i].numero.toString());
            let x = 15 + 50*(i%4);
            let y = 52 + 60*Math.floor(i/4);
            let tipo = ticketsReimprimir[i].tipo.split("-")[0];
            let ajuste;

            switch (tipo.length) {
                case "PROMOCIONAL":
                    ajuste = 14;
                    break;
                case "INFANTIL":
                    ajuste = 18;
                    break;
                default:
                    ajuste = 16;
                    break;
            }

            doc.addImage(qrcode, 'png', x, y, 32, 32);     // x, y, largura, altura
            doc.setFontSize(12).text(
                tipo, 
                x + ajuste, 
                y + 33, 
                "center"
            );
            doc.text(
                `${sessao.diaHora.slice(8,10)}/${sessao.diaHora.slice(5,7)}/${sessao.diaHora.slice(0,4)}  ${sessao.diaHora.slice(11,16)}`, 
                x + 15, 
                y + 48, 
                "center"
            );
            doc.setFontSize(8).text("ACESSO ÚNICO E INDIVIDUAL", x + 16, y + 40, "center");
        }

        doc.setLineDash([4, 2], 0);  // Create dashed line [dash length, gap length]

        // Linhas horizontais
        doc.line(2, 50, 210, 50);
        doc.addImage(tesoura, "png", 10, 47, 6, 6);

        for (let i = 0; i < Math.ceil(ticketsReimprimir.length/4); i++) {
            let y = 110 + 60 * i; 
            yMax = y;
            doc.line(2, y, 210, y);   // Draw the line under the ticket (adjust coordinates as needed)
        
            doc.addImage(tesoura, "png", 10, y-3, 6, 6);
        }

        // Linhas verticais
        for (let i = 0; i < 3; i++) {
            doc.line(50*i + 56, 50, 50*i + 56, yMax);
        }

        let iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = "none";
        iframe.src = doc.output("bloburl");
        iframe.onload = () => {
            iframe.contentWindow.print();
        };
    }

    const handleConfirma = () => {
        if (justificativa) {
            zerarLugares();
            setShowObs(false);
        }
    }

    const handleCancela = () => {
        setShowObs(false);
    }

    const handleSim = async () => {
        setCarregando(true);
        const paraDeletar = tickets.filter( ticket => selecionados.includes(ticket._id) );
        await deletarTickets(paraDeletar.map(ticket => ticket.numero));
        setSelecionados([]);
        setShowAviso2(false);
    };
    const handleNao = async () => {
        setShowAviso2(false);
    };

    return (
        <SecaoAdmin>
            {ModalPergunta(`Tem certeza que deseja deletar estes ${selecionados.length} ingressos?`, handleSim, handleNao, showAviso2, "100px")}
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Visualizar sessão")}
                { sessao && 
                <VisualizaSessao>
                    <InfosSessao $margem="15px 4px 15px 0" $cor="#252525">Data:</InfosSessao> 
                    <InfosSessao $bold="bold">{new Date(sessao.diaHora).toLocaleDateString('en-GB')}</InfosSessao>
                    <InfosSessao $margem="15px 4px 15px 0" $cor="#252525">Horário: </InfosSessao>
                    <InfosSessao $bold="bold">{sessao.diaHora.split("T")[1].slice(0,5)}</InfosSessao>
                    <InfosSessao $margem="15px 4px 15px 0" $cor="#252525">Lugares disponíveis: </InfosSessao>
                    <InfosSessao $bold="bold">{sessao.ticketsDisponiveis}</InfosSessao>
                </VisualizaSessao>}
                { tickets && 
                <VisualizaTickets>
                    <AreaSuperior>
                        <Titulo1 $tamanho="1.5em">Ingressos da sessão</Titulo1>
                        <Inline>
                            <Botao disabled={carregando} $marginRight="10px" onClick={() => reimprimirIngressos()}>Reimprimir</Botao>
                            <Botao disabled={carregando} $marginRight="10px" onClick={() => selecionados.length > 0? setShowAviso2(true): ""}>Excluir</Botao>
                            <Botao disabled={carregando} $marginRight="20px" onClick={() => setShowObs(true)}>Zerar lugares</Botao>
                        </Inline>
                    </AreaSuperior>
                    {TabelaTicketsSessao(tickets, sessao, showAviso, setShowAviso, selecionados, setSelecionados)}
                    {ModalObservacao("Informe uma justificativa", showObs, handleConfirma, handleCancela, "130px", "500px", justificativa, setJustificativa)}
                </VisualizaTickets>}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}