import styled from "styled-components";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow-x: auto;
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: ${props => props.$tamanho || "40px"};
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    color: ${props => props.$cor || "#252525"};
    font-size: ${props => props.$tamFonte || "inherit"};
    font-weight: ${props => props.$bold || "normal"};

    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
    }
`

export function TabelaPorGuia(dados) {
    try {
        return (
            <Tabela>
                <LinhaTabela>
                    <ColunaTabela $bold="bold">Guia</ColunaTabela>
                    <ColunaTabela $bold="bold">Total</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais Online</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais Excursão</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais Escolar</ColunaTabela>
                </LinhaTabela>
                {dados.guias.map((guia, indice) => {
                    return <LinhaTabela>
                        <ColunaTabela>{guia}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionais + dados.quantidade[indice].promocionaisOnline + dados.quantidade[indice].promocionaisExcursao}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionais}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionaisOnline}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionaisExcursao}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionaisEscolar}</ColunaTabela>
                    </LinhaTabela>
                })}
            </Tabela>
        );
    } catch (e) {
        console.log(e.message);
        return <></>
    }
}