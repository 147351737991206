import { useEffect, useState } from "react";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { AreaSuperior, LinksPaginas, PaginasTabela, RodapePaginas, SecaoAdmin, SecaoPrincipal, TotalDocs } from "../AdminStyled";
import { listarVisitantes } from "../../../servicos/visitantesServices";
import { TabelaVisitantes } from "./TabelaVisitantes";
import Pesquisa from "../../../componentes/Pesquisa";
import ErroSpan from "../../../componentes/ErroSpan";

export default function Visitantes() {
    const [visitantes, setVisitantes] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [ordem, setOrdem] = useState(1);
    const [ordenacao, setOrdenacao] = useState("nome");
    const [total, setTotal] = useState({ paginas: 1, docs: 0 });
    const [busca, setBusca] = useState("");
    const [showAviso, setShowAviso] = useState({
        mostrar1: false,
        mostrar2: false,
        visitanteDeletar: null,
        deletarTickets: false
    });

    useEffect(() => {
        if (!showAviso.mostrar1 && !showAviso.mostrar2) obterVisitantes(busca, pagina, ordem, ordenacao);
    }, [showAviso, busca, pagina, ordem, ordenacao]);

    async function obterVisitantes(busca, paginaAtual, ordemAtual, ordenacaoAtual){
        const resposta = await listarVisitantes(busca, paginaAtual, ordemAtual, ordenacaoAtual);
        if (resposta.dados) {
            setVisitantes(resposta.dados);
            setTotal({ paginas: Math.ceil(resposta.totalDocs/20), docs: resposta.totalDocs });
        }
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Visitantes")}
                <AreaSuperior>
                    <RodapePaginas />
                    {Pesquisa(setBusca, setPagina, busca)}
                </AreaSuperior>
                {((!visitantes || visitantes.length === 0) && <ErroSpan $corFundo="transparent" $corTexto="#252525">Carregando...</ErroSpan>) || <>
                    {TabelaVisitantes(visitantes, ordenacao, ordem, setOrdenacao, setOrdem, showAviso, setShowAviso)}
                    <RodapePaginas>
                        <TotalDocs>
                            <p>Total: {total.docs}</p>
                        </TotalDocs>
                        <PaginasTabela>
                            <p onClick={() => setPagina(1)}>Primeira</p>
                            <p onClick={() => {
                                if (pagina > 1) setPagina(pagina-1);
                                }
                            }>Anterior</p>
                            {paginas()}
                            <p onClick={() => {
                                if (pagina < total.paginas) setPagina(pagina+1);
                            }}>Próxima</p>
                            <p onClick={() => setPagina(total.paginas)}>Última</p>
                        </PaginasTabela>
                    </RodapePaginas>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    );

    function paginas() {
        let pages = [];
        let ajuste = 2;
        if (pagina === 1){
            ajuste = 0;
        } else if (pagina === 2){
            ajuste = 1;
        } else if (pagina === total.paginas-1 && total.paginas > 4){
            ajuste = 3;
        } else if (pagina === total.paginas && total.paginas > 4){
            ajuste = 4;
        }
        if (total.paginas < 5){
            for (let i = 0; i < total.paginas; i++){
                let pageNum = parseInt(i+1);
    
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
                let pageNum = parseInt(pagina) + parseInt(i) - ajuste;
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}