import styled from "styled-components";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
    margin-bottom: 20px;
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    border-top: ${props => props.$linha || "none"};
    height: 30px;
    td {
        padding-top: ${props => props.$espaco || "none"};
        padding-bottom: ${props => props.$espaco || "none"};
    }
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    color: ${props => props.$cor || "inherit"};
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
export function TabelaHistGuia(comissoes, ordenacaoAtual, setOrdenacaoAtual, ordemAtual, setOrdemAtual) {
    let total = 0;

    const campos = [
        {chave: "dataPagamento", label: "Data Pagamento"},
        {chave: "comissaoTotal", label: "Valor"},
        {chave: "pagamentoEfetivado", label: "Efetivado"},
        {chave: "mensagem", label: "Mensagem"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }

    if (comissoes) {
        return (
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                                
                            </TituloColunas>
                            
                        })}
                    </LinhaTabela>
                </thead>
                <tbody>
                    {comissoes.map((comissao, index) => {
                        total += comissao.comissaoTotal;
                        return (
                        <LinhaTabela key={index}> 
                            <ColunaTabela>{comissao.dataPagamento.slice(8, 10)}/{comissao.dataPagamento.slice(5, 7)}/{comissao.dataPagamento.slice(0, 4)}</ColunaTabela>
                            <ColunaTabela>R$ {comissao.comissaoTotal.toFixed(2).replace(".", ",")}</ColunaTabela>
                            <ColunaTabela>{comissao.pagamentoEfetivado? "Sim" : "Não"}</ColunaTabela>
                            <ColunaTabela>{comissao.mensagem}</ColunaTabela>
                        </LinhaTabela>
                    )})}
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525">Total</ColunaTabela>
                        <ColunaTabela $cor="#252525">R$ {total.toFixed(2).replace(".", ",")}</ColunaTabela>
                    </LinhaTabela>
                </tbody>
            </Tabela>
        )
    } else {
        return <></>
    }
}