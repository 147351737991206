import styled from "styled-components";
import Botao from "./Botao";

const ContainerModal = styled.div`
    display: ${props => props.$mostrar? "block" : "none"};
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 3000px;
    background-color: #000000ab;
    z-index: 99;
`
const DivModal = styled.div`
    display: "block";
    font-size: ${props => props.$tamFonte || "30px"};
    height: ${props => props.$altura || "300px"};
    width: ${props => props.$largura || "400px"};
    margin-left: ${props => props.$largura? -Math.floor(Number(props.$largura.slice(0, -2)))/2+"px" : "-200px"};
    background-color: white;
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    overflow: auto;
    border-radius: 5px;
    outline: 1px solid black;

    p {
        margin: 0;
        padding: 0;
        margin-top: 5px;
    }
    button {
        background-color: var(--corNugaliEscura);
        color: var(--corNugaliClara);
        padding: 5px 10px;
        margin: 10px auto;
    }
`
export default function ModalAviso(texto, show, fechar, altura, largura){
    return (
        <ContainerModal $mostrar={show}>
            <DivModal $altura={altura} $largura={largura}>
                <p>{texto}</p>
                <Botao onClick={fechar}>OK</Botao>
            </DivModal>
        </ContainerModal>
    )
}