import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useUsuarioContext } from "../../contexto/SessaoUsuario";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidaLogin } from "../../servicos/usuariosServices";
import { LoginContainer, Card, LabelFloat } from "./LoginStyled";
import loginSchema from "../../schemas/loginSchema";
import Titulo1 from "../../componentes/Titulo1";
import Botao from "../../componentes/Botao";
import ErroSpan from "../../componentes/ErroSpan";

// eslint-disable-next-line react/prop-types
export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const { setUsuario } = useUsuarioContext();

    const {
        register: registerLogin,
        handleSubmit: handleSubmitLogin,
        formState: { errors: errorLogin }
    } = useForm({resolver: zodResolver(loginSchema)});
    
    const [erroLogin, setErroLogin] = useState({erro: false, mensagem: ""});

    async function loginHandleSubmit(data){
        try {
            const resposta = await ValidaLogin(data.email, data.password);
            if (resposta.status === 200){
                setUsuario(resposta.usuario);
                setErroLogin({ erro: false, mensagem: "" });
                const caminhoRedirecionar = location.state?.redirecionar && location.state.redirecionar !== "/login"? location.state.redirecionar : "/";
                navigate(caminhoRedirecionar);
            } else {
                setErroLogin({ erro: true, mensagem: "E-mail ou senha incorretos" });
            }
        } catch (e) {
            setErroLogin({ erro: true, mensagem: "Erro ao efetuar login" });
            console.log(e);
            console.log(e.message);
        }
    }

    return (
        <LoginContainer>
            <Card>
                <Titulo1 $alinhamento="center" $corFundo="transparent" $cor="var(--corNugaliEscura)"> Login </Titulo1>
                <form onSubmit={handleSubmitLogin(loginHandleSubmit)}>
                    <LabelFloat>
                        <input 
                            type="email" 
                            {...registerLogin("email", {required: "Campo obrigatório"})}
                        />
                        <label htmlFor="email">E-mail</label>
                    </LabelFloat>
                    {errorLogin.email && <ErroSpan>{errorLogin.email.message}</ErroSpan>}
                    <LabelFloat>
                        <input 
                            type="password" 
                            id="senha"
                            {...registerLogin("password", {required: "Campo obrigatório"})}
                        />
                        <label htmlFor="senha">Senha</label>
                    </LabelFloat>
                    {errorLogin.password && <ErroSpan>{errorLogin.password.message}</ErroSpan>}
                    {erroLogin.erro && <ErroSpan>{erroLogin.mensagem}</ErroSpan>}
                    <div className="justifica-centro">
                        <Botao type="submit">Entrar</Botao>
                    </div>
                </form>
            </Card>
        </LoginContainer>
    )
}

