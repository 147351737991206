import styled from "styled-components";

export const AreaVisitante = styled.div`
    padding-left: 30px;
    border-bottom: 1px solid #aaaaaa;
`
export const LinhaInfos = styled.div`
    display: flex;
`
export const InfosVisitante = styled.p`
    margin: ${props => props.$margem || "15px 30px 15px 0"};
    color: ${props => props.$cor || "var(--corNugaliEscura)"};
    font-size: ${props => props.$fonte || "20px"};
    font-weight: ${props => props.$bold || "normal"};
`