import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import { InputStyled } from "../GlobalStyled";

const SearchBox = styled.div`
    display: inline-flex;
    border: 1px solid var(--corEscrita);
    border-radius: 5px;
    margin: 10px 20px;
    height: 40px;
    width: 300px;

    input {
        /* border: none; */
        margin: 5px;
        padding: 0;
        width: 100%;
        border: none;
    }

    .Icone {
        position: relative;
        top: 9px;
        left: 3px;
        margin: 0 5px;
    }
`

export default function Pesquisa(setBusca, setPaginaAtual = undefined, busca = null){
    return (
        <SearchBox>
            <AiOutlineSearch className="Icone" size={'1.5em'}/>
            <InputStyled 
                type="search"
                onInput={ (evento) => {
                    setBusca(evento.target.value);
                    if (setPaginaAtual) setPaginaAtual(1);
                } }
                value={(!busca)? "": busca}
            />
        </SearchBox>
    );
}