import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import ErroSpan from "../../../componentes/ErroSpan";
import Pesquisa from "../../../componentes/Pesquisa";
import { AreaSuperior, FiltrarData, InputMes, LinksPaginas, OpcoesFiltragem, PaginasTabela, RodapePaginas, SecaoAdmin, SecaoPrincipal, TotalDocs } from "../AdminStyled";
import { TabelaIngressos } from "./TabelaIngressos";
import { deletarTickets, listarTickets } from "../../../servicos/ticketsServices";
import Botao from "../../../componentes/Botao";
import ModalPergunta from "../../../componentes/ModalPergunta";

export default function Ingressos() {
    const [params, setParams] = useSearchParams();
    const [ingressos, setIngressos] = useState([]);
    const [total, setTotal] = useState({ paginas: 1, docs: 0 });
    const [carregando, setCarregando] = useState(true);
    const [busca, setBusca] = useState("");
    const [selecionados, setSelecionados] = useState([]);
    const [showAviso2, setShowAviso2] = useState(false);
    const [showAviso, setShowAviso] = useState({
        mostrar: false,
        ingressoDeletar: null
    });
    
    useEffect(() => {
        if (!showAviso2) obterIngressos(busca, params.get("dataInicial"), params.get("dataFinal"), JSON.parse(params.get("tipo")), params.get("pagina"), params.get("ordenacao"), params.get("ordem"));
    }, [busca, params, showAviso, showAviso2]);
    
    const opcoesTipos = [
        { value: "promocional", label: "PROMOCIONAL" },
        { value: "cortesia", label: "CORTESIA" },
        { value: "infantil", label: "INFANTIL" },
        { value: "promocionalOnline", label: "PROMOCIONAL-ONLINE" },
        { value: "infantilOnline", label: "INFANTIL-ONLINE" },
        { value: "promocionalExcursao", label: "PROMOCIONAL-EXCURSÃO" }
    ];
    async function obterIngressos(busca, dataI, dataF, tipo, pagina, ordenacao, ordem){
        // setCarregando(true);
        const resposta = await listarTickets(busca, {dataInicial: dataI, dataFinal: dataF, tipo: tipo}, pagina, ordenacao, ordem);
        if (resposta.dados) {
            setIngressos(resposta.dados);
            setTotal({ paginas: Math.ceil(resposta.totalDocs/20), docs: resposta.totalDocs });
            setCarregando(false);
        }
    }

    function atualizarParametro(parametro, valor) {
        setParams(params => {
            params.set(parametro, valor);
            return params;
        });
    }

    const handleSim = async () => {
        setCarregando(true);
        const paraDeletar = ingressos.filter( ticket => selecionados.includes(ticket._id) );
        await deletarTickets(paraDeletar.map(ticket => ticket.numero));
        setSelecionados([]);
        setShowAviso2(false);
    };
    const handleNao = async () => {
        setShowAviso2(false);
    };

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {ModalPergunta(`Tem certeza que deseja deletar estes ${selecionados.length} ingressos?`, handleSim, handleNao, showAviso2, "100px")}
                {CabecalhoAdmin("Ingressos")}
                <AreaSuperior>
                    <OpcoesFiltragem>
                        <FiltrarData $marginTop="1px">
                            <label>Data inicial</label>
                            <InputMes type="date" defaultValue={params.get("dataInicial")} onChange={event => {
                                atualizarParametro("dataInicial", event.target.value);
                                atualizarParametro("pagina", 1);
                            }}/>
                        </FiltrarData>
                        <FiltrarData $marginTop="1px">
                            <label>Data final</label>
                            <InputMes type="date" defaultValue={params.get("dataFinal")} onChange={event => {
                                atualizarParametro("dataFinal", event.target.value);
                                atualizarParametro("pagina", 1);
                            }}/>
                        </FiltrarData>
                        <FiltrarData>
                            <label>Tipo</label>
                            <Select 
                                options={opcoesTipos} 
                                isMulti
                                isClearable="false"
                                placeholder="Tipo"
                                value={JSON.parse(params.get("tipo"))}
                                onChange={e => {
                                    atualizarParametro("tipo", JSON.stringify(e));
                                    atualizarParametro("pagina", 1);
                                }}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: "2px",
                                        border: "1px solid #252525",
                                        width: "200px",
                                        height: "31px",
                                        minHeight: '30px',
                                        fontSize: "14px",
                                        borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        margin: '0px',
                                      }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        height: '30px',
                                        padding: '0 6px'
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                      ...provided,
                                      height: '30px',
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black"
                                    })
                                }}
                            />
                        </FiltrarData>
                    </OpcoesFiltragem>
                    {Pesquisa(setBusca, null, busca)}
                </AreaSuperior>
                <AreaSuperior>
                    <p>Ingressos selecionados: {selecionados.length}</p>
                    <Botao disabled={carregando} $marginRight="20px" onClick={() => selecionados.length > 0? setShowAviso2(true): ""}>Excluir</Botao>
                </AreaSuperior>
                {((carregando && <ErroSpan $corFundo="transparent" $corTexto="#252525">Carregando...</ErroSpan>) || (ingressos.length === 0 && <ErroSpan $corFundo="transparent">Nenhum ingresso encontrado!</ErroSpan>)) || <>
                    {TabelaIngressos(ingressos, params.get("ordenacao"), params.get("ordem"), atualizarParametro, showAviso, setShowAviso, selecionados, setSelecionados)}
                    <RodapePaginas>
                        <TotalDocs>
                            <p>Total: {total.docs}</p>
                        </TotalDocs>
                        <PaginasTabela>
                            <p onClick={() => atualizarParametro("pagina", 1)}>Primeira</p>
                            <p onClick={() => {
                                if (params.get("pagina") > 1) atualizarParametro("pagina", Number(params.get("pagina"))-1);
                                }
                            }>Anterior</p>
                            {paginas()}
                            <p onClick={() => {
                                if (params.get("pagina") < total.paginas) atualizarParametro("pagina", Number(params.get("pagina"))+1);
                            }}>Próxima</p>
                            <p onClick={() => atualizarParametro("pagina", total.paginas)}>Última</p>
                        </PaginasTabela>
                    </RodapePaginas>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    );
    
    function paginas() {
        let pages = [];
        let ajuste = 2;
        if (Number(params.get("pagina")) === 1){
            ajuste = 0;
        } else if (Number(params.get("pagina")) === 2){
            ajuste = 1;
        } else if (Number(params.get("pagina")) === total.paginas-1 && total.paginas > 4){
            ajuste = 3;
        } else if (Number(params.get("pagina")) === total.paginas && total.paginas > 4){
            ajuste = 4;
        }
        if (total.paginas < 5){
            for (let i = 0; i < total.paginas; i++){
                let pageNum = parseInt(i+1);
    
                if (pageNum === Number(params.get("pagina"))) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => atualizarParametro("pagina", parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
                let pageNum = parseInt(params.get("pagina")) + parseInt(i) - ajuste;
                if (pageNum === Number(params.get("pagina"))) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => atualizarParametro("pagina", parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}