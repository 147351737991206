import Cookies from "js-cookie";

export async function editarLinha(linha){
    try {
        const resposta = await fetch(`/api/cores-guias/editar`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(linha)
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return e.message;
    }
}

export async function obterLinhas(loja){
    try {
        const resposta = await fetch(`/api/cores-guias/${loja}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}