import { useEffect, useState } from "react";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { AreaSuperior, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { listarUsuarios } from "../../../servicos/usuariosServices";
import { TabelaUsuarios } from "./TabelaUsuarios";
import { Link } from "react-router-dom";
import Pesquisa from "../../../componentes/Pesquisa";
import Botao from "../../../componentes/Botao";
import ErroSpan from "../../../componentes/ErroSpan";

export default function Usuarios() {
    const [usuarios, setUsuarios] = useState([]);
    const [ordenacao, setOrdenacao] = useState("nome");
    const [ordem, setOrdem] = useState(1);
    const [busca, setBusca] = useState("");
    const [showAviso, setShowAviso] = useState({
        mostrar: false,
        usuario: null
    });

    useEffect(() => {
        obterUsuarios(busca, ordenacao, ordem);
    }, [busca, ordenacao, ordem, showAviso]);

    async function obterUsuarios(busca, ordenacao, ordem){
        const resposta = await listarUsuarios(busca, ordenacao, ordem);
        if (resposta) setUsuarios(resposta);
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Usuarios")}
                <AreaSuperior>
                    <Botao $marginTop="0"><Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/usuarios/cadastrar">Adicionar usuário</Link></Botao>
                    {Pesquisa(setBusca, null, busca)}
                </AreaSuperior>
                {((!usuarios || usuarios.length === 0) && <ErroSpan $corFundo="transparent" $corTexto="#252525">Carregando...</ErroSpan>) || TabelaUsuarios(usuarios, ordenacao, ordem, setOrdenacao, setOrdem, showAviso, setShowAviso)}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}