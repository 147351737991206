import styled from "styled-components";
import Titulo1 from "../componentes/Titulo1";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useUsuarioContext } from "../contexto/SessaoUsuario";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Cabecalho = styled.header`
    border-bottom: 1px solid #aaaaaa;
    margin: 0;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h1 {
        text-align: left;
    }
`
const UsuarioLogado = styled.div`
    width: 100px;
    text-align: right;
    font-size: 18px;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #252525;
    
    p {
        margin: 0 0 0 5px;
    }
`
const MenuUsuario = styled.div`
    display: ${props => props.$mostrar? "block": "none"};
    position: absolute;
    top: 60px;
    right: 30px;
    border: 1px solid #aaaaaa;
    border-radius: 2px;
    background-color: white;
    box-shadow: 3px 3px 5px 1px #b6b6b6;

    p {
        text-align: right;
        padding: 5px 8px;
        margin: 0;
        border: 1px solid #aaaaaa;
        color: #252525;
        cursor: pointer;

        .Icone {
            margin-right: 5px;
            margin-top: 5px;
        }
    }
`
export default function CabecalhoAdmin(titulo="Administração") {
    const { usuario, atualizarUsuario } = useUsuarioContext();
    const [mostrarMenu, setMostrarMenu] = useState(false);
    const navigate = useNavigate();

    useEffect( () => {
        atualizarUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = () => {
        Cookies.remove("token_tour");
        navigate("/login", { state: { redirecionar: "/admin" }});
    }
    
    return (
        <Cabecalho>
            <Titulo1>{titulo}</Titulo1>
                <MenuUsuario $mostrar={mostrarMenu}>
                    <p onClick={() => navigate("/admin/alterar-senha")}>Mudar senha</p>
                    <p onClick={logout}><MdLogout className="Icone"/>Sair</p>
                </MenuUsuario>
                <UsuarioLogado onClick={() => setMostrarMenu(!mostrarMenu)}>
                    <FaUserCircle />
                    <p>{usuario && usuario.nome.split(" ")[0]}</p>
                </UsuarioLogado>
        </Cabecalho>
    )
}