import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { zodResolver } from '@hookform/resolvers/zod';
import Botao from "../../../componentes/Botao";
import ErroSpan from '../../../componentes/ErroSpan';
import { AreaBotoesAdd, DivFormulario, SecaoAdmin, SecaoPrincipal } from '../AdminStyled';
import MenuLateral from '../../../componentes/MenuLateral';
import { criarUsuario } from '../../../servicos/usuariosServices';
import usuarioSchema from '../../../schemas/usuarioSchema';
import CabecalhoAdmin from '../../../componentes/CabecalhoAdmin';

const permissoes = [
    {label: "Administrador", value: 1},
    {label: "Atendente", value: 2}
];

const camposForm = [
    ["Nome", "nome", "text"],
    ["E-mail", "email", "text"],
    ["Permissões", "permissoes", "text", permissoes, "Selecione o tipo do usuário"],
    ["Senha", "senha", "password"],
    ["Confirmar senha", "confirmPassword", "password"]
];

export default function CadastrarUsuario(){
    const [status, setStatus] = useState({
        erro: false,
        sucesso: false,
        mensagem: undefined
    });

    const { 
        control,
        register: registerUser, 
        handleSubmit: handleSubmitUser, 
        reset: resetUser,
        formState: { errors: errosUser }
    } = useForm({
        resolver: zodResolver(usuarioSchema)
    });

    async function SalvarNovoUsuario(data){
        const resposta = await criarUsuario(data);
        if (resposta && resposta.status === 201) {
            setStatus({
                erro: false,
                sucesso: true,
                mensagem: resposta.usuario.nome
            })
            resetUser();
        } else {
            setStatus({
                erro: true,
                sucesso: false,
                mensagem: resposta.mensagem
            });
        }
    }

    return (
    <SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Cadastrar novo usuário")}
            {status.erro && <ErroSpan>{"Erro ao cadastrar usuário. " + status.mensagem}</ErroSpan>}
            {status.sucesso && <ErroSpan $corFundo={"#ddffcd"} $corTexto={"#209e00"} >{`Usuário ${status.mensagem} cadastrado com sucesso.`}</ErroSpan>}
            <form onSubmit={handleSubmitUser(SalvarNovoUsuario)}>
                <AreaBotoesAdd style={{ marginTop: "20px" }}>
                    <Botao type="submit" $corFundo="#3a170ef8" $cor="white" $marginRight="10px">Salvar</Botao>
                    <Link to="/admin/guias"><Botao $marginRight="40px">Cancelar</Botao></Link>  
                </AreaBotoesAdd>
                <DivFormulario>
                    {camposForm.map((campo) => { return (
                        <div key={campo[1]}>
                            <label>{campo[0]}</label>
                            {(campo[3] && <Controller
                                name={campo[1]}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: 0,
                                                margin: 0,
                                                marginBottom: 10,
                                                border: "2px solid rgba(58, 23, 14, 0.308)",
                                                width: "100%",
                                                height: 8,
                                                borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                            })
                                        }}
                                        classNamePrefix={'Select'}
                                        placeholder={campo[4]}
                                        {...field}
                                        options={campo[3]}
                                    />
                                )}
                            />) || <input {...registerUser(campo[1], {required: "Campo obrigatório"})} type={campo[2]}/>}
                            {errosUser[campo[1]] && <ErroSpan>{errosUser[campo[1]].message}</ErroSpan>}
                        </div>
                    )})}
                </DivFormulario>
            </form>
        </SecaoPrincipal>
    </SecaoAdmin>
    )
}