import { useParams } from "react-router-dom";
import { SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import MenuLateral from "../../../componentes/MenuLateral";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import { useEffect, useState } from "react";
import { obterTicketsVisitante } from "../../../servicos/ticketsServices";
import { obterVisitantePorId } from "../../../servicos/visitantesServices";
import { AreaVisitante, InfosVisitante, LinhaInfos } from "./VisitantesStyled";
import { VisualizaTickets } from "../sessoes/SessoesStyled";
import Titulo1 from "../../../componentes/Titulo1";
import { TabelaTicketsVisitante } from "./TabelaTicketsVisitante";

export default function VisitanteId(){
    const { id } = useParams();
    const [tickets, setTickets] = useState([]);
    const [visitante, setVisitante] = useState(null);
    const [showAviso, setShowAviso] = useState({
        mostrar: false,
        ticket: null
    });

    useEffect(() => {
        obterTickets();
        obterVisitante();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAviso]);

    async function obterVisitante(){
        const resposta = await obterVisitantePorId(id);
        if (resposta.visitante) setVisitante(resposta.visitante);
    }

    async function obterTickets(){
        const resposta = await obterTicketsVisitante(id);
        if (resposta.tickets) setTickets(resposta.tickets);
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Visualizar visitante")}
                {visitante && 
                <AreaVisitante>
                    <LinhaInfos>
                        <InfosVisitante $margem="15px 30px 0 0" $cor="#252525">Nome: </InfosVisitante>
                        <InfosVisitante $margem="15px 30px 0 0" $bold="bold">{visitante.nome || "-"}</InfosVisitante>
                    </LinhaInfos>
                    <LinhaInfos>
                        <InfosVisitante $margem="15px 30px 0 0" $cor="#252525">E-mail: </InfosVisitante>
                        <InfosVisitante $margem="15px 30px 0 0" $bold="bold">{visitante.email || "-"}</InfosVisitante>
                    </LinhaInfos>
                    <LinhaInfos>
                        <InfosVisitante  $margem="15px 30px 0 0" $cor="#252525">Celular: </InfosVisitante>
                        <InfosVisitante $margem="15px 30px 15px 0" $bold="bold">{visitante.celular || "-"}</InfosVisitante>
                    </LinhaInfos>
                </AreaVisitante>}
                {tickets && 
                <VisualizaTickets>
                    <Titulo1 $pad="30px 10px 10px 40px" $tamanho="1.5em">Ingressos</Titulo1>
                    {TabelaTicketsVisitante(tickets, showAviso, setShowAviso)}
                </VisualizaTickets>}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}