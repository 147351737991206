import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { Chart, ArcElement, LineElement, PointElement, LinearScale, Tooltip, Legend, CategoryScale } from "chart.js";
import { Pie, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CabecalhoAdmin from "../../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../../componentes/MenuLateral";
import { AreaSuperior, FiltrarData, InputMes, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal } from "../../AdminStyled";
import { totaisPorTipo } from "../../../../servicos/ticketsServices";
import { AreaRelatorio, LadoALado } from "../IndicadoresStyled";
import ErroSpan from "../../../../componentes/ErroSpan";

// Register the necessary Chart.js components
Chart.register(ArcElement, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, ChartDataLabels);

export default function PorTipo() {
    const [params, setParams] = useSearchParams();
    const [datas, setDatas] = useState({
        dataInicial: params.get("dataInicial"),
        dataFinal: params.get("dataFinal") 
    });
    const [carregando, setCarregando] = useState(true);
    const [dadosTipo, setDadosTipo] = useState(null);
    const [dadosFds, setDadosFds] = useState(null);
    const [dadosDiasUteis, setDadosDiasUteis] = useState(null);
    const [dadosPorDia, setDadosPorDia] = useState(null);

    useEffect(() => {
        if (params.get("dataInicial") && params.get("dataFinal")) {
            setCarregando(true);
            totaisPorTipo(params.get("dataInicial"), params.get("dataFinal")).then( resposta => {
                setDadosTipo({
                    labels: [
                        "PROMOCIONAL",
                        "PROMOCIONAL-ONLINE",
                        "PROMOCIONAL-EXCURSÃO",
                        "INFANTIL",
                        "INFANTIL-ONLINE",
                        "CORTESIA"
                    ],
                    datasets: [
                        {
                            label: "Totais por Tipo",
                            data: [
                                resposta.promocionais.reduce( (acumulador, atual) => acumulador + atual, 0),
                                resposta.promocionaisOnline.reduce( (acumulador, atual) => acumulador + atual, 0),
                                resposta.promocionaisExcursao.reduce( (acumulador, atual) => acumulador + atual, 0),
                                resposta.infantis.reduce( (acumulador, atual) => acumulador + atual, 0),
                                resposta.infantisOnline.reduce( (acumulador, atual) => acumulador + atual, 0),
                                resposta.cortesias.reduce( (acumulador, atual) => acumulador + atual, 0)
                            ],
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(87, 75, 192, 0.2)',
                                'rgba(190, 192, 75, 0.2)',
                                'rgba(192, 75, 95, 0.2)',
                                'rgba(0, 47, 255, 0.2)',
                                'rgba(75, 192, 100, 0.2)'
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                                'rgb(87, 75, 192)',
                                'rgb(190, 192, 75)',
                                'rgb(192, 75, 95)',
                                'rgb(0, 47, 255)',
                                'rgb(75, 192, 100)'
                            ],
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosFds({
                    labels: [
                        "PROMOCIONAL",
                        "PROMOCIONAL-ONLINE",
                        "PROMOCIONAL-EXCURSÃO",
                        "INFANTIL",
                        "INFANTIL-ONLINE",
                        "CORTESIA"
                    ],
                    datasets: [
                        {
                            label: "Tipos nos finais de semana",
                            data: [
                                resposta.promocionais[0] + resposta.promocionais[6],
                                resposta.promocionaisOnline[0] + resposta.promocionaisOnline[6],
                                resposta.promocionaisExcursao[0] + resposta.promocionaisExcursao[6],
                                resposta.infantis[0] + resposta.infantis[6],
                                resposta.infantisOnline[0] + resposta.infantisOnline[6],
                                resposta.cortesias[0] + resposta.cortesias[6]
                            ],
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(87, 75, 192, 0.2)',
                                'rgba(190, 192, 75, 0.2)',
                                'rgba(192, 75, 95, 0.2)',
                                'rgba(0, 47, 255, 0.2)',
                                'rgba(75, 192, 100, 0.2)'
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                                'rgb(87, 75, 192)',
                                'rgb(190, 192, 75)',
                                'rgb(192, 75, 95)',
                                'rgb(0, 47, 255)',
                                'rgb(75, 192, 100)'
                            ],
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosDiasUteis({
                    labels: [
                        "PROMOCIONAL",
                        "PROMOCIONAL-ONLINE",
                        "PROMOCIONAL-EXCURSÃO",
                        "INFANTIL",
                        "INFANTIL-ONLINE",
                        "CORTESIA"
                    ],
                    datasets: [
                        {
                            label: "Totais por Tipo",
                            data: [
                                resposta.promocionais[1] + resposta.promocionais[2] + resposta.promocionais[3] + resposta.promocionais[4] + resposta.promocionais[5],
                                resposta.promocionaisOnline[1] + resposta.promocionaisOnline[2] + resposta.promocionaisOnline[3] + resposta.promocionaisOnline[4] + resposta.promocionaisOnline[5],
                                resposta.promocionaisExcursao[1] + resposta.promocionaisExcursao[2] + resposta.promocionaisExcursao[3] + resposta.promocionaisExcursao[4] + resposta.promocionaisExcursao[5],
                                resposta.infantis[1] + resposta.infantis[2] + resposta.infantis[3] + resposta.infantis[4] + resposta.infantis[5],
                                resposta.infantisOnline[1] + resposta.infantisOnline[2] + resposta.infantisOnline[3] + resposta.infantisOnline[4] + resposta.infantisOnline[5],
                                resposta.cortesias[1] + resposta.cortesias[2] + resposta.cortesias[3] + resposta.cortesias[4] + resposta.cortesias[5]
                            ],
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(87, 75, 192, 0.2)',
                                'rgba(190, 192, 75, 0.2)',
                                'rgba(192, 75, 95, 0.2)',
                                'rgba(0, 47, 255, 0.2)',
                                'rgba(75, 192, 100, 0.2)'
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                                'rgb(87, 75, 192)',
                                'rgb(190, 192, 75)',
                                'rgb(192, 75, 95)',
                                'rgb(0, 47, 255)',
                                'rgb(75, 192, 100)'
                            ],
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosPorDia({
                    labels: [
                        "Domingo",
                        "Segunda-feira",
                        "Terça-feira",
                        "Quarta-feira",
                        "Quinta-feira",
                        "Sexta-feira",
                        "Sábado"
                    ],
                    datasets: [
                        {
                            label: "PROMOCIONAL",
                            data: resposta.promocionais,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderWidth: 2,
                            tension: 0.4,
                        },
                        {
                            label: "PROMOCIONAL-ONLINE",
                            data: resposta.promocionaisOnline,
                            borderColor: 'rgb(87, 75, 192)',
                            backgroundColor: 'rgba(87, 75, 192, 0.2)',
                            borderWidth: 2,
                            tension: 0.4,
                        },
                        {
                            label: "PROMOCIONAL-EXCURSÃO",
                            data: resposta.promocionaisExcursao,
                            borderColor: 'rgb(190, 192, 75)',
                            backgroundColor: 'rgba(190, 192, 75, 0.2)',
                            borderWidth: 2,
                            tension: 0.4,
                        },
                        {
                            label: "INFANTIL",
                            data: resposta.infantis,
                            borderColor: 'rgb(192, 75, 95)',
                            backgroundColor: 'rgba(192, 75, 95, 0.2)',
                            borderWidth: 2,
                            tension: 0.4,
                        },
                        {
                            label: "INFANTIL-ONLINE",
                            data: resposta.infantisOnline,
                            borderColor: 'rgb(0, 47, 255)',
                            backgroundColor: 'rgba(0, 47, 255, 0.2)',
                            borderWidth: 2,
                            tension: 0.4,
                        },
                        {
                            label: "CORTESIAS",
                            data: resposta.cortesias,
                            borderColor: 'rgb(75, 192, 100)',
                            backgroundColor: 'rgba(75, 192, 100, 0.2)',
                            borderWidth: 2,
                            tension: 0.4,
                        }
                    ]
                });
                setCarregando(false);
            });
        }
    }, [params]);

    function atualizarParametros() {
        setParams(params => {
            params.set("dataInicial", datas.dataInicial);
            params.set("dataFinal", datas.dataFinal);
            return params;
        });
    }
    
    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Indicadores por tipo de ingresso")}
                <AreaSuperior>
                    <OpcoesFiltragem>
                        <FiltrarData $marginTop="1px">
                            <label>Data inicial</label>
                            <InputMes type="date" defaultValue={params.get("dataInicial")} onChange={event => setDatas({...datas, dataInicial: event.target.value})}/>
                        </FiltrarData>
                        <FiltrarData $marginTop="1px">
                            <label>Data final</label>
                            <InputMes type="date" defaultValue={params.get("dataFinal")} onChange={event => setDatas({...datas, dataFinal: event.target.value})}/>
                        </FiltrarData>
                        <IoMdRefresh className="Icone" onClick={() => atualizarParametros()}/>
                    </OpcoesFiltragem>
                </AreaSuperior>
                {carregando && <ErroSpan>Carregando...</ErroSpan>}
                <LadoALado $margem="10px 5px 0px 5px">
                    {dadosTipo && <AreaRelatorio $maxLargura="33%">
                        <Pie data={dadosTipo} options={{
                            plugins: {
                                legend: {
                                    position: 'bottom',  // Position the legend below the chart
                                },
                                title: {
                                    text: "Total",
                                    display: true,
                                    font: {
                                        size: "16px"
                                    },
                                    color: '#252525'
                                }, 
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        const datapoints = ctx.chart.data.datasets[0].data;
                                        const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                                        const percentage = value / total * 100;
                                        return percentage.toFixed(2) + "%";
                                    },
                                    color: '#25252594'
                                }
                            }
                        }}/>
                    </AreaRelatorio>}
                    {dadosFds && <AreaRelatorio $maxLargura="33%">
                        <Pie data={dadosFds} options={{
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                },
                                title: {
                                    text: "Finais de semana",
                                    display: true,
                                    font: {
                                        size: "16px"
                                    },
                                    color: '#252525'
                                }, 
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        const datapoints = ctx.chart.data.datasets[0].data;
                                        const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                                        const percentage = value / total * 100;
                                        return percentage.toFixed(2) + "%";
                                    },
                                    color: '#25252594'
                                }
                            }
                        }}/>
                    </AreaRelatorio>}
                    {dadosDiasUteis && <AreaRelatorio $maxLargura="33%">
                        <Pie data={dadosDiasUteis} options={{
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                },
                                title: {
                                    text: "Dias da semana",
                                    display: true,
                                    font: {
                                        size: "16px"
                                    },
                                    color: '#252525'
                                }, 
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        const datapoints = ctx.chart.data.datasets[0].data;
                                        const total = datapoints.reduce((total, datapoint) => total + datapoint, 0);
                                        const percentage = value / total * 100;
                                        return percentage.toFixed(2) + "%";
                                    },
                                    color: '#25252594'
                                }
                            }
                        }}/>
                    </AreaRelatorio>}
                </LadoALado>
                {dadosPorDia && <AreaRelatorio $maxLargura="1000px">
                    <Line data={dadosPorDia} options={{
                        plugins: {
                            legend: {
                                position: 'bottom',
                            },
                            title: {
                                text: "Análise por dia da semana",
                                display: true,
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                            datalabels: {
                                display: false
                            }
                        }
                    }}/>
                </AreaRelatorio>}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}