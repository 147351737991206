import styled from "styled-components";

const ErroSpan = styled.span`
    background-color: ${props => props.$corFundo || "#ffcdcd"};
    color: ${props => props.$corTexto || "#9e0000"};
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    border-radius: 7px;
`

export default ErroSpan;