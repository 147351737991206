import Cookies from "js-cookie";

export async function salvarFoto(img, ticket, visitante) {
    try {
        const resposta = await fetch("/api/fotos", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                foto: img.split(",")[1],
                ticket: ticket,
                visitante: visitante
            })
        });

        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro ao salvar foto";
    }
}
export async function reenviarFotoPorWhatsapp(tickets) {
    try {
        const resposta = await fetch("/api/fotos/reenviar-wpp", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(tickets)
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return {erro: true, mensagem: e.message};
    }
}
export async function getFotoPorTicket(ticket) {
    try {
        const resposta = await fetch(`/api/fotos/ticket/${ticket}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro ao obter foto";
    }
}
export async function getFoto(foto) {
    try {
        const resposta = await fetch(`/api/fotos/${foto}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}
export async function getFotoWpp(id) {
    try {
        const resposta = await fetch(`/api/fotos-wpp/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return resposta;
    } catch (e) {
        console.log(e.message);
        return [];
    }
}
export async function enviarFoto(foto, visitante) {
    try {
        const resposta = await fetch("/api/fotos/teste", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                email: visitante.email,
                celular: visitante.celular,
                foto: foto
            })
        });

        console.log(await resposta.json());
        return "Enviado";
    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}
export async function getFotosMural(fotosAtuais) {
    try {
        const resposta = await fetch("/api/fotos-mural", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(fotosAtuais)
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}
export async function testeEnvio() {
    try {
        const resposta = await fetch("/api/fotos/teste", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                visitante: {
                    email: "engenharia@nugali.com.br",
                    celular: "(47) 991319391"
                },
                mensagem: "Olá. Testando."
            })
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro"
    }
}