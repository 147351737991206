import styled from "styled-components";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BotaoExcluir } from "../AdminStyled";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { removerGuia } from "../../../servicos/guiasServices";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};

    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
        cursor: pointer;
    }
`
const BotaoAcoes = styled.button`
    margin: 5px;
    padding: 2px 5px;
    cursor: pointer;
    background-color: var(--corNugaliClara);
    color: var(--corNugaliEscura);
    border-radius: 3px;
    border: 1px solid white;

    &:hover {
        background-color: var(--corNugaliEscura);
        color: white;
        border: 1px solid var(--corNugaliEscura);
    }
`
export function TabelaGuias(guias, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual, aviso, setAviso) {
    const campos = [
        {chave: "codigo", label: "Código"},
        {chave: "nome", label: "Nome"},
        {chave: "cidade", label: "Cidade"},
        {chave: "estado", label: "Estado"}
    ]

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }

    const handleSim = async () => {
        await removerGuia(aviso.guia);
        setAviso({ mostrar: false, guia: null });
    }
    const handleNao = () => {
        setAviso({ mostrar: false, guia: null });
    }
    
    try {
        return (<>
            {ModalPergunta("Tem certeza que deseja deletar este guia?", handleSim, handleNao, aviso.mostrar, "100px")}
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                        <th>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>
                    {guias.map((guia) => {
                        let waid = (guia.whatsApp).replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "");
                        if (waid.length < 12) waid = "55" + waid;

                        let mensagem = `Olá! Tudo bem?%0a%0aTivemos uma alteração em nosso sistema e alteramos os links de parceria para compras/vendas do Tour.%0a*Cada compra/venda concretizada, o comissionamento é creditado em seu cadastro.%0a%0aSegue novo link: https://www.nugali.com.br/tickets/${guia.codigo}`;
                        mensagem = mensagem.replaceAll(" ", "%20");

                        return (<LinhaTabela key={guia.codigo}> 
                            <ColunaTabela $tamanho = "50px">{guia.codigo}</ColunaTabela>
                            <ColunaTabela $tamanho = "400px">{guia.nome}</ColunaTabela>
                            <ColunaTabela>{guia.cidade}</ColunaTabela>
                            <ColunaTabela>{guia.estado}</ColunaTabela>
                            <ColunaTabela>
                                <Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/guias/${guia._id}`}><BotaoAcoes>Editar</BotaoAcoes></Link>
                                <BotaoExcluir onClick={() => setAviso({ mostrar: true, guia: guia.codigo })}>Excluir</BotaoExcluir>
                                <Link style={{ textDecoration: 'none', color: "inherit" }} to={`https://wa.me/${waid}?text=${mensagem}`} target="_blank" rel="noopener noreferrer"><FaWhatsapp className="Icone"/></Link>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
        </>)
    } catch (e) {
        return <></>
    }
}