import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from 'react-chartjs-2';
import CabecalhoAdmin from "../../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../../componentes/MenuLateral";
import { AreaSuperior, FiltrarData, InputMes, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal } from "../../AdminStyled";
import { ingressosPorDia } from "../../../../servicos/ticketsServices";
import { AreaRelatorio, LadoALado } from "../IndicadoresStyled";
import ErroSpan from "../../../../componentes/ErroSpan";

// Register the necessary Chart.js components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Excursoes() {
    const [params, setParams] = useSearchParams();
    const [datas, setDatas] = useState({ 
        dataInicial: params.get("dataInicial"),
        dataFinal: params.get("dataFinal") 
    });
    const [carregando, setCarregando] = useState(true);
    const [dadosPorDia, setDadosPorDia] = useState(null);
    const [dadosSemana, setDadosSemana] = useState(null);
    const [dadosMes, setDadosMes] = useState(null);

    useEffect(() => {
        if (params.get("dataInicial") && params.get("dataFinal")) {
            setCarregando(true);
            ingressosPorDia(params.get("dataInicial"), params.get("dataFinal"), "PROMOCIONAL-EXCURSÃO").then( resposta => {
                let dDia = new Date(params.get("dataInicial").split("-")[0], params.get("dataInicial").split("-")[1] - 1, params.get("dataInicial").split("-")[2]);
                const dados1 = {};
                const dados2 = [0, 0, 0, 0, 0, 0, 0];
                const dados3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                while (dDia <= new Date(params.get("dataFinal").split("-")[0], params.get("dataFinal").split("-")[1] - 1, params.get("dataFinal").split("-")[2])) {
                    dados1[dDia.toISOString().split("T")[0]] = 0;
                    dDia.setDate(dDia.getDate() + 1);
                }

                for (let i = 0; i < resposta.ingressos.length; i++) {
                    dDia = new Date(resposta.ingressos[i].numero*1000);
                    dados3[dDia.getMonth()]++;
                    dados2[dDia.getDay()]++;
                    dados1[dDia.toISOString().split("T")[0]]++;
                }
                setDadosPorDia({
                    labels: Object.keys(dados1),
                    datasets: [
                        {
                            label: "Quantidade de ingressos",
                            data: Object.values(dados1),
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosSemana({
                    labels: [
                        "Domingo",
                        "Segunda-feira",
                        "Terça-feira",
                        "Quarta-feira",
                        "Quinta-feira",
                        "Sexta-feira",
                        "Sábado"
                    ],
                    datasets: [
                        {
                            label: "Quantidade de ingressos",
                            data: dados2,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderWidth: 1,
                        }
                    ]
                });
                setDadosMes({
                    labels: [
                        "Janeiro",
                        "Fevereiro",
                        "Março",
                        "Abril",
                        "Maio",
                        "Junho",
                        "Julho",
                        "Agosto",
                        "Setembro",
                        "Outubro",
                        "Novembro",
                        "Dezembro"
                    ],
                    datasets: [
                        {
                            label: "Quantidade de ingressos",
                            data: dados3,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderWidth: 1,
                        }
                    ]
                });
                setCarregando(false);
            });
        }
    }, [params]);

    function atualizarParametros() {
        setParams(params => {
            params.set("dataInicial", datas.dataInicial);
            params.set("dataFinal", datas.dataFinal);
            return params;
        });
    }
    
    return (<SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Indicadores de excursões")}
            <AreaSuperior>
                <OpcoesFiltragem>
                    <FiltrarData $marginTop="1px">
                        <label>Data inicial</label>
                        <InputMes type="date" defaultValue={params.get("dataInicial")} onChange={event => setDatas({...datas, dataInicial: event.target.value})}/>
                    </FiltrarData>
                    <FiltrarData $marginTop="1px">
                        <label>Data final</label>
                        <InputMes type="date" defaultValue={params.get("dataFinal")} onChange={event => setDatas({...datas, dataFinal: event.target.value})}/>
                    </FiltrarData>
                    <IoMdRefresh className="Icone" onClick={() => atualizarParametros()}/>
                </OpcoesFiltragem>
            </AreaSuperior>
            {carregando && <ErroSpan>Carregando...</ErroSpan>}
            <LadoALado $margem="10px 5px 10px 5px">
                {dadosSemana && <AreaRelatorio $maxLargura="38%" $altura="250px">
                    <Bar data={dadosSemana} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos de excursão na semana",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                        }
                    }}/>
                </AreaRelatorio>}
                {dadosMes && <AreaRelatorio $maxLargura="60%" $altura="250px">
                    <Bar data={dadosMes} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos de excursão por mês",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                        }
                    }}/>
                </AreaRelatorio>}
            </LadoALado>
            {dadosPorDia && <AreaRelatorio $maxLargura="95%">
                <Bar data={dadosPorDia} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos de excursão por dia",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                        }
                    }}/>
            </AreaRelatorio>}
        </SecaoPrincipal>
    </SecaoAdmin>)
}