import { Link } from "react-router-dom";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import Titulo1 from "../../../componentes/Titulo1";
import { SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { ColunaTabela, LinhaTabela, TabelaLinks } from "./IndicadoresStyled"

export default function Indicadores() {
    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Indicadores")}
                <Titulo1 $tamanho="18px" $margem="10px" >Selecione um painel de indicadores</Titulo1>
                <TabelaLinks>
                    <tbody>
                        <LinhaTabela>
                            <ColunaTabela>
                                <Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/indicadores/tipo?dataInicial=${new Date().toISOString().split("T")[0]}&dataFinal=${new Date().toISOString().split("T")[0]}`}>Análise por tipo</Link>
                            </ColunaTabela>
                            <ColunaTabela>
                                <p>Exibe diferentes análises referentes ao Tipo dos ingressos do Tour.</p>
                            </ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela>
                                <Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/indicadores/excursao?dataInicial=${new Date().toISOString().split("T")[0]}&dataFinal=${new Date().toISOString().split("T")[0]}`}>Excursões</Link>
                            </ColunaTabela>
                            <ColunaTabela>
                                <p>Análise completa dos ingressos de excursão.</p>
                            </ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela>
                                <Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/indicadores/online?dataInicial=${new Date().toISOString().split("T")[0]}&dataFinal=${new Date().toISOString().split("T")[0]}`}>Onlines</Link>
                            </ColunaTabela>
                            <ColunaTabela>
                                <p>Análise completa dos ingressos comprados no site.</p>
                            </ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela>
                                <Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/indicadores/guias?dataInicial=${new Date().toISOString().split("T")[0]}&dataFinal=${new Date().toISOString().split("T")[0]}`}>Guias</Link>
                            </ColunaTabela>
                            <ColunaTabela>
                                <p>Análise do desempenho e participação dos guias e parceiros cadastrados.</p>
                            </ColunaTabela>
                        </LinhaTabela>
                    </tbody>
                </TabelaLinks>
                
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}