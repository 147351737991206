import styled from "styled-components";
import { FaTicketAlt } from "react-icons/fa";
import { MdPhotoCamera } from "react-icons/md";
import { Link } from "react-router-dom";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { deletarTicket, imprimirIngresso } from "../../../servicos/ticketsServices";
import { BotaoExcluir } from "../AdminStyled";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};

    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
        cursor: pointer;
    }
`
export function TabelaTicketsVisitante(tickets, showAviso, setShowAviso) {
    const campos = [
        {chave: "numero", label: "Número"},
        {chave: "tipo", label: "Tipo"},
        {chave: "sessao", label: "Sessão"}
    ];
    
    const handleSim = async () => {
        await deletarTicket(showAviso.ticket);
        setShowAviso({ mostrar: false, ticket: null });
    };
    const handleNao = async () => {
        setShowAviso({ mostrar: false, ticket: null });
    };
    
    try {
        return (
            <>{ModalPergunta("Tem certeza que deseja deletar este ingresso?", handleSim, handleNao, showAviso.mostrar, "100px")}
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave}>
                                <p>{campo.label}</p>
                            </TituloColunas>
                        })}
                        <th style={{color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>

                    {tickets.map((ticket) => {
                        return (<LinhaTabela key={ticket._id}> 
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/ingressos/${ticket._id}`}>{ticket.numero}</Link></ColunaTabela>
                            <ColunaTabela>{ticket.tipo}</ColunaTabela>
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${ticket.sessao._id}`}>{new Date(ticket.sessao.diaHora).toLocaleDateString('en-GB')} {ticket.sessao.diaHora.split("T")[1].slice(0,5)}</Link></ColunaTabela>
                            <ColunaTabela>
                                <FaTicketAlt className="Icone" onClick={() => imprimirIngresso(ticket.numero, ticket.tipo, ticket.sessao)}/>
                                {ticket.foto && <Link style={{ textDecoration: 'none', color: "inherit" }} target={"_blank"} to={`/foto/${ticket.foto}`}><MdPhotoCamera className="Icone"/></Link>}
                                <BotaoExcluir onClick={() => setShowAviso({ mostrar: true, ticket: ticket.numero })}>Excluir</BotaoExcluir>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
            </>
        )
    } catch (e) {
        return <></>
    }
}