import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import Jost300Light from "./assets/jost/Jost-300-Light.otf";
import Jost500Medium from "./assets/jost/Jost-500-Medium.otf";
import Jost700Bold from "./assets/jost/Jost-700-Bold.otf";

export const GlobalStyled = createGlobalStyle`
    :root {
        @font-face {
            font-family: "Jost";
            font-style: normal;
            font-weight: 300;
            src: url(${Jost300Light}) format("opentype");
        }
        @font-face {
            font-family: "Jost";
            font-style: normal;
            font-weight: 500;
            src: url(${Jost500Medium}) format("opentype");
        }
        @font-face {
            font-family: "Jost";
            font-style: bold;
            font-weight: 700;
            src: url(${Jost700Bold}) format("opentype");
        }

        font-family: "Jost", sans-serif;

        /* --corNugaliClara: #ddc397cc; */
        --corNugaliClara: #F3DEC4;
		/* --corNugaliEscura: #3a170e; */
        --corNugaliEscura: #3f1005;
		--corNugaliClaraEscrita: rgba(254, 209, 149, 0.9);
		--corEscrita: #05031de5;
		--corAzul: #110869e2;
        
        width: 100%;
        height: 100%;
        background-color: rgba(221, 195, 151, 0.8);
        color: var(--corNugaliEscura);
        margin: 0;
        padding: 0;
        
        body {
            margin: 0;
            a {
                text-decoration: none;
            }
        }
    }
`
export const InputStyled = styled.input`
  	padding: 0px 2px;
	border: 2px solid var(--corEscrita);
	border-radius: 5px;
	font-size: 16px;
	outline: none;
	margin: 15px;
	background-color: transparent;
	color: var(--corEscrita);
	transition: all .2s ease-out;
	&:-webkit-autofill {
		-webkit-background-clip: text;
		box-shadow: inset 0 0 20px 20px transparent;
		-webkit-text-fill-color: #3a170e;
		border: 2px solid rgba(58, 23, 14, 0.308);
	}
`