import styled from "styled-components";

export const SecaoIngressosDia = styled.section`
    height: 100%;
    width: 100%;
    background-color: #ba9961;
    position: absolute;
    overflow-y: hidden;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
`
export const TabelaHorarios = styled.table`
    justify-content: center;
    text-align: center;
    width: 97%;
    height: calc(100% - 180px);
    margin: auto;
`
export const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    border-top: ${props => props.$linha || "none"};
    height: 30px;
    td {
        padding-top: ${props => props.$espaco || "none"};
        padding-bottom: ${props => props.$espaco || "none"};
    }
`
export const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    background-color: ${props => props.$fundo || "transparent"};
    color: ${props => props.$cor || "var(--corNugaliEscura)"};
    font-weight: ${props => props.$bold || "normal"};
    font-size: 28px;
`