import styled from "styled-components";

export const AreaCamera = styled.div`
    margin-left: 50px;
    width: 200px;
`
export const LadoALado = styled.div`
    display: flex;
    justify-content: ${props => props.$justificar || "center"};
    align-items: center;
`
export const AreaCancelamento = styled.div`
    margin: 0 30px;
    display: inline-block;
    width: 90%;
`
export const AreaBotoes = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
`
export const InfosIngresso = styled.p`
    margin-left: 30px;
`