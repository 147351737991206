import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import ErroSpan from "../../../componentes/ErroSpan";
import { ingressosCancelados } from "../../../servicos/ticketsServices";
import { FiltrarData, InputMes, LinksPaginas, OpcoesFiltragem, PaginasTabela, RodapePaginas, SecaoAdmin, SecaoPrincipal, TotalDocs } from "../AdminStyled";
import { TabelaCancelados } from "./TabelaCancelados";

export default function Cancelados() {
    const [ingressos, setIngressos] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [ordem, setOrdem] = useState(1);
    const [ordenacao, setOrdenacao] = useState("numero");
    const [total, setTotal] = useState({ paginas: 1, docs: 0 });
    const [carregando, setCarregando] = useState(false);
    const [filtros, setFiltros] = useState({ dataInicial: new Date().toISOString().split("T")[0], dataFinal: new Date().toISOString().split("T")[0]});
    const [datas, setDatas] = useState({ dataInicial: new Date().toISOString().split("T")[0], dataFinal: new Date().toISOString().split("T")[0] });

    useEffect(() => {
        setCarregando(true);
        ingressosCancelados(filtros.dataInicial, filtros.dataFinal, pagina, ordem, ordenacao).then( resposta => {
            if (resposta.status && resposta.status === 200) setIngressos(resposta.tickets);
            setTotal({paginas: Math.ceil(resposta.totalDocs/20), docs: resposta.totalDocs});
            setCarregando(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtros, pagina, ordem, ordenacao]);
    
    function aplicarFiltros() {
        setFiltros(datas);
    }

    return (<SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Ingressos cancelados")}
            <OpcoesFiltragem $margem="15px 0 15px 40px">
                <FiltrarData $marginTop="1px">
                    <label>Data inicial</label>
                    <InputMes type="date" defaultValue={datas.dataInicial} onChange={event => setDatas({...datas, dataInicial: event.target.value})}/>
                </FiltrarData>
                <FiltrarData $marginTop="1px">
                    <label>Data final</label>
                    <InputMes type="date" defaultValue={datas.dataFinal} onChange={event => setDatas({...datas, dataFinal: event.target.value})}/>
                </FiltrarData>
                <IoMdRefresh className="Icone" onClick={() => aplicarFiltros()}/>
            </OpcoesFiltragem>
            {((carregando && <ErroSpan $corFundo="transparent" $corTexto="#252525">Carregando...</ErroSpan>) || (ingressos.length === 0 && <ErroSpan $corFundo="transparent">Nenhum ingresso encontrado!</ErroSpan>)) || <>
                {TabelaCancelados(ingressos, ordenacao, ordem, setOrdenacao, setOrdem)}
                <RodapePaginas>
                    <TotalDocs>
                        <p>Total: {total.docs}</p>
                    </TotalDocs>
                    <PaginasTabela>
                        <p onClick={() => setPagina(1)}>Primeira</p>
                        <p onClick={() => {
                            if (pagina > 1) setPagina(Number(pagina)-1);
                            }
                        }>Anterior</p>
                        {paginas()}
                        <p onClick={() => {
                            if (pagina < total.paginas) setPagina(Number(pagina)+1);
                        }}>Próxima</p>
                        <p onClick={() => setPagina(total.paginas)}>Última</p>
                    </PaginasTabela>
                </RodapePaginas></>}
        </SecaoPrincipal>
    </SecaoAdmin>)

    function paginas() {
        let pages = [];
        let ajuste = 2;
        if (pagina === 1){
            ajuste = 0;
        } else if (pagina === 2){
            ajuste = 1;
        } else if (pagina === total.paginas-1 && total.paginas > 4){
            ajuste = 3;
        } else if (pagina === total.paginas && total.paginas > 4){
            ajuste = 4;
        }
        if (total.paginas < 5){
            for (let i = 0; i < total.paginas; i++){
                let pageNum = parseInt(i+1);

                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
                let pageNum = parseInt(pagina) + parseInt(i) - ajuste;
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}