import { useEffect, useState } from "react";
import { FiltrarData, InputMes, LinksPaginas, OpcoesFiltragem, PaginasTabela, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { listarSessoes } from "../../../servicos/sessoesServices";
import { TabelaSessoes } from "./TabelaSessoes";
import { IoMdRefresh } from "react-icons/io";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import ErroSpan from "../../../componentes/ErroSpan";

export default function Sessoes(){
    const [sessoes, setSessoes] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [ordem, setOrdem] = useState(1);
    const [ordenacao, setOrdenacao] = useState("diaHora");
    const [totalPaginas, setTotalPaginas] = useState(1);
    const [carregando, setCarregando] = useState(false);
    const [filtros, setFiltros] = useState({ dataInicial: new Date().toISOString().split("T")[0], dataFinal: new Date().toISOString().split("T")[0]});
    const [datas, setDatas] = useState({ dataInicial: new Date().toISOString().split("T")[0], dataFinal: new Date().toISOString().split("T")[0] });

    useEffect( () => {
        obterSessoes(pagina, ordem, ordenacao, filtros);
    }, [pagina, ordem, ordenacao, filtros]);

    async function obterSessoes(pagina, ordem, ordenacao, filtros){
        setCarregando(true);
        listarSessoes(pagina, ordem, ordenacao, filtros).then( resposta => {
            setCarregando(false);
            if (resposta.dados) {
                setSessoes(resposta.dados);
                setTotalPaginas(Math.ceil(resposta.totalDocs/20));
            }
        });
    }
    function aplicarFiltros() {
        setFiltros(datas);
    }
    
    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Sessões")}
                <OpcoesFiltragem $margem="15px 0 15px 40px">
                    <FiltrarData $marginTop="1px">
                        <label>Data inicial</label>
                        <InputMes type="date" defaultValue={datas.dataInicial} onChange={event => setDatas({...datas, dataInicial: event.target.value})}/>
                    </FiltrarData>
                    <FiltrarData $marginTop="1px">
                        <label>Data final</label>
                        <InputMes type="date" defaultValue={datas.dataFinal} onChange={event => setDatas({...datas, dataFinal: event.target.value})}/>
                    </FiltrarData>
                    <IoMdRefresh className="Icone" onClick={() => aplicarFiltros()}/>
                </OpcoesFiltragem>
                {((!sessoes || sessoes.length === 0 || carregando) && <ErroSpan $corFundo="transparent" $corTexto="#252525">Carregando...</ErroSpan>) || <>
                    {TabelaSessoes(sessoes, ordenacao, ordem, setOrdenacao, setOrdem)}
                    <PaginasTabela>
                        <p onClick={() => setPagina(1)}>Primeira</p>
                        <p onClick={() => {
                            if (pagina > 1){
                                setPagina(pagina-1)}
                            }
                        }>Anterior</p>
                        {paginas()}
                        <p onClick={() => setPagina(pagina+1)}>Próxima</p>
                        <p onClick={() => setPagina(totalPaginas)}>Última</p>
                    </PaginasTabela>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    );

    function paginas() {
        let pages = [];
        let ajuste = 2;
    
        if (pagina === 1){
            ajuste = 0;
        } else if (pagina === 2){
            ajuste = 1;
        } else if (pagina === totalPaginas-1 && totalPaginas > 4){
            ajuste = 3;
        } else if (pagina === totalPaginas && totalPaginas > 4){
            ajuste = 4;
        }
        if (totalPaginas < 5){
            for (let i = 0; i < totalPaginas; i++){
                let pageNum = parseInt(i+1);
    
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
    
                let pageNum = parseInt(pagina) + parseInt(i) - ajuste;
    
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}
