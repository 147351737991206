import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { Chart, ArcElement, LineElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar, Line } from 'react-chartjs-2';
import CabecalhoAdmin from "../../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../../componentes/MenuLateral";
import ErroSpan from "../../../../componentes/ErroSpan";
import { AreaSuperior, FiltrarData, InputMes, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal } from "../../AdminStyled";
import { ingressosPorDia, obterTicketsBusca } from "../../../../servicos/ticketsServices";
import { AreaRelatorio, LadoALado } from "../IndicadoresStyled";
import Linha from "../../../../componentes/Linha";
import Titulo1 from "../../../../componentes/Titulo1";

Chart.register(ArcElement, LineElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Onlines() {
    const [params, setParams] = useSearchParams();
    const [datas, setDatas] = useState({ 
        dataInicial: params.get("dataInicial"),
        dataFinal: params.get("dataFinal") 
    });
    const [carregando, setCarregando] = useState(true);
    const [dadosPorDia, setDadosPorDia] = useState(null);
    const [dadosSemana, setDadosSemana] = useState(null);
    const [dadosMes, setDadosMes] = useState(null);
    const [dadosCompraDia, setDadosCompraDia] = useState(null);
    const [dadosCompraHora, setDadosCompraHora] = useState(null);
    const [dadosAntecedencia, setDadosAntecedencia] = useState(null);

    const labelsAntecedencia = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15-20",
        "21-25",
        "26-30",
        "31-40",
        "41-50",
        "51-60",
        "61-70",
        "71-80",
        "81-90"
    ];

    useEffect(() => {
        if (params.get("dataInicial") && params.get("dataFinal")) {
            setCarregando(true);
            atualizarDados().then( () => setCarregando(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    async function atualizarDados(){
        const comissoesPromessas = [ingressosPorDia(params.get("dataInicial"), params.get("dataFinal"), "PROMOCIONAL-ONLINE"), obterTicketsBusca(params.get("dataInicial"), params.get("dataFinal"), "PROMOCIONAL-ONLINE")];
        
        const dados = await Promise.all(comissoesPromessas);
        
        let dDia = new Date(params.get("dataInicial").split("-")[0], params.get("dataInicial").split("-")[1] - 1, params.get("dataInicial").split("-")[2]);
        const dados1 = {};
        const dados2 = [0, 0, 0, 0, 0, 0, 0];
        const dados3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        while (dDia <= new Date(params.get("dataFinal").split("-")[0], params.get("dataFinal").split("-")[1] - 1, params.get("dataFinal").split("-")[2])) {
            dados1[dDia.toISOString().split("T")[0]] = 0;
            dDia.setDate(dDia.getDate() + 1);
        }

        for (let i = 0; i < dados[0].ingressos.length; i++) {
            dDia = new Date(dados[0].ingressos[i].numero*1000);
            dados3[dDia.getMonth()]++;
            dados2[dDia.getDay()]++;
            dados1[dDia.toISOString().split("T")[0]]++;
        }
        setDadosPorDia({
            labels: Object.keys(dados1),
            datasets: [
                {
                    label: "Quantidade de ingressos",
                    data: Object.values(dados1),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderWidth: 1,
                }
            ]
        });
        setDadosSemana({
            labels: [
                "Domingo",
                "Segunda-feira",
                "Terça-feira",
                "Quarta-feira",
                "Quinta-feira",
                "Sexta-feira",
                "Sábado"
            ],
            datasets: [
                {
                    label: "Quantidade de ingressos",
                    data: dados2,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderWidth: 1,
                }
            ]
        });
        setDadosMes({
            labels: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            datasets: [
                {
                    label: "Quantidade de ingressos",
                    data: dados3,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderWidth: 1,
                }
            ]
        });

        const dadosCompra1 = [0, 0, 0, 0, 0, 0, 0];
        const dadosCompra2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const dadosCompra3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let dDiaUso;
        let antecedencia;

        for (let i = 0; i < dados[1].tickets.length; i++){
            dDia = new Date(dados[1].tickets[i].dataCriacao);
            dDiaUso = new Date(dados[1].tickets[i].numero*1000);
            
            antecedencia = (new Date(dDiaUso.getFullYear(), dDiaUso.getMonth(), dDiaUso.getDate()).getTime() - new Date(dDia.getFullYear(), dDia.getMonth(), dDia.getDate()).getTime()) / (1000*3600*24);

            if (antecedencia > 80) {
                antecedencia = 23;
            } else if (antecedencia > 70) {
                antecedencia = 22;
            } else if (antecedencia > 60) {
                antecedencia = 21;
            } else if (antecedencia > 50) {
                antecedencia = 20;
            } else if (antecedencia > 40) {
                antecedencia = 19;
            } else if (antecedencia > 30) {
                antecedencia = 18;
            } else if (antecedencia > 25) {
                antecedencia = 17;
            } else if (antecedencia > 20) {
                antecedencia = 16;
            } else if (antecedencia > 15) {
                antecedencia = 15;
            }

            dadosCompra1[dDia.getDay()]++;
            dadosCompra2[dDia.getHours()]++;
            dadosCompra3[antecedencia]++;
        }

        setDadosCompraDia({
            labels: [
                "Domingo",
                "Segunda-feira",
                "Terça-feira",
                "Quarta-feira",
                "Quinta-feira",
                "Sexta-feira",
                "Sábado"
            ],
            datasets: [
                {
                    label: "Quantidade de ingressos",
                    data: dadosCompra1,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderWidth: 1,
                }
            ]
        });
        setDadosCompraHora({
            labels: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00"
            ],
            datasets: [
                {
                    label: "Quantidade de ingressos",
                    data: dadosCompra2,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderWidth: 1,
                }
            ]
        });
        setDadosAntecedencia({
            labels: labelsAntecedencia,
            datasets: [
                {
                    label: "Quantidade de ingressos",
                    data: dadosCompra3,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderWidth: 1,
                    tension: 0.4
                }
            ]
        });
    }

    function atualizarParametros() {
        setParams(params => {
            params.set("dataInicial", datas.dataInicial);
            params.set("dataFinal", datas.dataFinal);
            return params;
        });
    }

    return (<SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Indicadores de ingressos online")}
            <AreaSuperior>
                <OpcoesFiltragem>
                    <FiltrarData $marginTop="1px">
                        <label>Data inicial</label>
                        <InputMes type="date" defaultValue={params.get("dataInicial")} onChange={event => setDatas({...datas, dataInicial: event.target.value})}/>
                    </FiltrarData>
                    <FiltrarData $marginTop="1px">
                        <label>Data final</label>
                        <InputMes type="date" defaultValue={params.get("dataFinal")} onChange={event => setDatas({...datas, dataFinal: event.target.value})}/>
                    </FiltrarData>
                    <IoMdRefresh className="Icone" onClick={() => atualizarParametros()}/>
                </OpcoesFiltragem>
            </AreaSuperior>
            {carregando && <ErroSpan>Carregando...</ErroSpan>}
            <LadoALado $margem="10px 5px 10px 5px">
                {dadosSemana && <AreaRelatorio $maxLargura="38%" $altura="250px">
                    <Bar data={dadosSemana} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos online na semana",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            }
                        }
                    }}/>
                </AreaRelatorio>}
                {dadosMes && <AreaRelatorio $maxLargura="60%" $altura="250px">
                    <Bar data={dadosMes} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos online por mês",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                        }
                    }}/>
                </AreaRelatorio>}
            </LadoALado>
            {dadosPorDia && <AreaRelatorio $maxLargura="95%">
                <Bar data={dadosPorDia} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Ingressos online por dia",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                            datalabels: {
                                display: false
                            }
                        }
                    }}/>
            </AreaRelatorio>}
            <Linha $margem="40px 20px 10px 20px"/>
            <Titulo1 $margem="0 0 10px 20px">Análise do comportamento de compra</Titulo1>
            <LadoALado $margem="10px 5px 0px 5px">
                {dadosCompraDia && <AreaRelatorio $maxLargura="30%" $altura="350px">
                    <Bar data={dadosCompraDia} options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: "Compra de ingressos por dia",
                                    font: {
                                        size: "16px"
                                    },
                                    color: '#252525'
                                }
                            }
                        }}/>
                </AreaRelatorio>}
                {dadosAntecedencia && <AreaRelatorio $maxLargura="68%" $altura="350px">
                    <Line data={dadosAntecedencia} options={{
                        plugins: {
                            legend: {
                                position: 'top',
                                display: false 
                            },
                            title: {
                                display: true,
                                text: "Antecedência na compra",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                            datalabels: {
                                display: false
                            }
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Dias de antecedência"
                                }
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: "Quantidade"
                                }
                            }
                        }
                    }} />
                </AreaRelatorio>}
            </LadoALado>
            {dadosCompraHora && <AreaRelatorio $maxLargura="95%">
                <Bar data={dadosCompraHora} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: "Compra de ingressos por hora",
                                font: {
                                    size: "16px"
                                },
                                color: '#252525'
                            },
                        }
                    }}/>
            </AreaRelatorio>}
        </SecaoPrincipal>
    </SecaoAdmin>)
}