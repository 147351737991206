import Cookies from "js-cookie";

export async function buscaSessao(dia = "2023-01-01"){
    try {
        const resposta = await fetch(`/api/sessoes?dia=${dia}&limite=100`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function recalcularLugaresSessao(sessao) {
    try {
        const resposta = await fetch("/api/sessoes/recalcular", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                _id: sessao
            })
        });

        return await resposta.json();
    } catch (e) {
        return { status: 400, mensagem: e.message }
    }
}

export async function zerarLugaresSessao(sessao, justificativa) {
    try {
        if (!justificativa) return { status: 400, mensagem: "Informe a justificativa" }
        const resposta = await fetch("/api/sessoes", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                filtros: {
                    _id: sessao
                },
                atualizacao: {
                    ticketsDisponiveis: 0,
                    observacao: justificativa
                }
            })
        });
        return await resposta.json();
    } catch (e) {
        return { status: 400, mensagem: e.message }
    }
}

export async function listarSessoes(pagina = "1", ordem = 1, ordenacao = "diaHora", filtros){
    try {
        let query = `?pagina=${pagina}&ordem=${ordem}&ordenacao=${ordenacao}`;
        if (filtros.dataInicial) query += `&dataInicial=${filtros.dataInicial}`;
        if (filtros.dataFinal) query += `&dataFinal=${filtros.dataFinal}`;
        const resposta = await fetch(`/api/sessoes${query}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function adicionarTicketsExtra(sessao){
    try {
        const resposta = await fetch("/api/sessoes/adicionar-lugares", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                sessao: sessao,
                quantidade: 2
            })
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return {status: 500};
    }
}

export async function obterSessaoPorId(id){
    try {
        const resposta = await fetch(`/api/sessoes/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}