import { useParams } from "react-router-dom";
import { ColunaTabela, LinhaTabela, SecaoAdmin, SecaoPrincipal, Tabela, TituloColunas } from "../AdminStyled";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import MenuLateral from "../../../componentes/MenuLateral";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import { useEffect, useState } from "react";
import { getComissoesGuia } from "../../../servicos/comissoesServices";

export default function HistoricoGuia() {
    const [comissoes, setComissoes] = useState(null);
    // const [paginaAtual, setPaginaAtual] = useState(1);
    const [ordenacaoAtual, setOrdenacaoAtual] = useState("dataPagamento");
    const [ordemAtual, setOrdemAtual] = useState(1);
    // const [totalPaginas, setTotalPaginas] = useState(1);
    const { id } = useParams();
    
    useEffect(() => {
        obterComissoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function obterComissoes(){
        const resposta = await getComissoesGuia(id);
        if (resposta && resposta.dados) setComissoes(resposta.dados);
    }
    
    const campos = [
        {chave: "dataPagamento", label: "Data pagamento"},
        {chave: "comissaoTotal", label: "Valor"},
        {chave: "pagamentoEfetivado", label: "Efetivado"},
        {chave: "mensagem", label: "Mensagem"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }
    let total = 0;
    return <SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Histórico guia")}
            {comissoes && <Tabela style={{ justifyContent: "center", textAlign: "center" }}>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                    </LinhaTabela>
                </thead>
                <tbody>
                    {comissoes.map((comissao, index) => {
                        total += comissao.comissaoTotal;
                        return (
                        <LinhaTabela key={index}> 
                            <ColunaTabela>{comissao.dataPagamento.slice(8, 10)}/{comissao.dataPagamento.slice(5, 7)}/{comissao.dataPagamento.slice(0, 4)}</ColunaTabela>
                            <ColunaTabela>R$ {comissao.comissaoTotal.toFixed(2).replace(".", ",")}</ColunaTabela>
                            <ColunaTabela>{comissao.pagamentoEfetivado? "Sim" : "Não"}</ColunaTabela>
                            <ColunaTabela>{comissao.mensagem}</ColunaTabela>
                        </LinhaTabela>
                    )})}
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525">Total</ColunaTabela>
                        <ColunaTabela $cor="#252525">R$ {total.toFixed(2).replace(".", ",")}</ColunaTabela>
                    </LinhaTabela>
                </tbody>
            </Tabela>}
        </SecaoPrincipal>
    </SecaoAdmin>
}