import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usuarioAtual } from "../servicos/usuariosServices";
import Cookies from "js-cookie";

export const UsuarioContext = createContext();

export const UsuarioProvider = ({ children }) => {
    const [ usuario, setUsuario ] = useState(null);

    return (
        <UsuarioContext.Provider value={{ usuario, setUsuario }}>
            {children}
        </UsuarioContext.Provider>
    )
}

export const useUsuarioContext = () => {
    const { usuario, setUsuario } = useContext(UsuarioContext);
    const navigate = useNavigate();

    async function atualizarUsuario(){
        const resposta = await usuarioAtual();
        if (!resposta.status || resposta.status !== 200) {
            Cookies.remove("token_tour");
            navigate("/login", { state: { redirecionar: window.location.pathname + window.location.search }});
        } else {
            if (window.location.pathname.split("/")[1] === "admin" && resposta.permissoes > 1) {
                Cookies.remove("token_tour");
                navigate("/login", { state: { redirecionar: window.location.pathname + window.location.search }}); 
            } else setUsuario({ nome: resposta.nome, email: resposta.email, id: resposta.id});
        }
    }

    return {
        usuario,
        setUsuario,
        atualizarUsuario
    }
}