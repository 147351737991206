import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import { jsPDF } from "jspdf";
import Select from "react-select";
import QRCode from "qrcode";
import Titulo1 from "../../componentes/Titulo1.jsx";
import Linha from "../../componentes/Linha.jsx";
import { listarGuias } from "../../servicos/guiasServices.js";
import { criarTickets } from "../../servicos/ticketsServices.js";
import { adicionarTicketsExtra, buscaSessao } from "../../servicos/sessoesServices.js";
import { AreaBotoesTickets, AreaNaoImprimiu, BotaoNaoImprimiu, BotaoSalvar, DivTickets, InputsTickets, LabelsTickets, LadoALado, ListaSessoes, MenuLateral, PaginaTickets, SecaoPrincipal, Texto } from "./TicketsStyled.jsx";

export default function CriarTickets() {
    const navigate = useNavigate();
    const [sessoes, setSessoes] = useState([]);
    const [reimprimir, setReimprimir] = useState(false);
    const [dia, setDia] = useState(new Date().toISOString().split("T")[0])
    const [dados, setDados] = useState({
        infantis: "0",
        cortesias: "0",
        promocionaisExcursao: "0",
        promocionaisEscolar: "0",
        visitante: {nome: "", email: "", celular: ""},
        motivoCortesia: ""
    });
    const [guias, setGuias] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const motivoCort = useRef(null);

    useEffect(() => {
        obterSessoes(dia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dia]);

    async function obterSessoes(dia) {
        buscaSessao(dia).then(resposta => {
            if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
            let listagemSessoes = [];
            resposta.dados.map( sessao => {
                return listagemSessoes.push({
                    value: sessao._id,
                    label: sessao.diaHora.slice(11,16),
                    ticketsDisponiveis: sessao.ticketsDisponiveis,
                    diaHora: sessao.diaHora,
                    extrasAdicionados: sessao.extrasAdicionados
                });
            });
            setSessoes(listagemSessoes);
            setDados({sessao: listagemSessoes.find( s => s.ticketsDisponiveis > 0), motivoCortesia: "", horarioEspecial: dados.horarioEspecial, guia: dados.guia || "", promocionaisExcursao: "0", promocionaisEscolar: "0", infantis: "0", cortesias: "0", visitante: {nome: "", email: "", celular: ""},
            segundaChamada: true});
        });
        listarGuias().then(resposta => {
            if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
            let listagemGuias = [];
            resposta.map(guia => {
                return listagemGuias.push({
                    value: guia._id,
                    label: guia.nome.toString()
                })
            });
            setGuias(listagemGuias);
        });
    }

    async function salvar(){
        if (Number(dados.cortesias + dados.infantis + dados.promocionaisExcursao + dados.promocionaisEscolar) === 0) return;
        if (Number(dados.cortesias) > 0 && !dados.motivoCortesia) return motivoCort.current.focus();
        setCarregando(true);
        try {
            criarTickets({ ...dados, sessao: dados.sessao.value }).then(async resposta => {
                let tesoura = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABuwAAAbsBOuzj4gAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATPSURBVHic7dtbiFVVGAfw3zqZF8xQSJNCwR7UwBTKIoSCtAsGRT6FSQ+9CGFQYlZPBRH0IN2gHoIgiEykIMKokGi6kERjkTes7ALmQFmWkuVoOquHvaeOx73P7LPPdsZx+4fvYdas9X3f/ztrrW/ddogxqjMaI+3ASKP2ARgzVIUQQgMLMRbHU/kHO2OMJ06ve8OAGGOm4DI8jr2IGfIj1mByno7RIFnEp+AdDOQQb5U/8TwuHGkyZSQ0Z4EQwjRsxoISnWkLbokxHi7RdsTw3yQYQrgUHytHHhZhUwhhQhWODRdCjFHq9C7MqkDnZiyNMQ5UoOu0YzAL3C2f/K94Ab3Yg8txLe7DpIz6N+NWvF2ppx0ghDAdyzBB0ssb+BwfxdaVX/r3LtkT3JuYmpMlZuKDnHbvjciMzjV4Fcdy/NqDR3BRUxs35VTuxZghDE7EdxltBzB7GIlPwcYcHlnyA2YNBuCNjAonMK+g8cU5Rp4cJvI3Yl8H5AdlH+bCJxn/3N2hE79n6Hj5NBMfj2cUX69kycYGznMqtmaUtcMXGWXjO9RRGCGEBZIh+gBCCRXHsBbLx8jeD3S6xs+qP65Tr4ZCui9Zgycke5My2IkVMcbtgwVfOrVr7OqwO/6WoeOtirv8TPRk2CkqA3ga41r0eimj8gnMKejYdTkG76+Q/Aoc7IL8T1iSo9uinEafojGEY+OwOyeA0ysgPhkbuiAe0/ZTcm2khr7OafyKnO0upmNTTrv3KyC/OP3lyhI/KBnr7e2kxh5qo6gPD6cOzZAsdR/FgTZt1nZBfBye0l1668HMQvZSoxfgsy67WrP0S2br0CH5K7C9C7tH8aAhhu4pAUiNT8FXFQYhSvYKMwoQD2nA+ruwtQPzO+5xLY5Mkz2pdSN/4K425GfI31QVkcz0VioAqUOXSDYW3YzBLHlNy4SK5WmAyurMTW+lA9Dk3Hy8jkM5xvdJ1uLL8X0HDi+WpLf1XQa0bXorKiedCWYhXX7OwZWS/H4Av2BHHBzAIUzCc7inrbIEUbJynFqgbhYOYVWMcX3J9i3edBnBll6zTHKCVOXQaZYeBdNb10OgiyBMx7sVE+84vY1YAJoCsQp/V0C+VHob8QCkQZgrOVsoQ7yr9HZGBCANwvmS/fvxDsh3nd6KSu1vh0/3rz9HcnRVvyGAe/FXSfKjdxLExZJbofqlQdyO/RWTb5YeFS+EqloKT0zH68q2yhJEo2EpbOjNUB+exZ34NqdOVno7ozZDWcTrux1W5wMRdT4SU/dDUeeOxWt8MaLuV2POXY7W+3q8ITmoaEVvRlk7ZL0oqfQhdYxxL5ZIJuxjJVQErMbWEML8wcK8AGQ9m2mHrPpHO9QxJGKMAzHGdZLncDtLqpmH3hDCgyGERkP2L7WwQ6VXZZT1d+xaQcQYt+FqyV4kllAxFuuwoSHZ2bVidghhXhFNIYTFklVkK34u4VhhxBj7Y4yrJeuSvhIq+vAYdX8omSqo7VPZwdfiK/FiTlfp9LE03BZjHBWPpWv/XL4BMcYjkhXdN13q+xB3jBbyNH0xEmPsw/XYVlLXFknXP1KFY8OFk26GYoz7cYP/b3eL4LBkjlgaR9n3QtBuRq3FZ3NFj8XP2g8nhwzA2Y7a3w7XPgD/ArwuhcM+YPXAAAAAAElFTkSuQmCC";
                const doc = new jsPDF();
                let yMax;
                doc.setFont(undefined, "bold");
                doc.setFontSize(15);
                doc.text("Estes são seus ingressos para o Tour Nugali Chocolates!", 105, 13, "center");
                doc.setFontSize(20).text(`Dia ${dados.sessao.diaHora.slice(8,10)}/${dados.sessao.diaHora.slice(5,7)}/${dados.sessao.diaHora.slice(0,4)} às ${dados.sessao.label}`, 105, 23, "center");
                doc.setFontSize(12).text("Recorte os ingressos e distribua para os usuários.", 105, 30, "center");
                doc.text("Aproxime o Código QR nos leitores das catracas no horário indicado.", 105, 37, "center");
                doc.text("O não comparecimento na data e horário agendados resultará na perda destes ingressos.", 105, 44, "center");
                
                // Adição dos ingressos no documento
                for (let i = 0; i < resposta.tickets.length; i ++) {
                    let qrcode = await QRCode.toDataURL(resposta.tickets[i].numero.toString());
                    let x = 15 + 50*(i%4);
                    let y = 52 + 60*Math.floor(i/4);
                    let tipo = resposta.tickets[i].tipo.split("-")[0];
                    let ajuste;

                    switch (tipo.length) {
                        case "PROMOCIONAL":
                            ajuste = 14;
                            break;
                        case "INFANTIL":
                            ajuste = 18;
                            break;
                        default:
                            ajuste = 16;
                            break;
                    }

                    doc.addImage(qrcode, 'png', x, y, 32, 32);     // x, y, largura, altura
                    doc.setFontSize(12).text(
                        tipo, 
                        x + ajuste, 
                        y + 33, 
                        "center"
                    );
                    doc.text(
                        `${dados.sessao.diaHora.slice(8,10)}/${dados.sessao.diaHora.slice(5,7)}/${dados.sessao.diaHora.slice(0,4)}  ${dados.sessao.label}`, 
                        x + 15, 
                        y + 48, 
                        "center"
                    );
                    doc.setFontSize(8).text("ACESSO ÚNICO E INDIVIDUAL", x + 16, y + 40, "center");
                }
                doc.setLineDash([4, 2], 0);  // Create dashed line [dash length, gap length]

                // Linhas horizontais
                doc.line(2, 50, 210, 50);
                doc.addImage(tesoura, "png", 10, 47, 6, 6);
                for (let i = 0; i < Math.ceil(resposta.tickets.length/4); i++) {
                    let y = 110 + 60 * i; 
                    yMax = y;
                    doc.line(2, y, 210, y);   // Draw the line under the ticket (adjust coordinates as needed)
                
                    doc.addImage(tesoura, "png", 10, y-3, 6, 6);
                }

                // Linhas verticais
                for (let i = 0; i < 3; i++) {
                    doc.line(50*i + 56, 50, 50*i + 56, yMax);
                }

                setReimprimir(doc);
                let iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                iframe.style.display = "none";
                iframe.src = doc.output("bloburl");
                iframe.onload = () => {
                    iframe.contentWindow.print();
                };
                obterSessoes(dia);
                setCarregando(false);
            });
        } catch (e) {
            console.log(e.message);
        }
    }

    function naoImprimiu(){
        let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = reimprimir.output("bloburl");
            iframe.onload = () => {
                iframe.contentWindow.print();
        }
    }

    async function adicionarExtras(){
        adicionarTicketsExtra(dados.sessao.value);
        setDados({...dados, sessao: {...dados.sessao, ticketsDisponiveis: dados.sessao.ticketsDisponiveis + 2}});
    }

    if (sessoes.length === 0 || guias.length === 0) {
        return (<span>Carregando</span>);
    } else {
        let fundo = "transparent";
        return (<PaginaTickets>
            <MenuLateral>
                <Titulo1 $alinhamento="center" $cor="var(--corNugaliClara)" $corFundo="transparent" $tamanho="1.5em">
                    Sessões e Lugares Criar
                </Titulo1>
                {sessoes.slice(0, -7).map(sessao => {
                    fundo = fundo === "transparent"? "#ac6c5c5f": "transparent";
                    return (
                        <ListaSessoes $vazio={sessao.ticketsDisponiveis === 0} key={sessao.value} $corFundo={fundo}>{sessao.label} : {sessao.ticketsDisponiveis}</ListaSessoes>
                    )
                })}
            </MenuLateral>
            <SecaoPrincipal>
                <Texto $margem="20px 10px 20px 10px" $negrito="bold" $tamanho="30px">{`${dados.sessao.ticketsDisponiveis} lugares disponíveis na sessão escolhida`}</Texto>
                <Linha />
                <DivTickets $margem="15px 10px 10px 50px">
                    <LabelsTickets $marginRight="10px">Dia</LabelsTickets>
                    <InputsTickets $largura="200px" type="date" onChange={event => setDia(event.target.value)} value={dia}/>
                </DivTickets>
                <LadoALado>
                    <DivTickets $largura="400px" $margem="15px 10px 15px 50px">
                        <LabelsTickets>Sessão</LabelsTickets>
                        <Select 
                            options={sessoes} 
                            value={dados.sessao}
                            onChange={(e) => setDados({...dados, sessao: e})}
                            placeholder=""
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    margin: 0,
                                    border: "none",
                                    padding: "2px",
                                    borderRadius: 0,
                                    borderBottom: "2px solid #3a170e",
                                    fontSize: "24px",
                                    textAlign: "center",
                                    borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                }),
                                option: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "black",
                                    textAlign: "center",
                                    fontSize: "20px"
                                }),
                                singleValue: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "black"
                                })
                            }}
                        />
                    </DivTickets>
                    <DivTickets $margem="15px 50px 15px 10px">
                        <LabelsTickets>Guia</LabelsTickets>
                        <Select 
                            options={guias} 
                            isClearable
                            onChange={(e) => {
                                if(e) setDados({...dados, guia: e.value}); else setDados(atual => {
                                    const {guia, ...resto} = atual;
                                    return resto;
                                })}
                            }
                            placeholder=""
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    margin: 0,
                                    border: "none",
                                    padding: "2px",
                                    borderRadius: 0,
                                    borderBottom: "2px solid #3a170e",
                                    fontSize: "24px",
                                    borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                }),
                                option: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "black",
                                    fontSize: "20px"
                                }),
                                singleValue: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "black"
                                })
                            }}
                        />
                    </DivTickets>
                </LadoALado>
                <LadoALado>
                    <DivTickets $margem="15px 10px 30px 50px">
                        <LabelsTickets>Promocional Excursão</LabelsTickets>
                        <InputsTickets type="number" onChange={event => setDados({...dados, promocionaisExcursao: event.target.value})} value={dados.promocionaisExcursao} min={0} max={dados.sessao?.ticketsDisponiveis - dados.cortesias - dados.infantis - dados.promocionaisEscolar || 0}/>
                    </DivTickets>
                    <DivTickets $margem="15px 10px 30px 10px">
                        <LabelsTickets>Promocional Escolar</LabelsTickets>
                        <InputsTickets type="number" onChange={event => setDados({...dados, promocionaisEscolar: event.target.value})} value={dados.promocionaisEscolar} min={0} max={dados.sessao?.ticketsDisponiveis - dados.cortesias - dados.infantis - dados.promocionaisExcursao || 0}/>
                    </DivTickets>
                    <DivTickets $margem="15px 10px 30px 10px">
                        <LabelsTickets>Infantil</LabelsTickets>
                        <InputsTickets type="number" onChange={event => setDados({...dados, infantis: event.target.value})} value={dados.infantis} min={0} max={dados.sessao?.ticketsDisponiveis - dados.cortesias - dados.promocionaisExcursao - dados.promocionaisEscolar || 0}/>
                    </DivTickets>
                    <DivTickets $margem="15px 50px 30px 10px">
                        <LabelsTickets>Cortesia</LabelsTickets>
                        <InputsTickets type="number" onChange={event => setDados({...dados, cortesias: event.target.value})} value={dados.cortesias} min={0} max={dados.sessao?.ticketsDisponiveis - dados.promocionaisExcursao - dados.infantis - dados.promocionaisEscolar || 0}/>
                    </DivTickets>
                </LadoALado>
                <DivTickets $esconder={Number(dados.cortesias) === 0} $margem="0px 100px 30px 50px" $pad="0 100px 0 0">
                    <LabelsTickets>Motivo da cortesia</LabelsTickets>
                    <InputsTickets ref={motivoCort} value={dados.motivoCortesia} onChange={(event => setDados({...dados, motivoCortesia: event.target.value}))}/>
                </DivTickets>
                <Linha />
                <LadoALado>
                    <DivTickets $margem="15px 10px 30px 50px">
                        <LabelsTickets>Nome</LabelsTickets>
                        <InputsTickets onChange={(e) => setDados({...dados, visitante: {...dados.visitante, nome: e.target.value}})} value={dados.visitante.nome} />
                    </DivTickets>
                    <DivTickets $margem="15px 10px 30px 10px">
                        <LabelsTickets>E-mail</LabelsTickets>
                        <InputsTickets onChange={(e) => setDados({...dados, visitante: {...dados.visitante, email: e.target.value}})} value={dados.visitante.email} />
                    </DivTickets>
                    <DivTickets $margem="15px 50px 30px 10px" $largura="80%">
                        <LabelsTickets>Celular</LabelsTickets>
                        <InputMask onInput={event => setDados({...dados, visitante: {...dados.visitante, celular: event.target.value}})} value={dados.visitante.celular} component={InputsTickets} mask="(__) _____-____" replacement={{ _: /\d/ }}/>
                    </DivTickets>
                </LadoALado>
                <AreaBotoesTickets>
                    <AreaNaoImprimiu>
                        <BotaoNaoImprimiu disabled={!reimprimir} onClick={() => naoImprimiu()}>Não imprimiu</BotaoNaoImprimiu>
                        <BotaoNaoImprimiu disabled={dados.sessao.extrasAdicionados} onClick={() => adicionarExtras()}>Adicionar Tickets extra</BotaoNaoImprimiu>
                    </AreaNaoImprimiu>
                    <BotaoSalvar disabled={carregando} onClick={() => salvar()}>SALVAR</BotaoSalvar>
                </AreaBotoesTickets>
            </SecaoPrincipal>
        </PaginaTickets>)
    }
}