import Cookies from "js-cookie";
import QRCode from "qrcode";
import { jsPDF } from "jspdf";

export async function imprimirIngresso(numero, tipo, sessao) {
    try {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [90, 50.8]
        });
        doc.setFont(undefined, "bold");
        let pos = 0;
        if (tipo === "PROMOCIONAL-ONLINE") pos = 45; else
        if (tipo === "INFANTIL-ONLINE") pos = 40; else
        if (tipo === "PROMOCIONAL-EXCURSÃO") pos = 47; else
        if (tipo === "PROMOCIONAL-ESCOLAR") pos = 46; else
        if (tipo === "PROMOCIONAL") pos = 38; else 
        if (tipo === "CORTESIA") pos = 35; else pos = 33;
        doc.setFontSize(9)
            .text("ACESSO ÚNICO E INDIVIDUAL", 12, 49, null, 90)
            .text(tipo, 17, pos, null, 90);
        doc.setFontSize(8)
            .text("NÃO REEMBOLSÁVEL", 23, 41, null, 90)
            .text("O não comparecimento na sessão", 61, 49, null, 90)
            .text("implica na perda do ingresso", 65, 46, null, 90);
        doc.setFontSize(18).text(sessao.diaHora.split("T")[1].slice(0,5), 75, 35, null, 90);
        doc.setFontSize(12).text(`${new Date(sessao.diaHora).toLocaleDateString('en-GB')}`, 82, 37, null, 90);
        let qrcode = await QRCode.toDataURL(numero.toString());
        doc.addImage(qrcode, 'png', 28, 13, 27, 27);
        
        let iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = "none";
        iframe.src = doc.output("bloburl");
        iframe.onload = () => {
            iframe.contentWindow.print();
        };
    } catch (e) {
        console.log(e.message);
    }
}

export async function listarTickets(busca, filtros, pagina, ordenacao, ordem) {
    try {
        let query = `?pagina=${pagina}&ordenacao=${ordenacao}&ordem=${ordem}`;
        if (filtros.tipo) {
            query += "&tipo="; 
            filtros.tipo.forEach( tipo => {
                query += `${tipo.label},`
            });
            query = query.slice(0, -1);
        }
        if (filtros.dataInicial) query += `&dataInicial=${filtros.dataInicial}`;
        if (filtros.dataFinal) query += `&dataFinal=${filtros.dataFinal}`;
        if (busca) query += `&pesquisa=${busca}`;

        const resposta = await fetch(`/api/tickets${query}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function ticketsRelatorios(dataInicial, dataFinal) {
    try {
        const resposta = await fetch(`api/tickets-relatorios?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, {
            meethod: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function criarTickets(dados) {
    try {
        const resposta = await fetch("/api/tickets/rapido", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(dados)
        });
        return await resposta.json();

    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}

export async function getTicket(numero) {
    try {
        const resposta = await fetch(`/api/tickets/busca?numero=${numero}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}

export async function cancelarIngresso(numero, motivo) {
    try {
        if (!motivo) return {status: 500, mensagem: "O motivo é obrigatório!"};
        const resposta = await fetch(`/api/tickets/cancelar/${numero}`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                motivo: motivo
            })
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return {status: 500, mensagem: e.message};
    }
}

export async function obterTicketsSessao(sessao) {
    try {
        const resposta = await fetch(`/api/tickets/busca?sessao=${sessao}&limite=99`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function obterTicketsHoje() {
    try {
        const resposta = await fetch(`/api/tickets/busca?criacaoDataInicial=${new Date().toISOString().split("T")[0]}&criacaoDataFinal=${new Date().toISOString().split("T")[0]}&cancelado=nao&limite=5000`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterTicketsVisitante(visitante) {
    try {
        const resposta = await fetch(`/api/tickets/busca?visitante=${visitante}&limite=999`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function atualizaEtapa(ticket, etapa) {
    try {
        const resposta = await fetch("/api/tickets/etapa-foto", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                numero: ticket,
                etapa: etapa
            })
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}

export async function deletarTicket(ticket) {
    try {
        const resposta = await fetch(`/api/tickets/deletar/${ticket}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function deletarTickets(tickets) {
    try {
        const resposta = await fetch("/api/tickets", {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(tickets)
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function ticketsComGuia(dataInicial, dataFinal, tipo, guia) {
    try {
        let query = "?";
        if (dataInicial) query += `dataInicial=${dataInicial}`; else return null;
        if (dataFinal) query += `&dataFinal=${dataFinal}`; else return null;
        if (tipo) query += `&tipo=${tipo}`;
        if (guia) query += `&guia=${guia}`;
        const resposta = await fetch(`/api/tickets/guias${query}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterTicketsBusca(dataInicial, dataFinal, tipo) {
    try {
        let query = "?limite=100000";
        if (dataInicial) query += `criacaoDataInicial=${dataInicial}`; else return null;
        if (dataFinal) query += `&criacaoDataFinal=${dataFinal}`; else return null;
        if (tipo) query += `&tipo=${tipo}`;
        const resposta = await fetch(`/api/tickets/busca${query}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterTicketPorId(id) {
    try {
        const resposta = await fetch(`/api/tickets/id/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function totaisPorTipo(dataInicial, dataFinal) {
    try {
        const resposta = await fetch(`/api/tickets/totais-tipo?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function ingressosCancelados(dataInicial, dataFinal, pagina, ordem, ordenacao) {
    try {
        const resposta = await fetch(`/api/tickets/busca?cancelado=sim&dataInicial=${dataInicial}&dataFinal=${dataFinal}&ordem=${ordem}&ordenacao=${ordenacao}&pagina=${pagina}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function ingressosPorDia(dataInicial, dataFinal, tipo) {
    try {
        let query = "?";
        if (dataInicial) query += `dataInicial=${dataInicial}`; else return null;
        if (dataFinal) query += `&dataFinal=${dataFinal}`; else return null;
        if (tipo) query += `&tipo=${tipo}`;

        const resposta = await fetch(`/api/tickets/por-dia${query}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}