import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
    margin-bottom: 20px;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    border-top: ${props => props.$linha || "none"};
    height: 30px;
    color: ${props => props.$cor || "inherit" };

    td {
        padding-top: ${props => props.$espaco || "none"};
        padding-bottom: ${props => props.$espaco || "none"};
    }
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
`
export function TabelaComissoes(comissoes, totais, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual, setOrdenou) {
    try {
        const campos = [
            {chave: "guia", label: "Guia"},
            {chave: "comissaoTour", label: "Comissão Tour"},
            {chave: "comissaoLojaTour", label: "Comissão Loja Fábrica"},
            {chave: "comissaoLojaCentro", label: "Comissão Loja Centro"},
            {chave: "total", label: "Total"}
        ];

        function atualizaOrdemOrdenacao(ordenacao){
            if (ordenacaoAtual === ordenacao){
                setOrdemAtual(ordemAtual === 1? -1 : 1);
            } else {
                setOrdenacaoAtual(ordenacao);
                setOrdemAtual(1);
            }
            setOrdenou(false);
        }

        return (
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                    </LinhaTabela>
                </thead>
                <tbody>
                    {comissoes.map((comissao, index) => {
                        let comTour = comissao.comissaoTour.toFixed(2).split(".");
                        let comLojaT = comissao.comissaoLojaTour.toFixed(2).split(".");
                        let comLojaC = comissao.comissaoLojaCentro.toFixed(2).split(".");
                        let comTot = comissao.comissaoTotal.toFixed(2).split(".");
                        return (
                        <LinhaTabela key={comissao.guia.codigo || index}> 
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit", cursor: "pointer" }} to={`/admin/guias/${comissao.guia._id}`}>{comissao.guia.nome}</Link></ColunaTabela>
                            <ColunaTabela>R$ {comTour[0]},{comTour[1]}</ColunaTabela>
                            <ColunaTabela>R$ {comLojaT[0]},{comLojaT[1]}</ColunaTabela>
                            <ColunaTabela>R$ {comLojaC[0]},{comLojaC[1]}</ColunaTabela>
                            <ColunaTabela>R$ {comTot[0]},{comTot[1]}</ColunaTabela>
                        </LinhaTabela>
                    )})}
                    <LinhaTabela $cor="#007396" $linha={"1px solid lightgray"} $espaco={"10px"}>
                        <ColunaTabela>Totais</ColunaTabela>
                        <ColunaTabela>R$ {totais.totalTour.split(".")[0]},{totais.totalTour.split(".")[1]}</ColunaTabela>
                        <ColunaTabela>R$ {totais.totalLojaTour.split(".")[0]},{totais.totalLojaTour.split(".")[1]}</ColunaTabela>
                        <ColunaTabela>R$ {totais.totalLojaCentro.split(".")[0]},{totais.totalLojaCentro.split(".")[1]}</ColunaTabela>
                        <ColunaTabela>R$ {totais.totalTotal.split(".")[0]},{totais.totalTotal.split(".")[1]}</ColunaTabela>
                    </LinhaTabela>
                </tbody>
            </Tabela>
        )
    } catch (e) {
        return <></>
    }
}