import { useEffect, useState } from "react";
import Select from 'react-select';
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { criarGuia, getUltimoCodigoGuia } from "../../../servicos/guiasServices";
import { AreaSuperior, ColunaTabela, LinhaTabela, MarkObrigatorio, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal, Tabela } from "../AdminStyled";
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import guiaSchema from "../../../schemas/guiaSchema";
import ErroSpan from "../../../componentes/ErroSpan";
import { Link } from "react-router-dom";
import Botao from "../../../componentes/Botao";
import Titulo1 from "../../../componentes/Titulo1";

const opcoesChavePix = [
    { value: "cpfcnpj", label: "CPF/CNPJ" },
    { value: "email", label: "E-mail" },
    { value: "celular", label: "Celular"},
    { value: "chaveAleatoria", label: "Chave Aleatória"}
];

const opcoesEstados = [
    { value: "ac", label: "AC" },
    { value: "al", label: "AL" },
    { value: "am", label: "AM" },
    { value: "ap", label: "AP" },
    { value: "ba", label: "BA" },
    { value: "ce", label: "CE" },
    { value: "df", label: "DF" },
    { value: "es", label: "ES" },
    { value: "go", label: "GO" },
    { value: "ma", label: "MA" },
    { value: "mg", label: "MG" },
    { value: "mt", label: "MT" },
    { value: "ms", label: "MS" },
    { value: "pa", label: "PA" },
    { value: "pb", label: "PB" },
    { value: "pe", label: "PE" },
    { value: "pi", label: "PI" },
    { value: "pr", label: "PR" },
    { value: "rj", label: "RJ" },
    { value: "rn", label: "RN" },
    { value: "ro", label: "RO" },
    { value: "rr", label: "RR" },
    { value: "rs", label: "RS" },
    { value: "sc", label: "SC" },
    { value: "sp", label: "SP" },
    { value: "se", label: "SE" },
    { value: "to", label: "TO" }
]

const camposForm = [    
    // [Nome mostrado,  key,  obrigatório,  tipo,  opções select,  placeholder select]
    ["Nome", "nome", true, "text"],
    ["Empresa", "empresa", false, "text"],
    ["Cadastur", "cadastur", false, "text"],
    ["Cidade", "cidade", true, "text"],
    ["Estado", "estado", true, "text", opcoesEstados, "Selecione o estado"],
    ["WhatsApp", "whatsApp", true, "tel"],
    ["E-mail", "email", true, "text"],
    ["Tipo da Chave Pix", "tipoChavePix", true, "text", opcoesChavePix, "Selecione o tipo da chave Pix"],
    ["Chave Pix", "chavePix", true, "text"],
    ["CPF/CNPJ Beneficiário", "cpfCnpjBeneficiario", true, "text"],
    ["Observação", "observacao", false, "text"]
];

const valoresPadrao = camposForm.reduce( (a, v) => ({ ...a, [v[1]]: ""}), {});

export default function AdicionarGuia(){
    const [codigo, setCodigo] = useState(null);
    const [status, setStatus] = useState({
        erro: false,
        sucesso: false,
        mensagem: undefined
    });

    useEffect(() => {
        obterCodigo();
    }, []);

    async function obterCodigo(){
        getUltimoCodigoGuia().then( resposta => setCodigo(resposta + 1)).catch( e => setStatus({ erro: true, sucesso: false, mensagem: e.message }));
    }

    const { 
        control,
        register: registerGuia, 
        handleSubmit: handleSubmitGuia, 
        reset: resetGuia,
        formState: { errors: errosGuia }
    } = useForm({
        defaultValues: valoresPadrao,
        resolver: zodResolver(guiaSchema)
    });

    async function SalvarNovoGuia(data){
        window.scrollTo(0, 0);
        const resposta = await criarGuia(data, codigo);
        if (resposta.status !== 201){
            setStatus({
                erro: true, 
                sucesso: false,
                mensagem: resposta.mensagem
            });
        } else {
            setStatus({
                erro: false,
                sucesso: true,
                mensagem: `Guia ${resposta.guia.nome} código ${codigo} cadastrado com sucesso`
            });
            resetGuia();
            setCodigo(codigo + 1);
        }
    }

    return (
    <SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Cadastrar guia")}
            {status.erro && <ErroSpan>{"Erro ao cadastrar guia. " + status.mensagem}</ErroSpan>}
            {status.sucesso && <ErroSpan $corFundo={"#ddffcd"} $corTexto={"#209e00"} >{status.mensagem}</ErroSpan>}
            {codigo && <form onSubmit={handleSubmitGuia(SalvarNovoGuia)} style={{ marginBottom: "20px" }}>
                <AreaSuperior>
                    <Titulo1 $margem="0 0 0 0" $tamanho="20px">Código {codigo}</Titulo1>
                    <OpcoesFiltragem style={{ marginTop: "10px" }}>
                        <Botao type="submit" $corFundo="#3a170ef8" $cor="white" $marginRight="10px">Salvar</Botao>
                        <Link to="/admin/guias"><Botao>Cancelar</Botao></Link> 
                    </OpcoesFiltragem>
                </AreaSuperior>
                <Tabela>
                    <tbody>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>Nome<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><input {...registerGuia("nome", {required: "Campo obrigatório"})}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.nome && <ErroSpan>{errosGuia.nome.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>E-mail<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><input {...registerGuia("email", {required: "Campo obrigatório"})}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.email && <ErroSpan>{errosGuia.email.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>WhatsApp<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><input type="tel" {...registerGuia("whatsApp", {required: "Campo obrigatório"})}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.whatsApp && <ErroSpan>{errosGuia.whatsApp.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Empresa</ColunaTabela>
                            <ColunaTabela><input {...registerGuia("empresa")}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.empresa && <ErroSpan>{errosGuia.empresa.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Cadastur</ColunaTabela>
                            <ColunaTabela><input {...registerGuia("cadastur")}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.cadastur && <ErroSpan>{errosGuia.cadastur.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>Cidade<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><input {...registerGuia("cidade", {required: "Campo obrigatório"})}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.cidade && <ErroSpan>{errosGuia.cidade.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>Estado<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><Controller
                                            name={"estado"}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            padding: 0,
                                                            margin: 0,
                                                            width: "100%",
                                                            fontSize: "14px",
                                                            color: "#252525",
                                                            borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                                        })
                                                    }}
                                                    classNamePrefix={'Select'}
                                                    placeholder="Estado"
                                                    {...field}
                                                    options={opcoesEstados}
                                                />
                                            )}
                                        /></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.estado && <ErroSpan>{errosGuia.estado.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>Tipo Chave Pix<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><Controller
                                            name={"tipoChavePix"}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            padding: 0,
                                                            margin: 0,
                                                            width: "100%",
                                                            fontSize: "14px",
                                                            color: "#252525",
                                                            borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                                        })
                                                    }}
                                                    classNamePrefix={'Select'}
                                                    placeholder="Tipo da Chave Pix"
                                                    {...field}
                                                    options={opcoesChavePix}
                                                />
                                            )}
                                        /></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.tipoChavePix && <ErroSpan>{errosGuia.tipoChavePix.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>Chave Pix<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><input {...registerGuia("chavePix", {required: "Campo obrigatório"})}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.chavePix && <ErroSpan>{errosGuia.chavePix.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px"><div style={{ display: "flex"}}>CPF/CNPJ beneficiário<MarkObrigatorio>*</MarkObrigatorio></div></ColunaTabela>
                            <ColunaTabela><input {...registerGuia("cpfCnpjBeneficiario", {required: "Campo obrigatório"})}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.cpfCnpjBeneficiario && <ErroSpan>{errosGuia.cpfCnpjBeneficiario.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Observação</ColunaTabela>
                            <ColunaTabela><input {...registerGuia("observacao")}/></ColunaTabela>
                            <ColunaTabela $tamanho="400px">{errosGuia.observacao && <ErroSpan>{errosGuia.observacao.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                    </tbody>
                </Tabela>
                </form>}
        </SecaoPrincipal>
    </SecaoAdmin>
    )
}