import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import { InputMask } from "@react-input/mask";
import { jsPDF } from "jspdf";
import { FaChartBar } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import Select from "react-select";
import QRCode from "qrcode";
import Titulo1 from "../../componentes/Titulo1.jsx";
import Linha from "../../componentes/Linha.jsx";
import ErroSpan from "../../componentes/ErroSpan.jsx";
import Botao from "../../componentes/Botao.jsx";
import ticketsSchema from "../../schemas/ticketsSchema.js";
import { listarGuias } from "../../servicos/guiasServices.js";
import { cancelarIngresso, criarTickets, getTicket, obterTicketsHoje } from "../../servicos/ticketsServices.js";
import { adicionarTicketsExtra, buscaSessao } from "../../servicos/sessoesServices.js";
import { AreaBotoesTickets, AreaCamera, AreaNaoImprimiu, BotaoNaoImprimiu, BotaoSalvar, ContainerModal, DadosIngresso, DivBotoes, DivModal, DivTickets, InputsTickets, LabelsTickets, LadoALado, ListaSessoes, MenuLateral, PaginaTickets, SecaoPrincipal, Texto } from "./TicketsStyled.jsx";
import { verificarCupom } from "../../servicos/cuponsServices.js";
import ModalPergunta from "../../componentes/ModalPergunta.jsx";

export default function Tickets() {
    const navigate = useNavigate();
    const [sessoes, setSessoes] = useState([]);
    const [reimprimir, setReimprimir] = useState(false);
    const [guias, setGuias] = useState([]);
    const [cupom, setCupom] = useState({ codigo: "", valido: false, mostrar: false, mensagem: "" });
    const [carregando, setCarregando] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalReimp, setShowModalReimp] = useState(false);
    const [motivoCancelamento, setMotivoCancelamento] = useState("");
    const [ingressoIn, setIngressoIn] = useState("");
    const [ingressoCancelar, setIngressoCancelar] = useState(null);
    const [cancelamento, setCancelamento] = useState({
        mostrar: false,
        mensagem: "",
        erro: false
    });
    
    const {
        register: registerTickets, 
        handleSubmit: handleSubmitTickets, 
        control,
        setValue,
        getValues,
        setFocus,
        watch,
        formState: { errors: errosTickets }
    } = useForm({
        resolver: zodResolver(ticketsSchema)
    });
    const cortesias = watch("cortesias");
    const promocionais = watch("promocionais");
    const infantis = watch("infantis");
    const sessaoSelecionada = watch("sessao");
    const especial = watch("horarioEspecial");

    async function obterSessoes() {
        buscaSessao(new Date().toISOString().split("T")[0]).then(resposta => {
            if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
            let listagemSessoes = [];
            resposta = resposta.dados.filter( sessao => new Date(sessao.diaHora).getTime()/1000 >= Date.now()/1000 - 3*60*60 - 7*60 );
            resposta.map( sessao => {
                return listagemSessoes.push({
                    value: sessao._id,
                    label: sessao.diaHora.slice(11,16),
                    ticketsDisponiveis: sessao.ticketsDisponiveis,
                    diaHora: sessao.diaHora,
                    extrasAdicionados: sessao.extrasAdicionados,
                    especiaisUsados: sessao.especiaisUsados
                });
            });
            listagemSessoes.push({
                value: "65ba40b4fce4c33dd762519c",
                label: "ESPECIAL",
                ticketsDisponiveis: 15,
                diaHora: "",
                extrasAdicionados: true
            });
            setSessoes(listagemSessoes);
            setValue("sessao", listagemSessoes.find( s => s.ticketsDisponiveis > 0 ));
            setValue("horarioEspecial", listagemSessoes.find( s => s.ticketsDisponiveis > 0 ));
            setValue("promocionais", 0);
            setValue("infantis", 0);
            setValue("cortesias", 0);
            setValue("visitante.email", "");
            setValue("visitante.celular", "");
            setValue("visitante.nome", "");
            setValue("motivoCortesia", "");
        });
        listarGuias().then(resposta => {
            if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
            let listagemGuias = [];
            resposta.map(guia => {
                return listagemGuias.push({
                    value: guia._id,
                    label: guia.nome.toString()
                })
            });
            if (getValues("guia") && getValues("guia").value) {
                const indice = listagemGuias.findIndex( obj => obj.value === getValues("guia").value);
                listagemGuias.splice(indice, 1);
                listagemGuias.unshift(getValues("guia"));
            }
            setGuias(listagemGuias);
            setValue("guia", null);
        })
    }

    useEffect(() => {
        obterSessoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ingressoIn) {
            obterIngresso(ingressoIn);
            setCancelamento({
                mostrar: false,
                mensagem: "",
                erro: false
            });
        }
    }, [ingressoIn]);

    async function salvar(dados){
        if (dados.cortesias + dados.infantis + dados.promocionais === 0) return;
        if (dados.cortesias > 0 && !dados.motivoCortesia) return setFocus("motivoCortesia");
        if (dados.cortesias + dados.infantis + dados.promocionais > Number(dados.sessao.ticketsDisponiveis)) return setCupom({ ...cupom, valido: false, mostrar: true, mensagem: "Quantidade de ingressos maior que o número de lugares!" });
        let cupomUtilizado = false;
        if (cupom.codigo) {
            if (dados.promocionais === 0) {
                return setCupom({ ...cupom, valido: false, mostrar: true, mensagem: "O cupom só pode ser usados com promocionais!" });
            }
            const cupomValido = await verificaCupom();
            if (!cupomValido) return; else cupomUtilizado = true;
        }
        setCupom({ codigo: "", valido: false, mostrar: false, mensagem: "" });
        setCarregando(true);
        if (dados.sessao.label !== "ESPECIAL") dados.horarioEspecial = null;
        if (dados.guia) dados.guia = dados.guia.value;
        try {
            criarTickets({ ...dados, sessao: dados.sessao.value, cupom: cupomUtilizado }).then(async resposta => {
                const doc = new jsPDF({
                    orientation: "landscape",
                    unit: "mm",
                    format: [90, 50.8]
                });
                doc.setFont(undefined, "bold");
                if (dados.sessao.label !== "ESPECIAL"){
                    for (let i = 0; i < resposta.tickets.length; i ++) {
                        if (i !== 0) doc.addPage();
                        let pos = 0;
                        if (resposta.tickets[i].tipo === "PROMOCIONAL") pos = 38; else 
                        if (resposta.tickets[i].tipo === "PROMOCIONAL-ONLINE") {
                            resposta.tickets[i].tipo = "PROMOCIONAL-CUPOM";
                            pos = 45; 
                        } else if (resposta.tickets[i].tipo === "CORTESIA") pos = 35; else pos = 33;
                        doc.setFontSize(9)
                            .text("ACESSO ÚNICO E INDIVIDUAL", 12, 49, null, 90)
                            .text(resposta.tickets[i].tipo, 17, pos, null, 90);
                        doc.setFontSize(8)
                            .text("NÃO REEMBOLSÁVEL", 23, 41, null, 90)
                            .text("O não comparecimento na sessão", 61, 49, null, 90)
                            .text("implica na perda do ingresso", 65, 46, null, 90);
                        doc.setFontSize(18).text(dados.sessao.label, 75, 35, null, 90);
                        doc.setFontSize(12).text(`${dados.sessao.diaHora.slice(8,10)}/${dados.sessao.diaHora.slice(5,7)}/${dados.sessao.diaHora.slice(0,4)}`, 82, 37.5, null, 90);
                        let qrcode = await QRCode.toDataURL(resposta.tickets[i].numero.toString());
                        doc.addImage(qrcode, 'png', 28, 13, 27, 27);
                    }
                } else {
                    for (let i = 0; i < resposta.tickets.length; i ++) {
                        if (i !== 0) doc.addPage();
                        let pos = 0;
                        if (resposta.tickets[i].tipo === "PROMOCIONAL") pos = 47.5; else 
                        if (resposta.tickets[i].tipo === "PROMOCIONAL-ONLINE") {
                            resposta.tickets[i].tipo = "PROMOCIONAL-CUPOM";
                            pos = 45; 
                        } else if (resposta.tickets[i].tipo === "CORTESIA") pos = 43; else pos = 42;
                        doc.setFontSize(9)
                            .text("ACESSO ÚNICO E INDIVIDUAL", 12, 49, null, 90)
                            .text(`${resposta.tickets[i].tipo} - ESPECIAL`, 17, pos, null, 90);
                        doc.setFontSize(8)
                            .text("NÃO REEMBOLSÁVEL", 23, 41, null, 90)
                            .text("Para a liberação da catraca solicite", 62, 50, null, 90)
                            .text("a ajuda de um atendente", 65, 46, null, 90);
                        doc.setFontSize(18).text(dados.horarioEspecial.label, 75, 35, null, 90);
                        doc.setFontSize(10).text("SEGUNDA CHAMADA", 80, 45, null, 90);
                        doc.setFontSize(12).text(`${dados.horarioEspecial.diaHora.slice(8,10)}/${dados.horarioEspecial.diaHora.slice(5,7)}/${dados.horarioEspecial.diaHora.slice(0,4)}`, 86, 37.5, null, 90);
                        let qrcode = await QRCode.toDataURL(resposta.tickets[i].numero.toString());
                        doc.addImage(qrcode, 'png', 28, 13, 27, 27);
                    }
                }
                setReimprimir(doc);
                let iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                iframe.style.display = "none";
                iframe.src = doc.output("bloburl");
                iframe.onload = () => {
                    iframe.contentWindow.print();
                };
                obterSessoes();
                setCarregando(false);
            });
        } catch (e) {
            setCupom({ ...cupom, valido: false, mostrar: true, mensagem: e.message });
            setCarregando(false);
            console.log(e.message);
        }
    }
    function naoImprimiu(){
        let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = reimprimir.output("bloburl");
            iframe.onload = () => {
                iframe.contentWindow.print();
        }
    }
    async function adicionarExtras(){
        adicionarTicketsExtra(sessaoSelecionada.value);
        setValue("sessao.ticketsDisponiveis", 2);
    }
    async function verificaCupom(){
        try {
            const resposta = await verificarCupom(cupom.codigo);
            if (resposta.valido) return true; else {
                setCupom({ codigo: "", mostrar: true, valido: false, mensagem: resposta.mensagem || "Cupom inválido ou inexistente!" });
                return false;
            }
        } catch (e) {
            setCupom({ codigo: "", mostrar: true, valido: false, mensagem: e.message });
            return false;
        }
    }
    async function relatorioTickets(){
        try {
            const ticketsHoje = await obterTicketsHoje();
            if (!ticketsHoje) return;
            const totaisHoje = { promocionais: 0, infantis: 0, cortesias: 0 };
            ticketsHoje.tickets.forEach( ticketHoje => {
                if (ticketHoje.tipo === "PROMOCIONAL") totaisHoje.promocionais++;
                else if (ticketHoje.tipo === "INFANTIL") totaisHoje.infantis++;
                else if (ticketHoje.tipo === "CORTESIA") totaisHoje.cortesias++;
            });

            const doc = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: [90, 50.8]
            });
            doc.setFont(undefined, "normal");
            doc.setFontSize(20).text(new Date().toLocaleString('en-GB'), 10, 10);
            doc.text("Promocionais: ", 10, 25);
            doc.setFont(undefined, "bold");
            doc.setFontSize(24).text(`${totaisHoje.promocionais}`, 60, 25);

            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = doc.output("bloburl");
            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        } catch (e) {
            console.log(e.message);
        }
    }
    async function cancelarTicket() {
        if (!motivoCancelamento) return setCancelamento({
            mostrar: true,
            erro: true,
            mensagem: "Informe um motivo"
        });
        if (ingressoCancelar.foto) return setCancelamento({
            mostrar: true,
            erro: true,
            mensagem: "Esse ingresso foi utilizado e não pode ser cancelado"
        });
        const resposta = await cancelarIngresso(ingressoCancelar.numero, motivoCancelamento);
        if (resposta.status === 200) {
            setCancelamento({
                mostrar: true,
                erro: false,
                mensagem: resposta.mensagem
            });
            setMotivoCancelamento("");
            setIngressoCancelar(null);
        } else {
            setCancelamento({
                mostrar: true,
                erro: true,
                mensagem: resposta.mensagem
            });
        }
    }
    async function obterIngresso(numero){
        if (!numero) return;
        setCarregando(true);
        const resposta = await getTicket(numero);
        if (resposta && resposta.status === 200) {
            setIngressoCancelar(resposta.tickets[0]);
            if (resposta.tickets[0].foto) setCancelamento({
                mostrar: true,
                erro: true,
                mensagem: "Este ingresso já foi utilizado!"
            });
        }
        setCarregando(false);
    }
    function fecharCancelamento() {
        setShowModal(false);
        setIngressoCancelar(null);
        setCancelamento({
            mostrar: false,
            erro: false,
            mensagem: ""
        });
    }

    const handleSim = () => {
        naoImprimiu();
        setShowModalReimp(false);
    }
    const handleNao = () => {
        setShowModalReimp(false);
    }

    if (sessoes.length === 0 || guias.length === 0) {
        return (<span>Carregando</span>);
    } else {
        let fundo = "transparent";
        return (<PaginaTickets>
            <MenuLateral>
                <Titulo1 $alinhamento="center" $cor="var(--corNugaliClara)" $corFundo="transparent" $tamanho="1.5em">
                    Sessões e Lugares
                </Titulo1>
                {sessoes.slice(0, -7).map(sessao => {
                    fundo = fundo === "transparent"? "#ac6c5c5f": "transparent";
                    return (
                        <ListaSessoes $vazio={sessao.ticketsDisponiveis === 0} key={sessao.value} $corFundo={fundo}>{sessao.label} : {sessao.ticketsDisponiveis}</ListaSessoes>
                    )
                })}
            </MenuLateral>
            {ModalPergunta("Tem certeza que deseja reimprimir uma cópia dos últimos ingressos?", handleSim, handleNao, showModalReimp, "100px", "400px")}
            <SecaoPrincipal>
                <form onSubmit={handleSubmitTickets(salvar)}>
                <FaChartBar style={ {position: "absolute", right: "20px", top: "20px", cursor: "pointer", color: "#3f10056a" } } onClick={() => relatorioTickets()}/>
                <Texto $margem="20px 10px 0px 10px" $negrito="bold" $tamanho="30px">{sessaoSelecionada.label === "ESPECIAL"? `${especial.especiaisUsados} ingressos especiais utilizados`:`${sessaoSelecionada.ticketsDisponiveis} lugares disponíveis na sessão escolhida`}</Texto>
                <LadoALado>
                    <Texto $tamanho="20px" $margem="20px 10px 20px 10px">Próxima sessão disponível: </Texto>
                    <Texto $tamanho="20px" $margem="10px 10px 20px 10px" $corFundo="var(--corNugaliEscura)" $cor="white" $pad="10px">{sessoes.find( s => s.ticketsDisponiveis > 0).label.substring(0, 2) === "18" ? "--:--": sessoes.find( s => s.ticketsDisponiveis > 0).label}</Texto>
                    <IoMdRefresh className="IconeAtt" onClick={() => obterSessoes()}/>
                </LadoALado>
                <Linha />
                <LadoALado>
                    <DivTickets $largura="400px" $margem="10px 10px 10px 50px">
                        <LabelsTickets>Sessão</LabelsTickets>
                        <Controller
                            name={"sessao"}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isClearable={false}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            margin: 0,
                                            border: "none",
                                            padding: "2px",
                                            borderRadius: 0,
                                            borderBottom: "2px solid #3a170e",
                                            fontSize: "24px",
                                            textAlign: "center",
                                            borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                        }),
                                        option: (baseStyles) => ({
                                            ...baseStyles,
                                            color: "black",
                                            textAlign: "center",
                                            fontSize: "20px"
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color: "black"
                                        })
                                    }}
                                    classNamePrefix={'Select'}
                                    placeholder=""
                                    {...field}
                                    options={sessoes} 
                                />
                            )}
                        />
                    </DivTickets>
                    <DivTickets $esconder={sessaoSelecionada.label !== "ESPECIAL"} $largura="300px" $margem="10px 10px 10px 10px">
                        <LabelsTickets>Horário</LabelsTickets>
                        <Controller
                            name={"horarioEspecial"}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isClearable={false}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            margin: 0,
                                            border: "none",
                                            padding: "2px",
                                            borderRadius: 0,
                                            borderBottom: "2px solid #3a170e",
                                            fontSize: "24px",
                                            textAlign: "center",
                                            borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                        }),
                                        option: (baseStyles) => ({
                                            ...baseStyles,
                                            color: "black",
                                            textAlign: "center",
                                            fontSize: "20px"
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color: "black"
                                        })
                                    }}
                                    classNamePrefix={'Select'}
                                    placeholder=""
                                    {...field}
                                    options={sessoes.slice(0, -1)} 
                                />
                            )}
                        />
                    </DivTickets>
                    <DivTickets $margem="10px 50px 10px 10px">
                        <LabelsTickets>Guia</LabelsTickets>
                        <Controller
                            name={"guia"}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isClearable
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            margin: 0,
                                            border: "none",
                                            padding: "2px",
                                            borderRadius: 0,
                                            borderBottom: "2px solid #3a170e",
                                            fontSize: "24px",
                                            borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                        }),
                                        option: (baseStyles) => ({
                                            ...baseStyles,
                                            color: "black",
                                            fontSize: "20px"
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color: "black"
                                        })
                                    }}
                                    classNamePrefix={'Select'}
                                    placeholder=""
                                    {...field}
                                    options={guias}
                                />
                            )}
                        />
                    </DivTickets>
                </LadoALado>
                <LadoALado>
                    <DivTickets $margem="10px 10px 20px 50px">
                        <LabelsTickets>Promocional</LabelsTickets>
                        <InputsTickets 
                            type="number" 
                            {...registerTickets("promocionais", { 
                                setValueAs: value => parseInt(value, 10) 
                            })}
                            onBlur={() => {
                                if (promocionais > (sessaoSelecionada.ticketsDisponiveis - infantis - cortesias)) setValue("promocionais", sessaoSelecionada.ticketsDisponiveis - infantis - cortesias);
                            }} 
                            min={0} 
                            max={sessaoSelecionada.ticketsDisponiveis - infantis - cortesias || 0}/>
                    </DivTickets>
                    <DivTickets $margem="10px 10px 20px 10px">
                        <LabelsTickets>Infantil</LabelsTickets>
                        <InputsTickets 
                        type="number" 
                        {...registerTickets("infantis", { 
                            setValueAs: value => parseInt(value, 10) 
                        })}
                        onBlur={() => {
                            if (infantis > (sessaoSelecionada.ticketsDisponiveis - cortesias - promocionais)) setValue("infantis", sessaoSelecionada.ticketsDisponiveis - cortesias - promocionais);
                        }} 
                        min={0} 
                        max={sessaoSelecionada.ticketsDisponiveis - promocionais - cortesias || 0}/>
                    </DivTickets>
                    <DivTickets $margem="10px 50px 20px 10px">
                        <LabelsTickets>Cortesia</LabelsTickets>
                        <InputsTickets 
                        type="number" 
                        {...registerTickets("cortesias", { 
                            setValueAs: value => parseInt(value, 10) 
                        })} 
                        onBlur={() => {
                            if (cortesias > (sessaoSelecionada.ticketsDisponiveis - infantis - promocionais)) setValue("cortesias", sessaoSelecionada.ticketsDisponiveis - infantis - promocionais);
                        }} 
                        min={0} 
                        max={sessaoSelecionada.ticketsDisponiveis - infantis - promocionais || 0}/>
                    </DivTickets>
                </LadoALado>
                <DivTickets $esconder={cortesias === 0} $margem="0px 100px 20px 50px" $pad="0 100px 0 0">
                    <LabelsTickets>Motivo da cortesia</LabelsTickets>
                    <InputsTickets {...registerTickets("motivoCortesia")}/>
                </DivTickets>
                <Linha />
                <LadoALado>
                    <DivTickets $margem="15px 10px 10px 50px">
                        <LabelsTickets>E-mail</LabelsTickets>
                        <InputsTickets autoComplete="on" {...registerTickets("visitante.email")} />
                    </DivTickets>
                    <DivTickets $margem="15px 50px 10px 10px" $largura="80%">
                        <LabelsTickets>Celular</LabelsTickets>
                        <InputMask
                            autoComplete="on"
                            {...registerTickets("visitante.celular")}
                            // onInput={event => setDados({...dados, visitante: {...dados.visitante, celular: event.target.value}})} 
                            // value={dados.visitante.celular} 
                            component={InputsTickets} 
                            mask="(__) _____-____" 
                            replacement={{ _: /\d/ }}
                        />
                    </DivTickets>
                </LadoALado>
                <LadoALado $justificar="left">
                    <DivTickets $largura="200px" $larguraMinima="200px" $margem="10px 10px 15px 50px">
                        <LabelsTickets>Cupom</LabelsTickets>
                        <InputMask 
                            onInput={event => setCupom({ codigo: event.target.value.toUpperCase(), valido: false, utilizado: false, mostrar: false })} 
                            value={cupom.codigo}
                            component={InputsTickets} 
                            mask="___ ___" 
                            replacement={{ _: /[A-Za-z0-9]/ }}
                        />
                    </DivTickets>
                    {/* <BotaoSalvar onClick={() => verificaCupom()} $margem="20px 10px 0 0">Aplicar</BotaoSalvar> */}
                    {cupom.mostrar && <DivTickets $margem="20px 50px 0px 0px"><ErroSpan $corFundo={cupom.valido? "#ddffcd": ""} $corTexto={cupom.valido? "#209e00": ""} >{cupom.valido? "O cupom foi aplicado": cupom.mensagem}</ErroSpan></DivTickets>}
                </LadoALado>
                <AreaBotoesTickets>
                    <AreaNaoImprimiu>
                        <BotaoNaoImprimiu type="button" onClick={() => setShowModal(true)}>Cancelar ingresso</BotaoNaoImprimiu>
                        <BotaoNaoImprimiu type="button" disabled={!reimprimir || carregando} onClick={() => setShowModalReimp(true)}>Não imprimiu</BotaoNaoImprimiu>
                        <BotaoNaoImprimiu type="button" $esconder={sessaoSelecionada.ticketsDisponiveis > 0 || sessaoSelecionada.extrasAdicionados === true} onClick={() => adicionarExtras()}>Adicionar Tickets extra</BotaoNaoImprimiu>
                    </AreaNaoImprimiu>
                    <BotaoSalvar disabled={carregando} type="submit">SALVAR</BotaoSalvar>
                </AreaBotoesTickets>
                {errosTickets.sessao && <ErroSpan>Erro na sessão: {errosTickets.sessao.message}</ErroSpan>}
                {errosTickets.promocionais && <ErroSpan>Erro nos promocionais: {errosTickets.promocionais.message}</ErroSpan>}
                {errosTickets.infantis && <ErroSpan>Erro nos infantis: {errosTickets.infantis.message}</ErroSpan>}
                {errosTickets.cortesias && <ErroSpan>Erro nas cortesias: {errosTickets.cortesias.message}</ErroSpan>}
                {errosTickets.motivoCortesia && <ErroSpan>Erro no motivo da cortesia: {errosTickets.motivoCortesia.message}</ErroSpan>}
                {errosTickets.horarioEspecial && <ErroSpan>Erro no horario especial: {errosTickets.horarioEspecial.message}</ErroSpan>}
                {errosTickets.visitante && <ErroSpan>Erro no visitante: {errosTickets.visitante.message}</ErroSpan>}
                {errosTickets.guia && <ErroSpan>Erro no guia: {errosTickets.guia.message}</ErroSpan>}
                </form>
            </SecaoPrincipal>
            <ContainerModal $mostrar={showModal}>
                <DivModal $altura={"450px"} $largura={"600px"}>
                    <LadoALado>
                        <AreaCamera>
                            {showModal && <QrReader
                                onResult={(resultado) => {
                                    if (!!resultado) {
                                        setIngressoIn(resultado.text)
                                        obterIngresso(resultado.text)
                                    }
                                }}
                            />}
                        </AreaCamera>
                        {ingressoCancelar && <DadosIngresso>
                            <p>Número: {ingressoCancelar.numero}</p>
                            <p>Sessão: {ingressoCancelar.sessao.diaHora.split("T")[0].split("-")[2]}/{ingressoCancelar.sessao.diaHora.split("T")[0].split("-")[1]}/{ingressoCancelar.sessao.diaHora.split("T")[0].split("-")[0]} - {ingressoCancelar.sessao.diaHora.split("T")[1].slice(0,5)}</p>
                            <p>Tipo: {ingressoCancelar.tipo}</p>
                            <p>Visitante: {ingressoCancelar.visitante.nome || ingressoCancelar.visitante.email || ingressoCancelar.visitante.celular || ingressoCancelar.visitante._id}</p>
                        </DadosIngresso>}
                    </LadoALado>
                    {ingressoCancelar && ingressoCancelar.cancelado && <ErroSpan $corFundo="#ffcdcd">Ingresso já cancelado!</ErroSpan>}
                    {cancelamento.mostrar && <ErroSpan $corFundo={cancelamento.erro? "#ffcdcd": "#ddffcd"} $corTexto={cancelamento.erro? "#9e0000": "#209e00"}>{cancelamento.mensagem}</ErroSpan>}
                    <DivTickets $largura="500px" $margem="10px 10px 10px 50px">
                        <LabelsTickets>Motivo do cancelamento</LabelsTickets>
                        <InputsTickets value={motivoCancelamento} onChange={ e => setMotivoCancelamento(e.target.value)}/>
                    </DivTickets>
                    <DivBotoes>
                        <Botao disabled={ingressoCancelar?.numero? false: true || carregando} onClick={() => cancelarTicket()}>Confirmar</Botao>
                        <Botao onClick={() => fecharCancelamento()}>Fechar</Botao>
                    </DivBotoes>
                </DivModal>
            </ContainerModal>
        </PaginaTickets>)
    }
}