import Cookies from "js-cookie";

export async function listarVisitantes(busca, pagina = "1", ordem = 1, ordenacao = "nome"){
    try {
        const resposta = await fetch(`/api/visitantes/busca?busca=${busca}&pagina=${pagina}&ordem=${ordem}&ordenacao=${ordenacao}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterVisitantePorId(id){
    try {
        const resposta = await fetch(`/api/visitantes/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function deletarVisitante(id, deletarTickets){
    try {
        const resposta = await fetch(`/api/visitantes/${id}/${deletarTickets}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}