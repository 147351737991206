import { Link } from "react-router-dom";
import styled from "styled-components";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { BotaoAcoes } from "../AdminStyled";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
`
export function TabelaCancelados(ingressos, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual) {
    const campos = [
        {chave: "numero", label: "Número"},
        {chave: "sessao", label: "Sessão"},
        {chave: "visitante", label: "Visitante"},
        {chave: "tipo", label: "Tipo"},
        {chave: "motivoCancelamento", label: "Mot. Cancelamento"},
        {chave: "guia", label: "Guia"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === "1"? "-1": "1");
        } else {
            setOrdenacaoAtual(ordenacao);
        }
    }
    
    try {
        return (<Tabela>
            <thead>
                <LinhaTabela>
                    {campos.map( campo => {
                        return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                            <p>
                                {campo.label}
                                {campo.chave === ordenacaoAtual && ordemAtual === "-1" && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                {campo.chave === ordenacaoAtual && ordemAtual === "1" && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                            </p>
                        </TituloColunas>
                    })}
                    <th style={{color: "#252525"}}>Ações</th>
                </LinhaTabela>
            </thead>
            <tbody>
                {ingressos.map((ingresso) => {
                    return (<LinhaTabela key={ingresso._id}> 
                        <ColunaTabela>{ingresso.numero || "-"}</ColunaTabela>
                        <ColunaTabela style={{cursor: "pointer" }}><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${ingresso.sessao._id}`}>{new Date(ingresso.sessao.diaHora).toLocaleDateString('en-GB')} {ingresso.sessao.diaHora.split("T")[1].slice(0,5) || "-"}</Link></ColunaTabela>
                        <ColunaTabela style={{cursor: "pointer"}}><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/visitantes/${ingresso.visitante._id}`}>{ingresso.visitante.nome.split(" ")[0] || ingresso.visitante.email || ingresso.visitante.celular || "-"}</Link></ColunaTabela>
                        <ColunaTabela>{ingresso.tipo || "-"}</ColunaTabela>
                        <ColunaTabela>{ingresso.motivoCancelamento || "-"}</ColunaTabela>
                        <ColunaTabela>{ingresso.guia? (<Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/guias/${ingresso.guia._id}`}>{ingresso.guia.nome.split(" ")[0]}</Link>): "-"}</ColunaTabela>
                        <ColunaTabela>
                            <BotaoAcoes><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/ingressos/${ingresso._id}`}>Abrir</Link></BotaoAcoes>
                        </ColunaTabela>
                    </LinhaTabela>)
                })}
            </tbody>
        </Tabela>);
    } catch (e) {
        return <></>
    }
}