import styled from "styled-components";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { BotaoExcluir } from "../AdminStyled";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { deletarUsuario } from "../../../servicos/usuariosServices";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
`
export function TabelaUsuarios(usuarios, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual, showAviso, setShowAviso) {
    const campos = [
        {chave: "nome", label: "Nome"},
        {chave: "email", label: "E-mail"},
        {chave: "permissoes", label: "Permissões"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }

    const handleSim = async () => {
        await deletarUsuario(showAviso.usuario);
        setShowAviso({ mostrar: false });
    }
    const handleNao = () => {
        setShowAviso({ mostrar: false })
    }
    
    try {
        return (<>
            {ModalPergunta("Tem certeza que deseja deletar este usuário?", handleSim, handleNao, showAviso.mostrar, "100px")}
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                        <th style={{ color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>
                    {usuarios.map((usuario) => {
                        return (<LinhaTabela key={usuario._id}> 
                            <ColunaTabela>{usuario.nome}</ColunaTabela>
                            <ColunaTabela>{usuario.email}</ColunaTabela>
                            <ColunaTabela>{usuario.permissoes === 1? "Administrador": "Atendente"}</ColunaTabela>
                            <ColunaTabela>
                                <BotaoExcluir onClick={() => setShowAviso({ mostrar: true, usuario: usuario._id })}>Excluir</BotaoExcluir>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
        </>
        )
    } catch (e) {
        return <></>
    }
}