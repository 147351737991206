import styled from "styled-components";

const Titulo1 = styled.h1`
    color: ${props => props.$cor || "#252525"};
    font-size: ${props => props.$tamanho || "2em"};
    background-color: ${props => props.$corFundo || "white"};
    padding: ${props => props.$pad || "20px 10px"};
    text-align: ${props => props.$alinhamento || "left"};
    margin: ${props => props.$margem || "0"};
`
export default Titulo1;