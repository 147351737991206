import { useEffect, useState } from "react";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { obterMensagens } from "../../../servicos/whatsAppServices";
import { AreaInput, AreaMensagens, AreaWpp, CabecalhoContato } from "./WhatsAppStyled";

export default function WhatsApp() {
    const [mensagens, setMensagens] = useState(null);

    useEffect( () => {
        obterMensagens("554791319391").then( resposta => {
            setMensagens(resposta.dados)
        });
    }, []);

    console.log(mensagens)

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("WhatsApp")}
                <AreaWpp>
                    <CabecalhoContato>
                        Contato
                    </CabecalhoContato>
                    <AreaMensagens>
                        Teste
                    </AreaMensagens>
                    <AreaInput>
                        <input></input>
                    </AreaInput>
                </AreaWpp>
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}