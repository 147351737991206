import { useEffect, useState } from "react";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import Botao from "../../../componentes/Botao";
import ErroSpan from "../../../componentes/ErroSpan";
import { AreaSuperior, FiltrarData, InputMes, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { getComissoes } from "../../../servicos/comissoesServices.js";
import { baixarArquivo } from "./arquivoBanco";
import { TabelaComissoes } from "./TabelaComissoes";

export default function Comissoes(){
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const [comissoes, setComissoes] = useState([]);
    const [totais, setTotais] = useState({});
    const [erro, setErro] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ordenacao, setOrdenacao] = useState("guia");
    const [ordem, setOrdem] = useState(1);
    const [ordenou, setOrdenou] = useState(false);

    function ordenarComissoes(ordenacaoAtual, ordemAtual){
        setComissoes(prevComissoes => {
            const sortedComissoes = [...prevComissoes].sort((a, b) => {
                if (ordenacaoAtual === "guia") {
                    if (a.guia.nome < b.guia.nome){
                        return -ordemAtual;
                    } else if (a.guia.nome > b.guia.nome){
                        return ordemAtual;
                    } else {
                        return 0;
                    }
                } else if (ordenacaoAtual === "total") {
                    return ordem*(b.comissaoTour + b.comissaoLojaTour + b.comissaoLojaCentro - a.comissaoTour - a.comissaoLojaTour - a.comissaoLojaCentro);
                }else {
                    if (a[ordenacaoAtual] < b[ordenacaoAtual]){
                        return ordemAtual;
                    } else if (a[ordenacaoAtual] > b[ordenacaoAtual]){
                        return -ordemAtual;
                    } else {
                        return 0;
                    }
                }
            });
            setOrdenou(true);
            return sortedComissoes;
        });
    }

    useEffect(() => {
        if (comissoes.length > 0 && !ordenou) {
            ordenarComissoes(ordenacao, ordem);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comissoes, ordem, ordenacao]);
    
    async function atualizaFiltros() {
        if (!dataInicio || !dataFim) return setErro("Selecione as datas");
        if (dataInicio > dataFim) return setErro("A data final deve ser maior que a data inicial");
        setErro("");
        setIsLoading(true);
        const resposta = await getComissoes(dataInicio, dataFim);
        if (resposta.length === 0){
            setErro("Falha ao obter as comissões!");
        } else {
            setComissoes(resposta);
            setTotais({
                totalTour: resposta.reduce( (total, atual) => total + atual.comissaoTour, 0).toFixed(2),
                totalLojaTour: resposta.reduce( (total, atual) => total + atual.comissaoLojaTour, 0).toFixed(2),
                totalLojaCentro: resposta.reduce( (total, atual) => total + atual.comissaoLojaCentro, 0).toFixed(2),
                totalTotal: resposta.reduce( (total, atual) => total + atual.comissaoTotal, 0).toFixed(2)
            });
            setErro("");
        }
        setIsLoading(false);
        setOrdenou(false);
    }

    function BaixarCobranca(){
        if (comissoes.length === 0) return setErro("Importe as comissões primeiro!");
        baixarArquivo(comissoes, setErro);
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Comissões")}
                <AreaSuperior $margem="10px 50px 20px 40px">
                    <OpcoesFiltragem>
                        <FiltrarData $marginTop="1px">
                            <label>Data inicial</label>
                            <InputMes type="date" onInput={ evento => setDataInicio(evento.target.value)}/>
                        </FiltrarData>
                        <FiltrarData $marginTop="1px">
                            <label>Data final</label>
                            <InputMes type="date" onInput={ evento => setDataFim(evento.target.value)}/>
                        </FiltrarData>
                        <Botao $marginTop="15px" $cursorDesabilitado={"wait"} disabled={isLoading} onClick={() => atualizaFiltros()}>Atualizar</Botao>
                    </OpcoesFiltragem>
                    <Botao $marginTop="15px" $cursorDesabilitado={"wait"} disabled={isLoading} onClick={() => BaixarCobranca()}>Baixar Arquivo</Botao>
                </AreaSuperior>
                {isLoading && <ErroSpan>Carregando comissões...</ErroSpan>}
                {erro && <ErroSpan>{erro}</ErroSpan>}
                {TabelaComissoes(comissoes, totais, ordenacao, ordem, setOrdenacao, setOrdem, setOrdenou)}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}