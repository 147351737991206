import { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import Botao from "../../../componentes/Botao";

const ContainerFiltros = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ab;
    display: ${props => props.$mostrar? "block": "none"};
    z-index: 99;
`
const SecaoFiltros = styled.div`
    position: relative;
    top: 100px;
    width: 580px;
    margin: auto;
    background-color: white;
    border-radius: 10px;
    align-items: center;
    padding: 20px 20px 5px 20px;
    button {
        margin: 10px;
    }
    p {
        color: black;
        font-size: 16px;
        font-weight: bold;
    }
`
const DivSelecaoFiltrar = styled.div`
    width: 100%;
    display: flex;
    button {
        margin-top: 0px;
    }
`
const DivFiltrar = styled.div`
    width: ${props => props.$largura || "200px"};
`
const InputFiltro = styled.input`
    border-radius: 4px;
    border: 2px solid rgba(58, 23, 14, 0.308);
    background-color: white;
    color: black;
    height: 34px;
    width: 180px;
    font-size: 16px;
`
const BotaoFiltro2 = styled.button`
    background-color: transparent;
    border: none;
    color: var(--corNugaliEscura);
    cursor: pointer;
    padding: 10px 0 0 60px;
`
export function FiltrosGuias(showFiltros, setShowFiltros, filtros, setFiltros) {
    const [campo, setCampo] = useState("");
    const [condicao, setCondicao] = useState("");
    const [valor, setValor] = useState("");
    const [filtrosSelecionados, setFiltrosSelecionados] = useState({});
    const [sincronizarFiltros, setSincronizarFiltros] = useState(true);
    const opcoesFiltragem = [
        { value: "codigo", label: "Código" },
        { value: "cidade", label: "Cidade" },
        { value: "estado", label: "Estado" },
        { value: "whatsApp", label: "WhatsApp" },
        { value: "tipoChavePix", label: "Tipo da Chave Pix" },
        { value: "cpfCnpjBeneficiario", label: "CPF/CNPJ Beneficiário" },
        { value: "empresa", label: "Empresa" }
    ];
    
    const opcoesFiltragem2 = [
        { value: "is", label: "É igual a" },
        { value: "isnot", label: "Não é igual a", chave: "$ne" },
        { value: "maior", label: "É maior que", chave: "$gt" },
        { value: "menor", label: "É menor que", chave: "$lt" },
        { value: "maiorIgual", label: "É maior ou igual a", chave: "$gte" },
        { value: "menorIgual", label: "É menor ou igaul a", chave: "$lte" }
    ];
    
    if (showFiltros && sincronizarFiltros) {
        if (Object.keys(filtros).length <= 1) setFiltrosSelecionados({})
        setSincronizarFiltros(false);
    }

    function diacriticSensitiveRegex(string = '') {     // Função para formatar acentos na busca
        return string
           .replace(/a/g, '[a,á,à,ä,â]')
           .replace(/A/g, '[A,a,á,à,ä,â]')
           .replace(/e/g, '[e,é,ë,è]')
           .replace(/E/g, '[E,e,é,ë,è]')
           .replace(/i/g, '[i,í,ï,ì]')
           .replace(/I/g, '[I,i,í,ï,ì]')
           .replace(/o/g, '[o,ó,ö,ò]')
           .replace(/O/g, '[O,o,ó,ö,ò]')
           .replace(/u/g, '[u,ü,ú,ù]')
           .replace(/U/g, '[U,u,ü,ú,ù]');
    }

    function addFiltro(){
        setFiltrosSelecionados({
            ...filtrosSelecionados,
            [campo.value]: {
                condicao: condicao,
                valor: valor
            }
        });
        setCampo("");
        setCondicao("");
        setValor("");
    }

    function aplicarFiltros(){
        let aplicar = {};
        Object.keys(filtrosSelecionados).forEach( item => {
            if (filtrosSelecionados[item]){
                if (item === "whatsApp"){
                    let procurar = "";
                    for (let i = 1; i <= filtrosSelecionados[item].valor.length; i++){
                        procurar += filtrosSelecionados[item].valor.slice(i-1, i) + "[^0-9]*";
                    }
                    if (filtrosSelecionados[item].condicao.value === "is") {
                        aplicar[item] = {
                            $regex: procurar
                        }
                    }
                } else if (item === "codigo") {
                    if (filtrosSelecionados[item].condicao.value === "is") aplicar[item] = Number(filtrosSelecionados[item].valor); 
                    else aplicar[item] = {
                        [filtrosSelecionados[item].condicao.chave]: Number(filtrosSelecionados[item].valor)
                    }
                } else {
                    if (filtrosSelecionados[item].condicao.value === "is") {
                        if (filtrosSelecionados[item].valor === "") {
                            aplicar.$or = [
                                {[item]: ""},
                                {[item]: null}
                            ]
                        } else {
                            aplicar[item] = { $regex: diacriticSensitiveRegex(filtrosSelecionados[item].valor), $options: "i" }; 
                        }
                    } else { 
                        aplicar[item] = {
                            $not: {
                                $regex: diacriticSensitiveRegex(filtrosSelecionados[item].valor), $options: "i"
                            }
                        }
                    }
                }
            } 
        });
        setFiltros({...filtros, ...aplicar});
        setShowFiltros(false);
        setSincronizarFiltros(true);
    }
    
    return (
        <ContainerFiltros $mostrar={showFiltros}>
                <SecaoFiltros>
                    <DivSelecaoFiltrar>
                        <DivFiltrar $largura="150px">
                            <Select 
                                options={opcoesFiltragem} 
                                placeholder="Campo"
                                value={campo}
                                onChange={e => setCampo(e)}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        marginBottom: 10,
                                        border: "2px solid rgba(58, 23, 14, 0.308)",
                                        width: "150px",
                                        borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black"
                                    })
                                }}
                            />
                        </DivFiltrar>
                        
                        <DivFiltrar $largura="150px">
                            <Select 
                                options={opcoesFiltragem2}
                                placeholder="?"
                                value={condicao}
                                onChange={e => setCondicao(e)}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        marginBottom: 10,
                                        border: "2px solid rgba(58, 23, 14, 0.308)",
                                        width: "150px",
                                        borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black"
                                    })
                                }}
                            />
                        </DivFiltrar>
                        <InputFiltro value={valor} placeholder="Valor" onChange={e => setValor(e.target.value)}/>
                        <Botao onClick={() => addFiltro()}>Adicionar</Botao>
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.codigo && <>
                            <p>Código {filtrosSelecionados.codigo.condicao.label} {filtrosSelecionados.codigo.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, codigo: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.cidade && <>
                            <p>Cidade {filtrosSelecionados.cidade.condicao.label} {filtrosSelecionados.cidade.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, cidade: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.estado && <>
                            <p>Estado {filtrosSelecionados.estado.condicao.label} {filtrosSelecionados.estado.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, estado: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.whatsApp && <>
                            <p>WhatsApp {filtrosSelecionados.whatsApp.condicao.label} {filtrosSelecionados.whatsApp.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, whatsApp: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.tipoChavePix && <>
                            <p>Tipo da Chave Pix {filtrosSelecionados.tipoChavePix.condicao.label} {filtrosSelecionados.tipoChavePix.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, tipoChavePix: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.cpfCnpjBeneficiario && <>
                            <p>CPF/CNPJ do beneficiário {filtrosSelecionados.cpfCnpjBeneficiario.condicao.label} {filtrosSelecionados.cpfCnpjBeneficiario.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, cpfCnpjBeneficiario: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>
                    <DivSelecaoFiltrar >
                        {filtrosSelecionados.empresa && <>
                            <p>Empresa {filtrosSelecionados.empresa.condicao.label} {filtrosSelecionados.empresa.valor}</p>
                            <BotaoFiltro2 onClick={() => setFiltrosSelecionados({...filtrosSelecionados, empresa: undefined})}>Remover</BotaoFiltro2>
                        </>}
                    </DivSelecaoFiltrar>

                    <Botao onClick={() => aplicarFiltros()}>Filtrar</Botao>
                    <Botao onClick={() => {
                        setShowFiltros(false);
                        setSincronizarFiltros(true);
                    }}>Cancelar</Botao>
                </SecaoFiltros>
            </ContainerFiltros>
    )
}