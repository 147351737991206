import { z } from 'zod';

const usuarioSchema = z.object({
    nome: z.string().min(3, { message: "O nome é obrigatório e deve ter pelo menos 3 letras!"}).transform((name) => name
        .trim()         // Remove espaços em branco no fim e no início do texto
        .split(" ")     // Divide palavras separadas por espaço
        .map((palavra) => palavra[0].toUpperCase() + palavra.slice(1))  // Transforma a primeira letra de cada palavra
        .join(" ")),    // Une novamente as palavras.
    email: z.string().email({ message: "E-mail inválido" }).toLowerCase().transform( (campo) => campo.trim()),
    permissoes: z.object({
        value: z.number().min(1).max(4),
        label: z.string()
    }),
    senha: z.string().min(6, "A senha deve ter no mínimo 6 caracteres"),
    confirmPassword: z.string().min(6, "A senha deve ter no mínimo 6 caracteres")
}).refine( dados => dados.senha === dados.confirmPassword, {
    message: "As senhas não correspondem",
    path: ["confirmPassword"]
});

export default usuarioSchema;