import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { AreaSuperior, ColunaTabela, LinhaTabela, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal, Tabela } from "../AdminStyled";
import { deletarTicket, imprimirIngresso, obterTicketPorId } from "../../../servicos/ticketsServices";
import { MdPhotoCamera } from "react-icons/md";
import Titulo1 from "../../../componentes/Titulo1";
import Botao from "../../../componentes/Botao";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { obterMensagemPorId } from "../../../servicos/whatsAppServices";
import { reenviarFotoPorWhatsapp } from "../../../servicos/fotosServices";
import ErroSpan from "../../../componentes/ErroSpan";

export default function IngressoId() {
    const [ingresso, setIngresso] = useState(null);
    const [mensagem, setMensagem] = useState(null);
    const [status, setStatus] = useState({
        erro: true,
        mostrar: false,
        mensagem: "Teste"
    })
    const [aviso, setAviso] = useState({
        mostrar: false
    });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        obterIngresso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function obterIngresso(){
        const resposta = await obterTicketPorId(id);
        if (resposta.ingresso) {
            if (resposta.ingresso.whatsAppMensagem) {
                const resposta2 = await obterMensagemPorId(resposta.ingresso.whatsAppMensagem);
                if (resposta2.mensagem) setMensagem(resposta2.mensagem);
            }
            setIngresso(resposta.ingresso);
        }
    }

    async function reenviar(){
        const resposta = await reenviarFotoPorWhatsapp([ingresso.numero]);
        if (resposta.status && resposta.status === 200) {
            setStatus({
                mostrar: true,
                mensagem: "A foto foi reenviada!",
                erro: false
            });
        } else {
            setStatus({
                mostrar: true,
                mensagem: resposta.mensagem,
                erro: true
            });
        }
        obterIngresso();
    }

    const handleSim = async () => {
        await deletarTicket(ingresso.numero);
        setAviso({ mostrar: false });
        navigate("/admin/ingressos");
    };
    const handleNao = async () => {
        setAviso({ mostrar: false });
    };

    return (<SecaoAdmin>
        <MenuLateral />
        <SecaoPrincipal>
            {CabecalhoAdmin("Visualizar ingresso")}
            {ModalPergunta("Tem certeza que deseja deletar este ingresso?", handleSim, handleNao, aviso.mostrar, "100px")}
            {ingresso && <>
            <AreaSuperior>
                <Titulo1 $margem="0 0 0 0" $tamanho="20px">{ingresso.numero}</Titulo1>
                <OpcoesFiltragem>
                    {ingresso.foto && ingresso.visitante.celular && <Botao $marginRight="10px" onClick={() => reenviar()}>Reenviar foto</Botao>}
                    <Botao $marginRight="10px" onClick={() => imprimirIngresso(ingresso.numero, ingresso.tipo, ingresso.sessao)}>Imprimir</Botao>
                    <Botao $marginRight="10px" $cor="#ff2424" onClick={() => setAviso({ mostrar: true })} >Excluir</Botao>
                </OpcoesFiltragem>
            </AreaSuperior>
            {status.mostrar && <ErroSpan $corFundo={status.erro? "#ffcdcd": "#ddffcd"} $corTexto={status.erro? "#9e0000": "#209e00"}>{status.mensagem}</ErroSpan>}
            <Tabela>
                <tbody>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Sessão</ColunaTabela>
                        <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${ingresso.sessao._id}`}>{new Date(ingresso.sessao.diaHora).toLocaleDateString('en-GB')}, {ingresso.sessao.diaHora.split("T")[1].slice(0,5)}</Link></ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Visitante</ColunaTabela>
                        <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/visitantes/${ingresso.visitante._id}`}>{ingresso.visitante.nome || ingresso.visitante.email || ingresso.visitante.celular || "-"}</Link></ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Tipo</ColunaTabela>
                        <ColunaTabela>{ingresso.tipo}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Motivo da cortesia</ColunaTabela>
                        <ColunaTabela>{ingresso.motivoCortesia || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Guia</ColunaTabela>
                        <ColunaTabela>{ingresso.guia? ingresso.guia.nome : "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Data da compra</ColunaTabela>
                        <ColunaTabela>{ingresso.dataCriacao? new Date(ingresso.dataCriacao).toLocaleString("en-gb") : "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525" $tamanho="200px">Foto</ColunaTabela>
                        <ColunaTabela>{ingresso.foto? <Link style={{ textDecoration: 'none', color: "inherit" }} target={"_blank"} to={`/foto/${ingresso.foto}`}><MdPhotoCamera className="Icone"/></Link>: "-"}</ColunaTabela>
                    </LinhaTabela>
                    {mensagem && <LinhaTabela $tamanho="70px">
                        <ColunaTabela $cor="#252525" $tamanho="200px">Envio por WhatsApp</ColunaTabela>
                        <ColunaTabela $cor="#252525" $tamFonte="14px">{mensagem.status.map( s => {
                            const s2 = JSON.parse(s);
                            const att = s2.atualizacao === "accepted"? "Aceita": s2.atualizacao === "sent"? "Enviada": s2.atualizacao === "delivered"? "Entregue": s2.atualizacao === "read"? "Lida": s2.atualizacao === "failed"? "Erro no envio": "Não identificado";
                            return `${att}: ${new Date(s2.horario*1000).toLocaleString("en-gb")}. `
                        })}</ColunaTabela>
                    </LinhaTabela>}
                    {ingresso.cancelado && <LinhaTabela><ColunaTabela $cor="#ff2424">Ingresso cancelado!</ColunaTabela></LinhaTabela>}
                    {ingresso.motivoCancelamento && <LinhaTabela><ColunaTabela $cor="#252525" $tamanho="200px">Motivo</ColunaTabela><ColunaTabela>{ingresso.motivoCancelamento}</ColunaTabela></LinhaTabela>}
                </tbody>
            </Tabela>
            </>}
        </SecaoPrincipal>
    </SecaoAdmin>)
}