import { z } from 'zod';

const guiaSchema = z.object({
    nome: z.string().min(3, { message: "O nome é obrigatório e deve ter pelo menos 3 letras!"}).transform((name) => name
        .replace(/\s+/g,' ')
        .trim()         // Remove espaços em branco no fim e no início do texto
        .split(" ")     // Divide palavras separadas por espaço
        .map((palavra) => palavra.length === 0? "": palavra[0].toUpperCase() + palavra.slice(1))  // Transforma a primeira letra de cada palavra
        .join(" ")),    // Une novamente as palavras.
    empresa: z.string().transform( (campo) => campo.trim()),
    cadastur: z.string().transform( (campo) => campo.trim()),
    cidade: z.string().transform( (campo) => campo.trim()),
    estado: z.object({
        value: z.string(),
        label: z.string()
    }, { message: "Selecione o estado!" }),
    whatsApp: z.string().regex(/(\([0-9]{2}\)\s?[0-9]{4,5}-?[0-9]{3,4})|([0-9]{10,11})|([0-9]{2}\s?[0-9]{8,9})/gm, { message: "Informe um telefone válido com DDD" }).transform( (campo) => campo.trim()),
    email: z.string().email({ message: "E-mail inválido" }).toLowerCase().transform( (campo) => campo.trim()),
    tipoChavePix: z.object({
        value: z.string(),
        label: z.string()
    }, { message: "Selecione o tipo da chave Pix!" }),
    chavePix: z.string().min(1, { message: "Informe a chave Pix" }).transform( (campo) => campo.trim()),
    cpfCnpjBeneficiario: z.string().min(1, { message: "Informe um CPF/CNPJ" }).transform( (campo) => campo.trim()),
    observacao: z.string().transform( (campo) => campo.trim())
})

export default guiaSchema;