import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { deletarVisitante } from "../../../servicos/visitantesServices";
import { BotaoAcoes, BotaoExcluir } from "../AdminStyled";
import ModalPergunta from "../../../componentes/ModalPergunta";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
`
export function TabelaVisitantes(visitantes, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual, showAviso, setShowAviso) {

    const campos = [
        {chave: "nome", label: "Nome"},
        {chave: "email", label: "E-mail"},
        {chave: "celular", label: "Celular"}
    ];

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }

    const handleSim1 = () => {
        setShowAviso({ ...showAviso, mostrar1: false, mostrar2: true });
    };
    const handleNao1 = () => {
        setShowAviso({ mostrar1: false, visitanteDeletar: null, mostrar2: false, deletarTickets: false });
    };
    const handleSim2 = async () => {
        await deletarVisitante(showAviso.visitanteDeletar, 1);
        setShowAviso({ mostrar1: false, visitanteDeletar: null, mostrar2: false, deletarTickets: false });
    };
    const handleNao2 = async () => {
        await deletarVisitante(showAviso.visitanteDeletar, 0);
        setShowAviso({ mostrar1: false, visitanteDeletar: null, mostrar2: false, deletarTickets: false });
    };
    
    try {
        return (<>
            {ModalPergunta("Tem certeza que deseja deletar este visitante?", handleSim1, handleNao1, showAviso.mostrar1, "100px")}
            {ModalPergunta("Deletar também os ingressos deste visitante?", handleSim2, handleNao2, showAviso.mostrar2, "95px")}
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                            </TituloColunas>
                        })}
                        <th style={{color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>
                    {visitantes.map((visitante) => {
                        return (<LinhaTabela key={visitante._id}> 
                            <ColunaTabela>{visitante.nome || "-"}</ColunaTabela>
                            <ColunaTabela>{visitante.email || "-"}</ColunaTabela>
                            <ColunaTabela>{visitante.celular || "-"}</ColunaTabela>
                            <ColunaTabela>
                                <BotaoAcoes><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/visitantes/${visitante._id}`}>Visualizar</Link></BotaoAcoes>
                                <BotaoExcluir onClick={() => setShowAviso({ ...showAviso, mostrar1: true, visitanteDeletar: visitante._id })}>Excluir</BotaoExcluir>
                            </ColunaTabela>
                        </LinhaTabela>)
                    })}
                </tbody>
            </Tabela>
        </>);
    } catch (e) {
        return <></>
    }
}