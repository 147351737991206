import styled from "styled-components";

export const CabecalhoContato = styled.header`
    background-color: #444242;
    color: white;
    height: 90px;
    padding: 0 30px;
    font-size: 20px;
`
export const AreaWpp = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`
export const AreaMensagens = styled.div`
    flex: 1;
    background-color: #F3DEC4;
    /* 
    height: calc(100% - 90px);
    width: 100%;
    padding: 0 30px;
    overflow-y: auto; */
`

export const AreaInput = styled.div`
    height: 50px;
    background-color: #444242;
    color: white;
    padding: 0 30px;
`