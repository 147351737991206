import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WindowedSelect from "react-windowed-select";
import styled from "styled-components";
import ErroSpan from '../../componentes/ErroSpan';
import { editarLinha, obterLinhas } from "../../servicos/coresGuiasServices";
import { getAllGuias } from "../../servicos/guiasServices";

const Tabela = styled.table`
    width: 100%;
    height: 100vh;
    background-color: white;
    color: black;
    font-size: 50px;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    overflow-x: auto;
    th {
        border-right: 1px solid gray;
    }
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid gray;
    border-top: ${props => props.$linha || "none"};
    height: 30px;
    td {
        padding-top: ${props => props.$espaco || "none"};
        padding-bottom: ${props => props.$espaco || "none"};
    }
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    background-color: ${props => props.$cor || "white"};
    color: ${props => props.$fonte || "black"}; 
    border-right: 1px solid gray;
    font-size: ${props => props.$tamFonte || "50px"};
    margin: 0;
    padding: 0;
    padding-left: ${props => props.$marginLeft || "0"};
`
export default function CoresGuias() {
    const [guias, setGuias] = useState([]);
    const [linhas, setLinhas] = useState(null);
    const navigate = useNavigate();
    const { loja } = useParams();
    
    useEffect(() => {
        findGuiasLinhas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            findGuiasLinhas();
        }, 120000); // 120000 milliseconds = 120 seconds
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function findGuiasLinhas(){
        let resposta = await getAllGuias();
        if (!resposta) return;
        if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
        let listagemGuias = [];
        let cpf = ""; 
        let telefone = ""; 
        let cadastur = "";
        resposta.forEach(guia => {
            cpf = guia.cpfCnpjBeneficiario? guia.cpfCnpjBeneficiario.replace(/[^0-9]/g, "") : "";
            telefone = guia.whatsApp? guia.whatsApp.replace(/[^0-9]/g, "") : "";
            cadastur = guia.cadastur? guia.cadastur.replace(/[^0-9]/g, "") : "";
            listagemGuias.push({
                value: guia.codigo,
                label: guia.nome.toString(),
                empresa: guia.empresa,
                cpfCnpj: cpf,
                email: guia.email,
                telefone: telefone,
                cidade: guia.cidade,
                cadastur: cadastur
            })
        });
        if (listagemGuias.length > 0) setGuias(listagemGuias);

        resposta = await obterLinhas(loja);
        try {
            let linhasSalvas = [];
            resposta.map(linha => 
                linhasSalvas.push({
                    codigo: linha.codigo,
                    guia: linha.guia,
                    cor: linha.cor,
                    fonte: linha.fonte
                })
            );
            setLinhas(linhasSalvas);
        } catch (e) {
            console.log(e.message);
        }
    }

    function atualizarLinha(posicao, valor){
        let linhasAtuais = [...linhas];
        linhasAtuais[posicao] = {
            codigo: valor ? valor.value : null,
            guia: valor ? valor.label : null,
            cor: linhas[posicao].cor,
            fonte: linhas[posicao].fonte
        };
        editarLinha({
            loja: loja,
            posicao: posicao,
            guia: valor ? valor.label : null,
            codigo: valor ? valor.value : null,
            cor: linhas[posicao].cor,
            fonte: linhas[posicao].fonte
        });
        setLinhas(linhasAtuais);
    }

    // Filtro para pesquisar guias por empresa, cpf, email e telefone
    const customFilter = (option, searchText) => {
        let { label, empresa, cpfCnpj, email, telefone, cidade, cadastur } = option.data;

        if (!label) label = "";
        if (!empresa) empresa = "";
        if (!cpfCnpj) cpfCnpj = "";
        if (!email) email = "";
        if (!telefone) telefone = "";
        if (!cidade) cidade = "";
        if (!cadastur) cadastur = "";
        searchText = searchText.toLowerCase();

        if (searchText.replace(/[^0-9]/g, "") !== "" && searchText.replace(/[^0-9]/g, "").length > 2) {
            return (
                label.toLowerCase().includes(searchText) ||
                empresa.toLowerCase().includes(searchText) ||
                email.toLowerCase().includes(searchText) ||
                cidade.toLowerCase().includes(searchText) ||
                cpfCnpj.includes(searchText.replace(/[^0-9]/g, "")) ||
                telefone.includes(searchText.replace(/[^0-9]/g, "")) ||
                cadastur.includes(searchText.replace(/[^0-9]/g, ""))
            );
        } else {
            return (
                label.toLowerCase().includes(searchText) ||
                empresa.toLowerCase().includes(searchText) ||
                email.toLowerCase().includes(searchText) ||
                cidade.toLowerCase().includes(searchText)
            )
        }
    };

    if (!linhas) return (<ErroSpan>Carregando...</ErroSpan>);
    return (
        <Tabela>
            <tbody>
                {linhas.map((linha, index) => (
                    <LinhaTabela key={index}>
                        <ColunaTabela $tamanho={"120px"} $marginLeft="20px" $fonte={linha.fonte} $cor={linha.cor}>{linha?.codigo}</ColunaTabela>
                        <ColunaTabela $tamFonte={"32px"} $tamanho={"90%"}>
                            <WindowedSelect 
                                options={guias} 
                                placeholder=""
                                isClearable
                                defaultValue={{
                                    value: linha.codigo,
                                    label: linha.guia
                                }}
                                onChange={e => atualizarLinha(index, e)}
                                filterOption={customFilter}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        marginBottom: 5,
                                        marginTop: 5,
                                        border: "none"
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black",
                                        fontSize: "32px"
                                    }),
                                    indicatorSeparator: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: '#454545',
                                    }),
                                    clearIndicator: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#454545', // Set the desired color for the clear indicator "x"
                                        '&:hover': {
                                            color: '#454545', // Set the desired hover color for the clear indicator "x"
                                        }
                                    }),
                                    dropdownIndicator: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#454545', // Set the desired color for the dropdown indicator
                                        '&:hover': {
                                            color: '#454545', // Set the desired hover color for the dropdown indicator
                                        }
                                    })
                                }}
                            />
                        </ColunaTabela>
                    </LinhaTabela>
                ))}
            </tbody>
        </Tabela>
    );
}
