import styled from "styled-components";

export const AreaRelatorio = styled.div`
    width: 100%;
    max-width:  ${props => props.$maxLargura || "500px"};
    height: ${props => props.$altura || "400px"};
    margin: ${props => props.$margem || "0 20px"};
    padding: ${props => props.$pad || "0"};

    .chartjs-tooltip {
        z-index: 1000 !important;
    }
`
export const LadoALado = styled.div`
    display: flex;
    width: 95%;
    margin: ${props => props.$margem || "0 20px"};
`
export const TabelaLinks = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
export const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
export const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: 30px;
`
export const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};

    p {
        color: #252525;
    }
    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
        cursor: pointer;
    }
`