import styled from "styled-components";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { FaPix } from "react-icons/fa6";
import { getGuiaPorCodigo } from "../../../servicos/guiasServices";
import { editarComissao } from "../../../servicos/comissoesServices";
import { Link } from "react-router-dom";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
    margin-bottom: 20px;
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    border-top: ${props => props.$linha || "none"};
    height: 30px;
    td {
        padding-top: ${props => props.$espaco || "none"};
        padding-bottom: ${props => props.$espaco || "none"};
    }
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    color: ${props => props.$cor || "inherit"};

    .Icone {
        display: ${props => props.$hide? "none": "inline-block"};
        cursor: pointer;
    }
`
const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
const BotaoAcoes = styled.button`
    margin: 5px;
    padding: 2px 5px;
    cursor: pointer;
    background-color: var(--corNugaliClara);
    color: var(--corNugaliEscura);
    border-radius: 3px;
    border: 1px solid white;
    display: ${props => props.$hide? "none": "inline-block"};

    &:hover {
        background-color: var(--corNugaliEscura);
        color: white;
        border: 1px solid var(--corNugaliEscura);
    }
`
export function TabelaHistorico(comissoes, ordenacaoAtual, ordemAtual, setOrdenacaoAtual, setOrdemAtual, setStatus) {
    let total = 0;

    const campos = [
        {chave: "guia", label: "Guia"},
        {chave: "comissaoTotal", label: "Valor"},
        {chave: "dataPagamento", label: "Pagamento"},
        {chave: "pagamentoEfetivado", label: "Efetivado"},
        {chave: "mensagem", label: "Mensagem"}
    ];

    function formatarChavePix(chave, tipo){
        if (tipo === "Celular"){
            chave = chave.replace(/[^0-9]/g, "");     // Deixa apenas números
            if (chave.length === 10) chave = chave.substring(0, 2) + "9" + chave.substring(2);
            if (chave.length === 11) chave = "+55" + chave;
        } else if (tipo === "E-mail"){
            chave = chave.toLowerCase();
        } else if (tipo === "CPF/CNPJ") {
            chave = chave.replace(/[^0-9]/g, "");     // Deixa apenas números
        }
        return chave;
    }

    function get_pix(chave, nome, valor){   // Chave já formata e valor no formato (xx.yy)
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        let pix = '00020126';
        let InfoPix = '0014br.gov.bcb.pix'.toUpperCase() + '01';
        valor = valor.toFixed(2)
        if (chave.lenth < 10){
            InfoPix = InfoPix + '0';
        }
        InfoPix += chave.length + chave;
        pix += InfoPix.length + InfoPix + '52040000530398654';
        if (valor.length < 10){
            pix += '0';
        }
        pix += valor.length + valor + '5802BR59';
        nome = nome.split(' ')[0];    
        if (nome.length < 10){
            pix += '0';
        }
        pix += nome.length + nome + '6008POMERODE62070503***6304'
        // #53 -> Transaction Currency
        // #54 -> Transaction Amount
        // #58 -> Country code
        // #59 -> Merchant name
        // #60 -> Merchant city
        // #62 -> Dados adicionais

        const crc16 = get_crc16(pix).toUpperCase();
        pix += zeroPad(crc16, 4);
    
        return pix;
    }

    // function unsecuredCopyToClipboard(text) {
    //     const textArea = document.createElement("textarea");
    //     textArea.value = text;
    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();
    //     try {
    //         document.execCommand('copy');
    //     } catch (err) {
    //         console.error('Unable to copy to clipboard', err);
    //     }
    //     document.body.removeChild(textArea);
    // }

    function gerarPixCopiaECola(nome, pix, tipoChavePix, valor){
        const pixCopiaCola = get_pix(formatarChavePix(pix, tipoChavePix), nome, valor);
        if (navigator.clipboard) navigator.clipboard.writeText(pixCopiaCola);
        
        window.scrollTo(0, 0);
        setStatus({
            erro: false,
            sucesso: true,
            mensagem: `Pix de R$ ${valor.toFixed(2).replace(".", ",")} para ${nome} copiado com sucesso.`
        });
    }

    function atualizaOrdemOrdenacao(ordenacao){
        if (ordenacaoAtual === ordenacao){
            setOrdemAtual(ordemAtual === 1? -1 : 1);
        } else {
            setOrdenacaoAtual(ordenacao);
            setOrdemAtual(1);
        }
    }

    async function enviarWhatsApp(codigoGuia, valor){
        setStatus({
            erro: true,
            sucesso: false,
            mensagem: "Carregando..."
        });
        const mensagem = `Olá! A Nugali Chocolates realizou hoje a transferência, referente a sua comissão semanal, no valor de R$ ${valor.toFixed(2).split(".")[0]},${valor.toFixed(2).split(".")[1]} para o seu PIX cadastrado.%0a%0aLembrando que é sua responsabilidade manter seus dados cadastrais atualizados e qualquer alteração deve ser informada somente neste WhatsApp. Obrigado!`;
        mensagem.replaceAll(" ","%20");
        const guia = await getGuiaPorCodigo(codigoGuia);
        window.open(`https://wa.me/${formatarChavePix(guia.whatsApp, "Celular")}?text=${mensagem}`,'_newtab');
        setStatus({
            erro: false,
            sucesso: false,
            mensagem: undefined
        });
    }

    async function definirPago(comissao){
        await editarComissao(comissao, {pagamentoEfetivado: true});
        setOrdemAtual(ordemAtual === 1? -1 : 1);
    }

    if (comissoes) {
        return (
            <Tabela>
                <thead>
                    <LinhaTabela>
                        {campos.map( campo => {
                            return <TituloColunas key={campo.chave} onClick={() => atualizaOrdemOrdenacao(campo.chave)}>
                                <p>
                                    {campo.label}
                                    {campo.chave === ordenacaoAtual && ordemAtual === -1 && <BiUpArrowAlt className="Icone" size={'1em'}/>}
                                    {campo.chave === ordenacaoAtual && ordemAtual === 1 && <BiDownArrowAlt className="Icone" size={'1em'}/>}
                                </p>
                                
                            </TituloColunas>
                            
                        })}
                        <th style={{color: "#252525"}}>Ações</th>
                    </LinhaTabela>
                </thead>
                <tbody>
                    {comissoes.map((comissao, index) => {
                        total += comissao.comissaoTotal;
                        return (
                        <LinhaTabela key={index}> 
                            <ColunaTabela $tamanho="400px"><Link style={{ textDecoration: 'none', color: "inherit", cursor: "pointer", margin: "0", padding: "0" }} to={`/admin/guias/${comissao.guia._id}`}>{comissao.guia.nome}</Link></ColunaTabela>
                            <ColunaTabela>R$ {comissao.comissaoTotal.toFixed(2).replace(".", ",")}</ColunaTabela>
                            <ColunaTabela>{comissao.dataPagamento.slice(8, 10)}/{comissao.dataPagamento.slice(5, 7)}/{comissao.dataPagamento.slice(0, 4)}</ColunaTabela>
                            <ColunaTabela>{comissao.pagamentoEfetivado? "Sim" : "Não"}</ColunaTabela>
                            <ColunaTabela>{comissao.mensagem}</ColunaTabela>
                            <ColunaTabela $hide={comissao.pagamentoEfetivado}>
                                <FaPix className="Icone" onClick={() => gerarPixCopiaECola(comissao.guia.nome, comissao.guia.chavePix, comissao.guia.tipoChavePix, comissao.comissaoTotal)} />
                                <BotaoAcoes $hide={!comissao.pagamentoEfetivado} onClick={() => enviarWhatsApp(comissao.guia.codigo, comissao.comissaoTotal)}>Mensagem</BotaoAcoes>
                                <BotaoAcoes $hide={comissao.pagamentoEfetivado} onClick={() => definirPago(comissao.identificador)}>Pago</BotaoAcoes>
                            </ColunaTabela>
                        </LinhaTabela>
                    )})}
                    <LinhaTabela>
                        <ColunaTabela $cor="#252525">Total</ColunaTabela>
                        <ColunaTabela $cor="#252525">R$ {total.toFixed(2).replace(".", ",")}</ColunaTabela>
                    </LinhaTabela>
                </tbody>
            </Tabela>
        )
    } else {
        return <></>
    }
    function get_crc16(dados){
        let crc = 0xFFFF;
        for (let i = 0; i < dados.length; i++){
            crc ^= dados.charCodeAt(i) << 8;

            for (let j = 0; j < 8; j++){
                if ((crc & 0x8000) > 0){
                    crc = (crc << 1) ^ 0x1021;
                } else {
                    crc = crc << 1;
                }
            }
        }
        return (crc & 0xFFFF).toString(16);
    }
}