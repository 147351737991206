import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaWhatsapp } from "react-icons/fa";
import Select from 'react-select';
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import Botao from "../../../componentes/Botao";
import ErroSpan from "../../../componentes/ErroSpan";
import ModalPergunta from "../../../componentes/ModalPergunta";
import Titulo1 from "../../../componentes/Titulo1";
import MenuLateral from "../../../componentes/MenuLateral";
import guiaSchema from "../../../schemas/guiaSchema";
import { alterarGuia, getGuiaPorId, removerGuia } from "../../../servicos/guiasServices";
import { AreaSuperior, ColunaTabela, LinhaTabela, LinksPaginas, OpcoesFiltragem, PaginasTabela, RodapePaginas, SecaoAdmin, SecaoPrincipal, Tabela, TotalDocs } from "../AdminStyled";
import { getComissoesGuia } from "../../../servicos/comissoesServices";
import { TabelaHistGuia } from "./TabelaHistGuia";

const opcoesChavePix = [
    { value: "cpfcnpj", label: "CPF/CNPJ" },
    { value: "email", label: "E-mail" },
    { value: "celular", label: "Celular"},
    { value: "chaveAleatoria", label: "Chave Aleatória"}
];

const opcoesEstados = [
    { value: "ac", label: "AC" },
    { value: "al", label: "AL" },
    { value: "am", label: "AM" },
    { value: "ap", label: "AP" },
    { value: "ba", label: "BA" },
    { value: "ce", label: "CE" },
    { value: "df", label: "DF" },
    { value: "es", label: "ES" },
    { value: "go", label: "GO" },
    { value: "ma", label: "MA" },
    { value: "mg", label: "MG" },
    { value: "mt", label: "MT" },
    { value: "ms", label: "MS" },
    { value: "pa", label: "PA" },
    { value: "pb", label: "PB" },
    { value: "pe", label: "PE" },
    { value: "pi", label: "PI" },
    { value: "pr", label: "PR" },
    { value: "rj", label: "RJ" },
    { value: "rn", label: "RN" },
    { value: "ro", label: "RO" },
    { value: "rr", label: "RR" },
    { value: "rs", label: "RS" },
    { value: "sc", label: "SC" },
    { value: "sp", label: "SP" },
    { value: "se", label: "SE" },
    { value: "to", label: "TO" }
]

// const camposForm = [    
//     // [Nome mostrado,  key,  obrigatório,  tipo,  opções select,  placeholder select]
//     ["Nome", "nome", true, "text"],
//     ["Empresa", "empresa", false, "text"],
//     ["Cadastur", "cadastur", false, "text"],
//     ["Cidade", "cidade", true, "text"],
//     ["Estado", "estado", true, "text", opcoesEstados, "Selecione o estado"],
//     ["WhatsApp", "whatsApp", true, "tel"],
//     ["E-mail", "email", true, "text"],
//     ["Tipo da Chave Pix", "tipoChavePix", true, "text", opcoesChavePix, "Selecione o tipo da chave Pix"],
//     ["Chave Pix", "chavePix", true, "text"],
//     ["CPF/CNPJ Beneficiário", "cpfCnpjBeneficiario", true, "text"],
//     ["Observação", "observacao", false, "text"]
// ];

export default function GuiaId() {
    const [guia, setGuia] = useState(null);
    const [comissoes, setComissoes] = useState([]);
    const [status, setStatus] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [editando, setEditando] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [ordem, setOrdem] = useState(1);
    const [ordenacao, setOrdenacao] = useState("dataPagamento");
    const [total, setTotal] = useState({ paginas: 1, docs: 0 });
    const navigate = useNavigate();
    const { id } = useParams();
    const { 
        control,
        register: registerGuia, 
        handleSubmit: handleSubmitGuia,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        resolver: zodResolver(guiaSchema)
    });

    useEffect(() => {
        obterGuia();
        obterComissoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (guia){
            setValue("estado", opcoesEstados.find( e => e.label === guia.estado));
            setValue("tipoChavePix", opcoesChavePix.find( e => e.label === guia.tipoChavePix));
        }
    }, [guia, setValue]);

    useEffect(() => {
        obterComissoes(pagina, ordem, ordenacao);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagina, ordem, ordenacao])

    async function obterGuia(){
        getGuiaPorId(id).then( resposta => setGuia(resposta.guia));
    }

    async function obterComissoes(pagina, ordem, ordenacao){
        getComissoesGuia(id, pagina, ordem, ordenacao).then( resposta => {
            if (resposta && resposta.dados) {
                setComissoes(resposta.dados.sort( (a, b) => a.dataPagamento - b.dataPagamento));
                setTotal({ paginas: Math.ceil(resposta.totalDocs/20), docs: resposta.totalDocs });
            }
        });
    }

    async function atualizarGuia(data){
        setStatus({
            erro: false,
            sucesso: false,
            mensagem: ""
        });
        window.scrollTo(0, 0);
        const resposta = await alterarGuia({ 
            ...data,
            codigo: guia.codigo, 
            estado: data.estado.label, 
            tipoChavePix: data.tipoChavePix.label
        });
        if (resposta.status === 200){
            setStatus({
                sucesso: true,
                mensagem: resposta.mensagem
            });
        } else {
            setStatus({
                erro: true,
                sucesso: false,
                mensagem: resposta.mensagem
            });
        }
        obterGuia();
    }

    const handleSim = async () => {
        const res = await removerGuia(guia.codigo);
        if (res.status === 200){
            navigate("/admin/guias");
        } else {
            setStatus({
                erro: true,
                sucesso: false,
                mensagem: `Erro ao deletar: ${res.status}`
            });
        }
    }
    const handleNao = () => {
        setShowModal(false);
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Visualizar guia")}
                {ModalPergunta("Tem certeza que deseja deletar este guia?", handleSim, handleNao, showModal, "100px")}
                {status.erro && <ErroSpan>{status.mensagem}</ErroSpan>}
                {status.sucesso && <ErroSpan $corFundo={"#ddffcd"} $corTexto={"#209e00"} >{status.mensagem}</ErroSpan>}
                {guia && 
                <form onSubmit={handleSubmitGuia(atualizarGuia)} style={{ marginBottom: "20px" }}>
                <AreaSuperior>
                    <Titulo1 $margem="0 0 0 0" $tamanho="20px">Código {guia.codigo}</Titulo1>
                    <OpcoesFiltragem>
                        <Botao $marginRight="10px" $esconder={editando} onClick={ e => { setEditando(true); e.preventDefault();}}>Editar</Botao>
                        <Botao $marginRight="10px" $esconder={!editando} onClick={() => setEditando(false)} type="submit">Salvar</Botao>
                        <Botao $marginRight="10px" $esconder={!editando} onClick={() => { setEditando(false); reset();}}>Cancelar</Botao>
                        <Botao $marginRight="10px" $cor="#ff2424" onClick={() => setShowModal(true)}>Excluir</Botao>
                    </OpcoesFiltragem>
                </AreaSuperior>
                <Tabela>
                    <tbody>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Nome</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.nome} {...registerGuia("nome", {required: "Campo obrigatório"})}/>) || <p style={{margin: 0}}>{guia.nome || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.nome && <ErroSpan>{errors.nome.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">E-mail</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.email} {...registerGuia("email", {required: "Campo obrigatório"})}/>) || <p style={{margin: 0}}>{guia.email || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.email && <ErroSpan>{errors.email.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">WhatsApp</ColunaTabela>
                            <ColunaTabela>{(editando && <input type="tel" defaultValue={guia.whatsApp} {...registerGuia("whatsApp", {required: "Campo obrigatório"})}/>) || <p style={{margin: 0}}>{<>{guia.whatsApp} <Link style={{ textDecoration: 'none', color: "inherit", marginLeft: "10px" }} to={`https://wa.me/${(guia.whatsApp).replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")}`} target="_blank" rel="noopener noreferrer"><FaWhatsapp className="Icone"/></Link></> || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.whatsApp && <ErroSpan>{errors.whatsApp.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Empresa</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.empresa} {...registerGuia("empresa")}/>) || <p style={{margin: 0}}>{guia.empresa || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.empresa && <ErroSpan>{errors.empresa.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Cadastur</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.cadastur} {...registerGuia("cadastur")}/>) || <p style={{margin: 0}}>{guia.cadastur || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.cadastur && <ErroSpan>{errors.cadastur.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Cidade</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.cidade} {...registerGuia("cidade", {required: "Campo obrigatório"})}/>) || <p style={{margin: 0}}>{guia.cidade || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.cidade && <ErroSpan>{errors.cidade.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Estado</ColunaTabela>
                            <ColunaTabela>{(editando && <Controller
                                            name={"estado"}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    isClearable={false}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            padding: 0,
                                                            margin: 0,
                                                            width: "100%",
                                                            fontSize: "14px",
                                                            color: "#252525",
                                                            borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                                        })
                                                    }}
                                                    classNamePrefix={'Select'}
                                                    placeholder="Estado"
                                                    {...field}
                                                    options={opcoesEstados}
                                                />
                                            )}
                                        />) || <p style={{margin: 0}}>{guia.estado || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.estado && <ErroSpan>{errors.estado.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Tipo Chave Pix</ColunaTabela>
                            <ColunaTabela>{(editando && <Controller
                                            name={"tipoChavePix"}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    defaultValue={opcoesChavePix.find( e => e.label === guia.tipoChavePix)}
                                                    isClearable={false}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            padding: 0,
                                                            margin: 0,
                                                            width: "100%",
                                                            fontSize: "14px",
                                                            color: "#252525",
                                                            borderColor: state.hover ? 'black' : 'rgba(58, 23, 14, 0.308)' 
                                                        })
                                                    }}
                                                    classNamePrefix={'Select'}
                                                    placeholder="Tipo da Chave Pix"
                                                    {...field}
                                                    options={opcoesChavePix}
                                                />
                                            )}
                                        />) || <p style={{margin: 0}}>{guia.tipoChavePix || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.tipoChavePix && <ErroSpan>{errors.tipoChavePix.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Chave Pix</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.chavePix} {...registerGuia("chavePix", {required: "Campo obrigatório"})}/>) || <p style={{margin: 0}}>{guia.chavePix || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.chavePix && <ErroSpan>{errors.chavePix.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">CPF/CNPJ beneficiário</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.cpfCnpjBeneficiario} {...registerGuia("cpfCnpjBeneficiario", {required: "Campo obrigatório"})}/>) || <p style={{margin: 0}}>{guia.cpfCnpjBeneficiario || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.cpfCnpjBeneficiario && <ErroSpan>{errors.cpfCnpjBeneficiario.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Observação</ColunaTabela>
                            <ColunaTabela>{(editando && <input defaultValue={guia.observacao} {...registerGuia("observacao")}/>) || <p style={{margin: 0}}>{guia.observacao || "-"}</p>}</ColunaTabela>
                            <ColunaTabela $tamanho="400px">{editando && errors.observacao && <ErroSpan>{errors.observacao.message}</ErroSpan>}</ColunaTabela>
                        </LinhaTabela>
                    </tbody>
                </Tabela>
                </form>}
                {comissoes && comissoes.length > 0 && <>
                <AreaSuperior>
                    <Titulo1 $margem="0 0 0 0" $tamanho="20px">Comissoes</Titulo1>
                        <Botao $marginRight="10px" $esconder={editando}><Link style={{ color: "inherit" }} to={`/admin/guias/${id}/historico`}>Ver histórico completo</Link></Botao>
                </AreaSuperior>
                {TabelaHistGuia(comissoes, ordenacao, setOrdenacao, ordem, setOrdem)}
                <RodapePaginas>
                    <TotalDocs>
                        <p>Total: {total.docs}</p>
                    </TotalDocs>
                    <PaginasTabela>
                        <p onClick={() => setPagina(1)}>Primeira</p>
                        <p onClick={() => {
                            if (pagina > 1) setPagina(pagina-1);
                            }
                        }>Anterior</p>
                        {paginas()}
                        <p onClick={() => {
                            if (pagina < total.paginas) setPagina(pagina+1);
                        }}>Próxima</p>
                        <p onClick={() => setPagina(total.paginas)}>Última</p>
                    </PaginasTabela>
                </RodapePaginas>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    );

    function paginas() {
        let pages = [];
        let ajuste = 2;
    
        if (pagina === 1){
            ajuste = 0;
        } else if (pagina === 2){
            ajuste = 1;
        } else if (pagina === total.paginas-1 && total.paginas > 4){
            ajuste = 3;
        } else if (pagina === total.paginas && total.paginas > 4){
            ajuste = 4;
        }
        if (total.paginas < 5){
            for (let i = 0; i < total.paginas; i++){
                let pageNum = parseInt(i+1);
    
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        } else {
            for (let i = 0; i < 5; i++){
                let pageNum = parseInt(pagina) + parseInt(i) - ajuste;
                if (pageNum === pagina) {
                    pages.push(<LinksPaginas key = {`page${pageNum}`} $corFundo="var(--corNugaliEscura)" $cor="white">{pageNum}</LinksPaginas>)
                }else {
                    pages.push(<LinksPaginas 
                        key = {`page${pageNum}`}
                        id={pageNum} 
                        onClick={event => setPagina(parseInt(event.currentTarget.id))
                        }>
                        {pageNum}
                    </LinksPaginas>)
                }
            }
        }    
        return pages;
    }
}