import styled from "styled-components";

const Tabela = styled.table`
    width: 90%;
    justify-content: left;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;
`
const LinhaTabela = styled.tr`
    border-bottom: ${props => props.$bordaBaixo || "1px solid lightgray"};
    border-top: ${props => props.$bordaTopo || "none"};
    height: 30px;
    background-color: ${props => props.$corFundo || "transparent"};
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    color: #252525;
    font-weight: ${props => props.$bold || "normal"};
`
export function TabelaTotais(quantidades) {
    try {
        return (
            <Tabela>
                <tbody>
                    <LinhaTabela $bordaTopo="1px solid lightgray"> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Promocionais</ColunaTabela>
                        <ColunaTabela>{quantidades.promocionais || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Promocionais excursão</ColunaTabela>
                        <ColunaTabela>{quantidades.promocionaisExcursao || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Promocionais escolar</ColunaTabela>
                        <ColunaTabela>{quantidades.promocionaisEscolar || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Promocionais online</ColunaTabela>
                        <ColunaTabela>{quantidades.promocionaisOnline || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Infantis</ColunaTabela>
                        <ColunaTabela>{quantidades.infantis || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Infantis online</ColunaTabela>
                        <ColunaTabela>{quantidades.infantisOnline || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela $bordaBaixo="1px solid #535353"> 
                        <ColunaTabela $bold="bold" $tamanho="200px">Cortesias</ColunaTabela>
                        <ColunaTabela>{quantidades.cortesias || "-"}</ColunaTabela>
                    </LinhaTabela>
                    <LinhaTabela> 
                        <ColunaTabela $bold="bold" $tamanho="200px">TOTAL</ColunaTabela>
                        <ColunaTabela>{quantidades.cortesias + quantidades.promocionais + quantidades.promocionaisExcursao + quantidades.promocionaisOnline + quantidades.infantis + quantidades.infantisOnline || "-"}</ColunaTabela>
                    </LinhaTabela>
                </tbody>
            </Tabela>
        );
    } catch (e) {
        return <></>
    }
}