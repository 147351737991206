import Cookies from "js-cookie";

export async function listarGuias(){
    try {
        const guias = await fetch("/api/guias",{
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await guias.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}
export async function getGuias(busca, ordenacao = "codigo", ordem = "1", pagina = "1") {
    try {
        let params = `?ordenacao=${ordenacao}&ordem=${ordem}&pagina=${pagina}`

        const resposta = await fetch(`/api/guias/busca-avancada${params}`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(busca)
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
    }
}
export async function getGuiaPorId(id) {
    try {
        const resposta = await fetch (`/api/guias/id/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}
export async function getGuiaPorCodigo(codigo) {
    try {
        const resposta = await (await fetch (`/api/guias/${codigo}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        })).json();
        return resposta[0];
    } catch (e) {
        console.log(e.message);
        return null;
    }
}
export async function criarGuia(novoGuia, codigo) {
    novoGuia.estado = novoGuia.estado.label;
    novoGuia.tipoChavePix = novoGuia.tipoChavePix.label;
    novoGuia.codigo = codigo;
    try {
        const resposta = await fetch("/api/guias", {
            method: "post",
            body: JSON.stringify(novoGuia),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
    }
}
export async function removerGuia(codigo){
    try {
        const resposta = await fetch(`/api/guias/${codigo}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
    }
}
export async function alterarGuia(guia){
    try {
        const resposta = await fetch (`/api/guias/${guia.codigo}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(guia)
        });

        if (resposta.status === 200) {
            return {status: 200, mensagem: `Guia ${guia.codigo} alterado com sucesso. Verifique o cadastro no Chef`};
        } else {
            return {status: resposta.status, mensagem: `Erro ao alterar guia. ${resposta.status}`};
        }

    } catch (e) {
        console.log(e.message);
    }
}
export async function getUltimoCodigoGuia(){
    try {
        const resposta = await (await fetch ("/api/guias/busca?limite=1&pagina=1&ordenacao=codigo&ordem=-1", {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        })).json();
        return resposta.dados[0].codigo;
    } catch (e) {
        console.log(e.message);
        return 0;
    }
}

export async function getAllGuias(){
    try {
        const resposta = await fetch(`/api/guias?limite=2000`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}